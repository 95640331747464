import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DetailMedia, StandardMedia } from 'src/_api';
import { MediaCreateModal } from '../../admin/media/media-edit/media-create.modal';
import { MediaSelectModal } from '../../admin/media/media-select.modal';
import { Media } from './media.service';
import { ViewMediaModal } from 'src/app/shared/view-media-modal/view-media.modal';
import { TriggerMediaModal } from 'src/app/shared/trigger-media-model/trigger-media-model.component';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaModalService {
  constructor(private dialog: MatDialog) { }

  openCreateMediaModal(
    form: any,
    propertyName: string,
    list: any[],
    data?: any,
    index?: number
  ): void {
    const dialogRef = this.dialog.open(MediaCreateModal, {
      width: '410px',
      data: {
        ...data,
      },
    });

    dialogRef.afterClosed().subscribe((media: Media) => {
      if (media) {
        const mediaObject = {
          mediaId: media.id,
          name: media.name,
          type: media.type,
          duration: media.duration,
          thumbnailUrl: media.thumbnailImageUrl,
          imageUrl: `${environment.apiBaseUrl}${media.imageUrl}`,
          url: `${environment.apiBaseUrl}${media.imageUrl}`,
          filename: media.filename
        };

        if (media.type === 2) {
          form.get('presentationTime').setValue(mediaObject.duration)
          form.get('presentationTime').disable()
        }

        const findMedia = list.find((sm) => sm?.mediaId === media.id);
        if (!findMedia) {
          list.splice(0, 0, mediaObject);
          form.get(propertyName).setValue(mediaObject);
        } else {
          form.get(propertyName).setValue(findMedia)
        }
      }
    });
  }

  openSelectMediaModal(
    form: any,
    propertyName: string,
    list: any[],
    data?: any,
    index?: number
  ): void {
    const dialogRef = this.dialog.open(MediaSelectModal, {
      width: '54%',
      height: '85%',
      data: {
        ...data,
      },
    });

    dialogRef.afterClosed().subscribe((media: Media) => {
      if (media) {
        const mediaObject = {
          mediaId: media.id,
          name: media.name,
          type: media.type,
          duration: media.duration,
          thumbnailUrl: media.thumbnailImageUrl,
          url: media.imageUrl,
          imageUrl: media.imageUrl,
          filename: media.filename,
          imageThumbnailUrl: media.imageUrl,
        };

        if (media.type === 2) {
          if (propertyName === 'defaultMediaDetails') {
            form.get('defaultMediaPresentationTime').setValue(mediaObject.duration)
            form.get('defaultMediaPresentationTime').disable()
          } else {
            form.get('presentationTime')?.setValue(mediaObject.duration)
            form.get('presentationTime')?.disable()
          }


        }
        const findMedia = list.find((sm) => sm?.mediaId === media.id);
        if (!findMedia) {
          list.splice(0, 0, mediaObject);
          form.get(propertyName).setValue(mediaObject);
        } else {
          form.get(propertyName)?.setValue(findMedia)
        }
      }
    });
  }

  openViewMediaModal(standardMedia: StandardMedia | DetailMedia): void {
    this.dialog.open(ViewMediaModal, {
      width: '80vw',
      maxHeight: '80vh',  
      data: {
        title: standardMedia.name + ' - ' + standardMedia.filename,
        media: {
          imageUrl: standardMedia.url,
          thumbnailImageUrl: `${environment.apiBaseUrl}/${standardMedia.thumbnailImageUrl}`,
          type: standardMedia.type
        }
      }
    });
  }
  openTriggerMediaModal(triggers?: any, updateTriggersCallback?: (triggers: any) => void) {
    const dialogRef = this.dialog.open(TriggerMediaModal, {
      autoFocus: false,
      data: {
        triggers
      },
    });

    dialogRef.afterClosed().subscribe((triggerForm: any) => {
      if (triggerForm) {
        triggers = {
          weatherConditions: (triggerForm?.selectedIcons && triggerForm?.selectedIcons.length > 0) ? triggerForm?.selectedIcons : null,
          temperature: (triggerForm?.temperature && Object.keys(triggerForm?.temperature).length > 0) ? triggerForm?.temperature : null
        };
        updateTriggersCallback(triggers);
      }
    });
  }
}
