import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PermissionsConfig } from '@api/index';
import { AVAILABLE_PERMISSIONS } from '@app/constants';

@Component({
  selector: 'flow-permissions-table',
  templateUrl: './permissions-table.component.html',
  styleUrls: ['./permissions-table.component.scss']
})
export class PermissionsTableComponent implements OnInit {
  @Input() permissionList: PermissionsConfig = { nodes: [] };
  @Input() isEditable: boolean = true;
  @Input() selectablePermissions: string[] = [];
  @Input() headerTitle: string = "";
  @Input() actions: string[] = [];
  @Output() actionClick: EventEmitter<{ action: string, node: any }> = new EventEmitter();

  selectAll: boolean;

  headerConfig = [
    {
      group: "sidebar.sections.cms",
      actions: [
        {
          title: "sidebar.manage",
          icon: "booking",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewBookingCms",
            "createCustomer.permissions.actions.headers.update"//"UpdateBookingCms"
          ]
        },
        {
          title: "sidebar.media",
          icon: "media",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewMediaCms",
            "createCustomer.permissions.actions.headers.update"//"UpdateMediaCms"
          ]
        },
      ]
    },
    {
      group: "Analytics",
      actions: [
        {
          title: "sidebar.reach",
          icon: "reach",
          permissions: [
            "createCustomer.permissions.actions.headers.view"//"ViewReachAnalytics"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.reachReports",
          icon: "exposures-reports",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewReachReportsAnalytics",
            "createCustomer.permissions.actions.headers.export"//"ExportReachReportsAnalytics"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.exposures",
          icon: "exposure",
          permissions: [
            "createCustomer.permissions.actions.headers.view"//"ViewExposuresAnalytics"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.exposuresReports",
          icon: "exposure-reports",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewExposuresReportsAnalytics",
            "createCustomer.permissions.actions.headers.export"//"ExportExposuresReportsAnalytics"
          ]
        },
      ]
    },
    {
      group: "sidebar.sections.visitorInsights",
      actions: [
        {
          title: "createCustomer.permissions.actions.body.peopleCounter",
          icon: "exposure-reports",
          permissions: [
            "createCustomer.permissions.actions.headers.view"//"ViewPeopleCounterInsights"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.peopleCounterReports",
          icon: "people-counter-reports",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewPeopleCounterReportsInsights",
            "createCustomer.permissions.actions.headers.export"//"ExportPeopleCounterReportsInsights"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.poi",
          icon: "poi",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewPoiInsights",
            "createCustomer.permissions.actions.headers.update"//"UpdatePoiInsights"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.poiCategories",
          icon: "poi-categories",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewPoiCategoriesInsights",
            "createCustomer.permissions.actions.headers.update"//"UpdatePoiCategoriesInsights"
          ]
        },
        {
          title: "createCustomer.permissions.actions.body.poiReports",
          icon: "poi-reports",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewPoiReportsInsights",
            "createCustomer.permissions.actions.headers.export"//"ExportPoiReportsInsights"
          ]
        },
      ]
    },
    {
      group: "createCustomer.permissions.headers.admin",
      actions: [
        {
          title: "sidebar.sms",
          icon: "sms",
          permissions: [
            "createCustomer.permissions.actions.headers.view"//"ViewSmsAdmin"
          ]
        },
        {
          title: "sidebar.channels",
          icon: "channels",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewChannelAdmin",
            "createCustomer.permissions.actions.headers.update"//"UpdateChannelAdmin"
          ]
        },
        {
          title: "sidebar.screens",
          icon: "screens",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewScreensAdmin",
            "createCustomer.permissions.actions.headers.update"//"UpdateScreensAdmin"
          ]
        },
        {
          title: "sidebar.clients",
          icon: "clients",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewClientsAdmin",
            "createCustomer.permissions.actions.headers.update"//"UpdateClientsAdmin"
          ]
        },
        {
          title: "sidebar.contactPersons",
          icon: "contact-persons",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewContactPersonAdmin",
            "createCustomer.permissions.actions.headers.update"//"UpdateContactPersonAdmin"
          ]
        },
        {
          title: "createCustomer.permissions.subTitles.permissions",
          icon: "users-permissions",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewUserPermissionsAdmin",
            "createCustomer.permissions.actions.headers.update"//"UpdateUserPermissionsAdmin"
          ]
        },
        {
          title: "sidebar.users",
          icon: "users",
          permissions: [
            "createCustomer.permissions.actions.headers.view",//"ViewUsersAdmin",
            "createCustomer.permissions.actions.headers.update"//"UpdateUsersAdmin"
          ]
        }
      ]

    }
  ]
  availablePermissions = AVAILABLE_PERMISSIONS;
  permissionsForm: FormGroup;

  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.reinitializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.permissionList && !changes.permissionList.firstChange) {
      console.log('permissionList changed:', changes.permissionList.currentValue);
      this.reinitializeForm();
    }
    if(changes.isEditable && !changes.isEditable.firstChange) {
      console.log('iseditable changed', changes.isEditable.currentValue);
      this.reinitializeForm();
    }
  }

  reinitializeForm() {
    this.permissionsForm = this.formBuilder.group({
      nodes: this.formBuilder.array(this.permissionList.nodes.map(node => this.createNodeForm(node)))
    });
  }

  checkSelectedAll(permissionsFormArray: FormArray<FormGroup>) {
    return permissionsFormArray.controls.every(obj =>  obj?.value?.value === true);
  }

  createNodeForm(node): FormGroup {
    const permissionsArray = this.availablePermissions.map(permission => this.createPermissionControl(node.permissions, permission));
    const permissionsFormArray = this.formBuilder.array(permissionsArray);
    const allTrue = this.checkSelectedAll(permissionsFormArray)

    return this.formBuilder.group({
      id: node.id,
      name: node.name,
      data: node.data,
      selectAll: allTrue,
      permissions: permissionsFormArray
    });
  }

  createPermissionControl(permissions, permission): FormGroup {
    const isPermissionAvailable = permissions.includes(permission);
    const isPermissionEnabled = this.selectablePermissions.includes(permission);
    return this.formBuilder.group({
      label: [permission],
      value: [{ value: isPermissionAvailable, disabled: this.isEditable ? !isPermissionEnabled : true }]
    });
  }

  get nodes(): FormArray {
    return this.permissionsForm.get('nodes') as FormArray;
  }

  get selectAllProp() {
    return this.permissionsForm.get('selectAll');
  }

  permissions(nodeIndex: number): FormArray {
    return this.nodes.at(nodeIndex).get('permissions') as FormArray;
  }

  isFormControl(control: any): control is FormControl {
    return control instanceof FormControl;
  }

  onNodeSelectAll(node: AbstractControl, event: MatCheckboxChange): void {
    const formGroup = node as FormGroup;
    const permissionsControl = formGroup.get('permissions') as FormArray;
    permissionsControl.controls.forEach(permission => {
      if (!permission.get('value').disabled) {
        permission.get('value').setValue(event.checked);
      }
    });
  }

  calculateHeaderColSpan(array) {
    return array.actions.flatMap(action => action.permissions).length;
  }

  getFormValue(): any {
    return this.permissionsForm.value;
  }

  onGroupChange(customerId: number, roleId: number): void {
    const nodeIndex = this.nodes.controls.findIndex(n => (n as FormGroup).get('id').value === customerId);
    if (nodeIndex !== -1) {
      const node = this.nodes.at(nodeIndex) as FormGroup;
      if (roleId === null) {
        const node = this.permissionList.nodes.find(n => n.id === customerId);
        if (node) {
          this.nodes.setControl(nodeIndex, this.createNodeForm(node));
        }
      } else {
        node.disable();
      }
    }
  }

  getSelectedPermissions(): any {
    return this.permissionsForm.value.nodes.map((node) => {
      const selectedPermissions = node.permissions
        .filter(permission => permission.value)
        .map(permission => permission.label);

      return {
        id: node.id,
        permissions: selectedPermissions
      };
    });
  }

  onActionClick(action: string, node: any) {
    this.actionClick.emit({ action, node });
  }

  getTranslatedActionKey(actionKey: string): string {
    return `permissionTableActions.${actionKey}`;
  }
}
