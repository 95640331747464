<div [formGroup]="marketingAutomationForm"
     class="w-full flex flex-col gap-8 marketing-automation-container">
  <mat-checkbox color="primary"
                formControlName="isEnabled"
                [disabled]="!mediaForm.valid"
                [matTooltip]="!mediaForm.valid ? '' : ''">
    {{ 'marketingAutomation.checkboxTitle' | translate }}
  </mat-checkbox>
  <div formGroupName="marketingAutomation"
       *ngIf="marketingAutomationForm.value.isEnabled"
       class="flex flex-col gap-8">
    <div class="w-full h-fit flex-col justify-start items-start gap-6 inline-flex">
      <div class="w-full justify-start items-end gap-4 inline-flex">
        <div class="text-neutral-800 text-base font-semibold font-['Inter'] leading-[18px]">{{
          'editEngagement.sensitivitySetting.title' | translate }}
        </div>
      </div>

      <div class="flex flex-col items-center w-full ">
        <div class="flex justify-between w-full text-sm text-gray-800">
          <span>{{ 'settings.sensitivitySettingMA.low' | translate }}</span>
          <span class="text-center">{{ 'settings.sensitivitySettingMA.medium' | translate }}</span>
          <span class="text-right">{{ 'settings.sensitivitySettingMA.high' | translate }}</span>
        </div>
      
        <div class="flex w-full px-[0.16rem]">
          <mat-slider formControlName="sensitivitySettings"
                    [disabled]="hasAdvanceSetting?.value"
                    [max]="3"
                    [min]="1"
                    [step]="1"
                    class="w-full"
                    color="primary">
          </mat-slider>
        </div>
      </div>

      <div class="flex-col justify-start items-start gap-2 flex">
        <div class="justify-start items-center gap-2 inline-flex">
          <mat-checkbox color="primary"
                        formControlName="hasAdvancedSettings">
            {{ "marketingAutomation.advancedSettings" | translate }}
          </mat-checkbox>
        </div>
        <ng-container *ngIf="hasAdvanceSetting?.value"
                      formGroupName="advancedSettings">
          <div class="flex items-baseline">
            <mat-checkbox color="primary"
                          formControlName="configurationRuleOneActivated"></mat-checkbox>
            <span class="m-2">{{ "marketingAutomation.activateIfNoPeopleInFront" | translate }}</span>
            <mat-form-field appearance="outline"
                            class="marketing-automation w-16">
              <input [value]="numberOfMinutes.value"
                     formControlName="numberOfMinutes"
                     matInput
                     [min]="1"
                     type="number">
            </mat-form-field>
            <span class="m-2"> {{ "marketingAutomation.minutes" | translate }}</span>
          </div>
          <div class="flex items-baseline">
            <mat-checkbox color="primary"
                          formControlName="configurationRuleTwoActivated"></mat-checkbox>
            <span class="m-2">{{ 'marketingAutomation.activateIf' | translate }}</span>
            <mat-form-field appearance="outline"
                            class="marketing-automation w-16">
              <input formControlName="numberOfPeople"
                     matInput
                     [min]="1"
                     type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.peopleInFront" | translate }}</span>
            <mat-form-field appearance="outline"
                            class="marketing-automation w-16">
              <input [value]="numberOfMinutes.value"
                     formControlName="numberOfMinutes"
                     matInput
                     [min]="1"
                     type="number">
            </mat-form-field>
            <span class="m-2">{{ "marketingAutomation.minutes" | translate }}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="w-full pb-2 flex-col justify-start items-start gap-6 inline-flex"
         formArrayName="playlistMediaFiles"
         cdkDropList
         (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let mediaPl of playlistMediaFiles.controls; let index = index">
        <div [formGroupName]="index"
             *ngIf="!mediaPl.get('isRemoved').value"
             cdkDrag
             class="w-full justify-start items-end gap-2 inline-flex overflow-scroll">
          <div class="w-full justify-start items-end gap-2 inline-flex">
            <div class="justify-start items-end gap-4 flex">
              <div class="menu-drag">
                <a cdkDragHandle
                   mat-flat-button
                   [disableRipple]="true"
                   class="drag-button">
                  <mat-icon inline="true">menu</mat-icon>
                </a>
              </div>
              <div *ngIf="!mediaPl.get('media')?.value"
                   class="step-three-default-media-dimensions">

                <mat-label>{{ 'editSegment.form.media' | translate }}</mat-label>
                <mat-form-field appearance="outline"
                                class="w-full">
                  <mat-select formControlName="media"
                              placeholder="{{'editSegment.form.media' | translate}}">
                    <mat-option *ngFor="let media of selectedMedias"
                                [value]="media"
                                class="hidden">
                      {{ media?.name }}
                    </mat-option>
                    <mat-option (click)="openSelectMedia('media', index)"
                                [value]="null"
                                class="option-create">{{
                      'editSegment.form.chooseMedia'
                      | translate
                      }}
                    </mat-option>
                    <mat-option *flowHasPermission="['UpdateMediaCms']; disabled true;"
                                (click)="openCreateMedia( 'media', index)"
                                [value]="null"
                                class="option-create">{{
                      'editSegment.form.createNewMedia'
                      | translate
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="flex flex-col"
                   *ngIf="mediaPl.get('media').value">
                <mat-label>{{ 'editSegment.form.media' | translate }} *</mat-label>
                <div *ngIf="mediaPl.get('media').value"
                     class="preview">
                  <div (click)="openViewMedia(mediaPl.get('media')?.value, $event)"
                       class="list-thumbnail-container">
                    <div class="relative">
                      <div class="image-name">{{mediaPl.get('media')?.value?.name}}</div>
                    </div>
                    <img *ngIf="mediaPl.get('media').value?.type === MediaTypeEnum.Image"
                         [src]="mediaPl.get('media').value?.url"
                         class="list-thumbnail-container">

                    <div *ngIf="mediaPl.get('media').value?.type === MediaTypeEnum.Video"
                         class="thumbnail-placeholder">
                      <mat-icon>play_circle</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button (click)="onTriggerClick(mediaPl, $event)"
                        class="btn-trigger"
                        [ngClass]="mediaPl.get('numOfTriggers')?.value && mediaPl.get('numOfTriggers')?.value > 0 ? 'border-[#1F5792]' : 'border-[#DADADA]'"
                        mat-stroked-button
                        tabIndex=-1>
                  <span *ngIf="mediaPl.get('numOfTriggers')?.value && mediaPl.get('numOfTriggers')?.value > 0"
                        class="trigger-notification w-6 h-6 text-xs">{{ mediaPl.get('numOfTriggers')?.value ?
                    mediaPl.get('numOfTriggers')?.value : " " }}</span>
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            stroke="#202020"
                            size="md"
                            iconName="triggers"></app-icon>
                  <span>{{ 'editEngagement.triggerButton' | translate }}</span>
                </button>
              </div>
              <div class="flex flex-col w-[160px]">
                <mat-label>{{ 'editEngagement.presentationTimeLabel' | translate }}</mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="presentationTime"
                         matInput
                         [min]="1"
                         placeholder="{{'editEngagement.presentationTimePlaceholder' | translate }}"
                         type="number">
                </mat-form-field>
              </div>
            </div>
            <div (click)="removeMediaRow(mediaPl, index)"
                 class="p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex"
                 *ngIf="playlistMediaFiles.controls.length > 1">
              <app-icon [ngStyle]="{cursor:'pointer'}"
                        iconName="x"
                        size="md"
                        stroke="#202020">
              </app-icon>
            </div>
            <div (click)="addMediaRow(index)"
                 class="p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex">
              <app-icon [ngStyle]="{cursor:'pointer'}"
                        iconName="plus"
                        size="md"
                        stroke="#202020">
              </app-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>