import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AdminApi, Customer, PersonOfInteresetApi, PersonOfInterest, StatisticsApi, Tag } from '../../../../_api';
import { indicateLoading } from '@core/indicate-loading';
import { Media, MediaService } from '@core/services/media.service';
import { SystemMessageService } from '@core/services/system-message.service';
import { unsubscribeMixin } from '@core/unsubscribe';
import { IdName } from "../../../../_api/models/idname";
import { switchMap } from "rxjs/operators";
import { AuthService, UserRoleEnum } from "../../../core/auth/auth.service";
import { TagsApi } from '@api/services/tags-api.service';

@Component({
  selector: 'flow-person-of-interest-create',
  templateUrl: './person-of-interest-create.component.html',
  styleUrls: ['./person-of-interest-create.component.scss'],
})
export class PersonOfInterestCreateComponent extends unsubscribeMixin() implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  personOfInterestId: number;
  personOfInterest: PersonOfInterestCreate;
  customers: Customer[];
  categories: IdName[];
  form: FormGroup;
  selectedFile: any;
  filePreview: any;
  tags: Tag[]
  customerId: number
  isAdmin = false;
  editMode = false
  data: any

  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
    upload: new BehaviorSubject(false)
  }


  constructor(
    private adminApi: AdminApi,
    private tagsApi: TagsApi,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private personOfInterestApi: PersonOfInteresetApi,
    private statisticsServiceApi: StatisticsApi,
    private mediaService: MediaService,
  ) {
    super();
    this.personOfInterestId = +this.activatedRoute.snapshot.params['id'] || null;
    this.initForm();
  }

  ngOnInit(): void {
    this.editMode = !!this.personOfInterestId
    const user = this.authService.userData;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    forkJoin([
      this.adminApi.getCustomers(),
      this.personOfInterestId ? this.personOfInterestApi.getPersonOfInterest(this.personOfInterestId) : of(null),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([customers, poi,]) => {
        if (poi) {
          this.personOfInterest = poi;
          const mediaFile = {
            size: poi?.size,
            name: poi?.fileName,
            id: poi?.id,
            width: poi?.width,
            height: poi?.height
          }
          this.personOfInterest.media = this.mediaService.getPoiMedia(mediaFile)
        }
        this.customers = customers;
        this.initForm(this.personOfInterest);
      });
  }

  selectFile(event: any): void {
    this.selectedFile = event;
  }

  clearFile(): void {
    this.selectedFile = null;
    this.filePreview = null;
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    if (this.selectedFile) {
      const customerId = this.form.get('customerId').value;
      const name = this.form.get('name').value
      this.personOfInterestApi.uploadPoiImage({ customerId, name }, this.selectedFile?.selectedFile)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.upload))
        .subscribe((poiId) => {
          this.savePersonOfInterest(poiId);
        });
    } else {
      this.savePersonOfInterest(this.personOfInterest.id)
    }
  }

  onCancel() {
    this.router.navigate(['/person-of-interest'])
  }

  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  private savePersonOfInterest(poiId): void {
    const poi = this.form.getRawValue()
    // The new Tags must not have Id but the already crated ones must not have name
    let tags = (poi.tags || []).map(x => ({
      id: x.id || null,
      name: x.id ? null : x.name
    }));

    this.personOfInterestApi.createOrUpdatePersonOfInterest(poiId, { ...poi, tags })
      .pipe(
        takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.router.navigate(['/person-of-interest'])
        this.systemMessageService.success(`"Person of Interest" ${this.form.value.name} sparad!`);
      });
  }

  private initForm(personOfInterest?: PersonOfInterestCreate): void {
    this.form = this.formBuilder.group({
      id: this.personOfInterestId,
      name: [personOfInterest?.name, Validators.required],
      customerId: personOfInterest?.customer?.id || this.customerId,
      categoryId: personOfInterest?.category?.id,
      mediaId: personOfInterest?.media?.id,
      tags: this.formBuilder.control(personOfInterest?.tags),
      confirmation: false
    });
    this.form.get('customerId').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap(customerId => forkJoin([
        customerId ? this.statisticsServiceApi.getStatisticsCategories({ customerIds: [customerId] }) : of(null),
        customerId ? this.tagsApi.getTags({ customerIds: [customerId] }) : of(null)
      ])),
    ).subscribe(([categories, tags]) => {
      this.categories = categories
      this.tags = tags
    });

    if (this.personOfInterestId || !personOfInterest) {
      this.form.enable();
    } else {
      this.form.disable();
    }
  }
}

export interface PersonOfInterestCreate extends PersonOfInterest {
  media: Media;
  category?: IdName
  tags?: Tag[]
}

