import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GetMediasAndFoldersResponse } from "@api/models/get-medias-and-folders-response";
import { Observable } from "rxjs";
import { MediaFile } from "..";
import { ApiConfiguration } from "../api-configuration";
import { BaseService } from "../base-service";

@Injectable({
  providedIn: 'root',
})
export class MediaApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetMediaPath = '/medias';

  getMediasAndFolders(params): Observable<any> {
    return this.http.get<GetMediasAndFoldersResponse>(`${this.rootUrl}${MediaApi.GetMediaPath}/mediasAndFolders`, { params });
  }
  getMediaById(id) {
    return this.http.get(`${this.rootUrl}${MediaApi.GetMediaPath}/${id}`);
  }
  editMedia(media): Observable<any> {
    return this.http.put(`${this.rootUrl}${MediaApi.GetMediaPath}`, media);
  }
  removeMedias(params): Observable<any> {
    return this.http.delete(`${this.rootUrl}${MediaApi.GetMediaPath}`, {params});
  }
  mediaUploadImage(body) {
    const formData = new FormData();
    formData.append('file', body.file);

    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/images/upload/?${body.paramsString}`, formData);
  }
  mediaUploadVideo(body) {
    const formData = new FormData();
    formData.append('file', body.file);
    return this.http.post(`${this.rootUrl}${MediaApi.GetMediaPath}/videos/upload/?${body.paramsString}`, formData);
  }
}
