<div class="container-login">
  <div class="login-wrapper">
    <div class="login-card"
              *ngIf="!navigating">
      <div class="logo">
        <img src="../../../../assets/svg/logo.svg"
             alt="Logo">
      </div>
      <div *ngIf="loadingLogin || !authService.isLoggedIn()"
           class="header">
        <h3 class="welcome">Welcome</h3>
        <p>Please enter your details to Log In</p>
      </div>
      <form #f="ngForm"
            (ngSubmit)="onLogin(f.value.email, f.value.password)"
            *ngIf="loadingLogin || !authService.isLoggedIn()">
          <div>
            <label>Email</label>
            <mat-form-field appearance="outline"
                            class="w-full">
              <input matInput
                     placeholder="email"
                     type="email"
                     name="email"
                     id="email"
                     (ngModel)="email">
            </mat-form-field>
          </div>
          <div class="password-container">
          <mat-label>Password</mat-label>
          <mat-form-field appearance="outline"
                          class="w-full">
            <input matInput
                   placeholder="password"
                   type="password"
                   name="password"
                   id="password"
                   (ngModel)="password">
          </mat-form-field>
          </div>
          <div class="forgot-password">
            <a class="forgot-password-link"
               [routerLink]="['/confirm-email']">Forgot password?</a>
          </div>

        <button class="btn-primary-l w-full"
                [disabled]="loadingLogin">Log In</button>


      </form>
      <div *ngIf="!loadingLogin && authService.isLoggedIn()">
        <p class="text-center mb-6">
          {{ 'login.info' | translate }} {{authService.user?.email}}.
        </p>
        <button mat-flat-button
                color="accent"
                class="btn-block mr-2"
                type="button"
                (click)="logout()">{{ 'login.logOut' | translate }}</button>
        <a mat-flat-button
           color="primary"
           class="btn-block mt-2"
           type="button"
           [routerLink]="['/']">
          <mat-icon class="mr-2"
                    inline="true">home</mat-icon>
          {{ 'login.toHome' | translate }}
        </a>
      </div>
    </div>
    <div>
      <p>© BizLab Analytics 2024</p>
    </div>
  </div>
  <div class="image-wrapper">
  </div>
</div>
