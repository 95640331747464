import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Pipe({
  name: 'bookStep'
})
export class BookStepPipe implements PipeTransform {

  transform(i: number, activeStep: number, isEdit: boolean, bookForm?: any): string {
    let classes = ''
    if (activeStep === i) {
      classes += ' active-stepper-book';
    }
    if (activeStep !== i) {
      classes += ' inactive-stepper-book';
    }
    if (i > activeStep && bookForm && (!bookForm?.name || bookForm?.screens.length == 0 || (!isEdit && i > 1))) {
      classes += ' disabled';
    }
    return classes.trim();
  }

}
