import { Component, Inject, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BreadcrumbService } from '@app/core/services/breadcrumb.service';
import { Media } from '@app/core/services/media.service';


@Component({
  selector: 'flow-media-select-modal',
  templateUrl: './media-select.modal.html',
  styleUrls: ['./media-select.modal.scss']
})
export class MediaSelectModal implements OnDestroy {

  form: FormGroup;

  constructor(
    private breadcrumbService: BreadcrumbService,
    public dialogRef: MatDialogRef<MediaSelectModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {
  }

  ngOnDestroy(): void {
    this.breadcrumbService.clearBreadcrumbs();
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  onSelected(media: Media): void {
    this.dialogRef.close(media);
  }
}


interface ModalData {
  customerIdModal: number;
}
