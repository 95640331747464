import { BackendErrorsEnum } from "@api/models/be-errors-enum";

export const BackendErrorsTranslate = {
  [BackendErrorsEnum.ScreenUsed]: 'notifications.backendErrors.screenUsed',
  [BackendErrorsEnum.ScreenInUse]: 'notifications.backendErrors.screenInUse',
  [BackendErrorsEnum.ScreenNotFound]: 'notifications.backendErrors.screenNotFound',
  [BackendErrorsEnum.ScreenBooked]: 'notifications.backendErrors.screenBooked',
  [BackendErrorsEnum.ChannelUsedAtSegment]: 'notifications.backendErrors.channelUsedSegment',
  [BackendErrorsEnum.ChannelUsedAtScreen]: 'notifications.backendErrors.channelUsedScreen',
  [BackendErrorsEnum.MediaUsedAtAd]: 'notifications.backendErrors.mediaUsedInAd',
  [BackendErrorsEnum.MediaUsedAtDm]: 'notifications.backendErrors.mediaUsedInDm',
  [BackendErrorsEnum.mediasInUse]: 'notifications.backendErrors.mediasInUse',
  [BackendErrorsEnum.mediaInUse]: 'notifications.backendErrors.mediasInUse',
  [BackendErrorsEnum.mediaFileTooLarge]: 'notifications.backendErrors.mediaFileTooLarge',
  [BackendErrorsEnum.mediaNotFound]: 'notifications.backendErrors.mediaNotFound',
  [BackendErrorsEnum.mediaCannotRemoveClient]: 'notifications.backendErrors.mediaCannotRemoveClient',
  [BackendErrorsEnum.MediaNameAlreadyExists] : 'notifications.backendErrors.mediaNameAlreadyInUse',
  [BackendErrorsEnum.EngagementNameUsed]: 'notifications.backendErrors.engagementNameUsed',
  [BackendErrorsEnum.PlacementNameUsed]: 'notifications.backendErrors.placementNameUsed',
  [BackendErrorsEnum.FolderNameUsed]: 'notifications.backendErrors.folderNameUsed',
  [BackendErrorsEnum.TransactionFailed]: 'notifications.backendErrors.transactionFailed',
  [BackendErrorsEnum.SegmentNotFound]: 'notifications.backendErrors.segmentNotFound',
  [BackendErrorsEnum.ChannelNotFound]: 'notifications.backendErrors.channelNotFound',
  [BackendErrorsEnum.CustomerNotFound]: 'notifications.backendErrors.customerNotFound',
  [BackendErrorsEnum.UserNotFound]: 'notifications.backendErrors.userNotFound',
  [BackendErrorsEnum.EngagementNotFound]: 'notifications.backendErrors.engagementNotFound',
  [BackendErrorsEnum.PlacementNotFound]: 'notifications.backendErrors.placementNotFound',
  [BackendErrorsEnum.FolderNotFound]: 'notifications.backendErrors.folderNotFound',
  [BackendErrorsEnum.TagNotFound]: 'notifications.backendErrors.tagNotFound',
  [BackendErrorsEnum.CustomerStorageSizeLimitReached]: 'notifications.backendErrors.customerStorageSizeLimitReached',
  [BackendErrorsEnum.AdvertisementNotFound]: 'notifications.backendErrors.advertisementNotFound',
  [BackendErrorsEnum.ServerError]: 'notifications.backendErrors.serverError',
  [BackendErrorsEnum.EmptyFolderName]: 'notifications.backendErrors.emptyFolderName',
  [BackendErrorsEnum.TagNameAlreadyUsed]: 'notifications.backendErrors.tagNameAlreadyUsed',
  [BackendErrorsEnum.ActivationRuleMissing]: 'notifications.backendErrors.activationRuleMissing',
  [BackendErrorsEnum.MAAlreadyExists]: 'notifications.backendErrors.MAAlreadyExists',
  [BackendErrorsEnum.MAInvalidRules]: 'notifications.backendErrors.MAInvalidRules',
  [BackendErrorsEnum.MAAlreadyDefined]: 'notifications.backendErrors.MAAlreadyDefined',
  [BackendErrorsEnum.MANotFound]: 'notifications.backendErrors.MANotFound',
  [BackendErrorsEnum.UserAlreadyRegistered]: 'notifications.backendErrors.userAlreadyRegistered',
  [BackendErrorsEnum.ContactPersonEmailExists]: 'notifications.backendErrors.contactPersonEmailExists',
  [BackendErrorsEnum.ContactPersonPhoneExists]: 'notifications.backendErrors.contactPersonPhoneExists',
  [BackendErrorsEnum.ContactPersonNotFound]: 'notifications.backendErrors.contactPersonNotFound',
  [BackendErrorsEnum.ContactPersonLimitReach]: 'notifications.backendErrors.contactPersonLimitReach',
  [BackendErrorsEnum.ContactPersonNegativeNumber]: 'notifications.backendErrors.contactPersonNegativeNumber',
  [BackendErrorsEnum.ContactPersonInvalidPhone]: 'notifications.backendErrors.contactPersonInvalidPhone',
  [BackendErrorsEnum.ContactPersonListEmpty]: 'notifications.backendErrors.contactPersonListEmpty',
  [BackendErrorsEnum.ContactPersonLimitDefaultValue]: 'notifications.backendErrors.contactPersonLimitDefaultValue',
  [BackendErrorsEnum.StorageLimitSizeDefaultValue]: 'notifications.backendErrors.storageLimitSizeDefaultValue',
  [BackendErrorsEnum.StorageLimitNegativeNumber]: 'notifications.backendErrors.storageLimitNegativeNumber',
  [BackendErrorsEnum.CategoryNameCannotBeEmpty]: 'notifications.backendErrors.categoryNameCannotBeEmpty',
  [BackendErrorsEnum.CategoryNameIsNotUnique]: 'notifications.backendErrors.categoryNameIsNotUnique',
  [BackendErrorsEnum.CategoryNotFound]: 'notifications.backendErrors.categoryNotFound',
  [BackendErrorsEnum.CategoryInUse]: 'notifications.backendErrors.categoryInUse',
  [BackendErrorsEnum.CategoryWithoutNotifications]: 'notifications.backendErrors.categoryWithoutNotifications',
  [BackendErrorsEnum.CategoryMinutesException]: 'notifications.backendErrors.categoryMinutesException',
  [BackendErrorsEnum.CategorySMSException]: 'notifications.backendErrors.categorySMSException',
  [BackendErrorsEnum.POILimitReached]: 'notifications.backendErrors.POILimitReached',
  [BackendErrorsEnum.POINotFound]: 'notifications.backendErrors.POINotFound',
  [BackendErrorsEnum.POIWithoutCategory]: 'notifications.backendErrors.POIWithoutCategory',
  [BackendErrorsEnum.POINegativeNumberAtCustomer]: 'notifications.backendErrors.POINegativeNumberAtCustomer',
  [BackendErrorsEnum.AINotFound]: 'notifications.backendErrors.AINotFound',
  [BackendErrorsEnum.PlaylistNotFound]: 'notifications.backendErrors.playlistNotFound',
  [BackendErrorsEnum.PlaylistMediaNotFound]: 'notifications.backendErrors.playlistMediaNotFound',
  [BackendErrorsEnum.ChildPlaylistNotFound]: 'notifications.backendErrors.childPlaylistNotFound',
  [BackendErrorsEnum.ClientNameExists]: 'notifications.backendErrors.clientNameExists',
  [BackendErrorsEnum.ClientNotFound]: 'notifications.backendErrors.clientNotFound',
  [BackendErrorsEnum.ClientVATNumberExists]: 'notifications.backendErrors.vatNumberExists',
  [BackendErrorsEnum.InvalidCredentials]: "notifications.backendErrors.invalidCredentials",
  [BackendErrorsEnum.InvalidPowerDownBeforePowerUpSchedule]: "notifications.backendErrors.invalidPowerDownBeforePowerUpSchedule"
};