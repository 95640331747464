<div class="w-[700px] h-[82px] ml-[26px] justify-center items-end inline-flex">
  <div class="w-[180px] py-1.5"></div>
  <div class="justify-start items-start gap-px flex"
       *ngFor="let day of week">
    <div class="w-[66px] h-16  bg-white border-b border-l border-r border-neutral-100">
      <div class="flex-col justify-start items-end flex pr-2">
        <div class="justify-center items-center gap-2 inline-flex">
          <div class="text-neutral-800 text-xs font-normal font-['Inter'] capitalize leading-[18px]">{{day.dayName |
            translate}}</div>
        </div>
        <div *ngIf="day.dateNumber === todayNumber"
             class="NumberCalendar p-1 bg-orange-600 rounded-[20px] w-[22px] h-[22px] justify-center items-center gap-2 inline-flex">
          <div class="text-neutral-50 text-sm font-bold font-['Inter'] capitalize leading-none">{{day.dateNumber}}</div>
        </div>
        <div *ngIf="day.dateNumber !== todayNumber"
             class="NumberCalendar justify-center items-center gap-2 inline-flex">
          <div class="text-neutral-800 text-base font-bold font-['Inter'] capitalize leading-tight">{{day.dateNumber}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="w-full overflow-scroll">
  <div *ngFor="let booking of bookings"
       class="cursor-pointer">
    <!-- screen info-->
    <div class="w-[700px] h-[82px]  justify-center items-end inline-flex">
      <div class="w-[180px] py-1.5 border-b border-neutral-100 flex-col justify-start items-start inline-flex max-h-[82px]"
           (click)="showMore($event, booking)">
        <div class="flex-col justify-start items-start flex">
          <div class="text-zinc-600 text-xs font-normal font-['Inter'] lowercase leading-[14px]">{{booking?.screenGroup
            ? 'screen group': 'screen'}}</div>
          <div class="w-[164px] justify-start items-center gap-1 inline-flex">
            <div
                 class="w-[140px] text-neutral-800 text-sm font-semibold font-['Inter'] capitalize leading-[18px] truncate">
              {{booking?.screenGroup ? booking?.screenGroup?.name : booking.screens[0].name}}</div>
            <div class="w-6 h-6 origin-top-left justify-center items-center flex">
              <div class="w-6 h-6 relative flex-col justify-start items-start flex">
                <app-icon iconName="{{booking?.orientation == 1 ? 'portrait' : 'landscape'}}"
                          fill="rgba(0,0,0,0)"
                          size="md">
                </app-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-col justify-start items-start flex">
          <div class="text-zinc-600 text-xs font-normal font-['Inter'] lowercase leading-[14px]">{{'book.channel' |
            translate}}</div>
          <div class="text-neutral-800 text-sm font-normal font-['Inter'] capitalize leading-[18px]">
            {{booking?.channels.join(', ')}}</div>
        </div>

        <div class="w-[16.3rem] relative  bg-white top-[-15px] left-[180px]  rounded shadow border border-neutral-100 z-10 flex-col"
             *ngIf="showInfo && (selectedScreen === booking?.screens[0]?.name || selectedScreen=== booking?.screenGroup?.name) ">
          <div class="flex flex-col border-b border-neutral-100 p-4">
            <div class="flex justify-between">
              <div class="text-neutral-800 text-sm font-semibold font-['Inter'] leading-[18px]">{{booking?.screenGroup ?
                booking?.screenGroup?.name : booking.screens[0].name}}</div>
              <a (click)="showLess($event)">
                <app-icon [ngStyle]="{cursor:'pointer'}"
                          stroke="#202020"
                          size="sm"
                          iconName="x">
                </app-icon>
              </a>
            </div>
            <div class="text-neutral-800 text-xs font-normal font-['Inter'] leading-none">{{booking?.screenGroup ?
              ('book.screenGroup' | translate) : ('book.screen' | translate)}}</div>
          </div>
          <div class="flex pt-4 pr-4 pl-4 justify-between">
            <div class="text-neutral-800 text-sm font-semibold font-['Inter'] leading-[18px]">{{'book.orientation' |
              translate}}</div>
            <app-icon iconName="{{booking?.orientation == 1 ? 'portrait' : 'landscape'}}"
                      fill="rgba(0,0,0,0)"
                      size="md">
            </app-icon>
          </div>
          <div class="flex-col pl-4 pr-4 pb-2 pt-2">
            <div class="text-neutral-800 text-sm font-semibold font-['Inter'] leading-[18px]">{{'book.channels' |
              translate}}</div>
            <div>{{booking?.channels.join(', ')}}</div>
          </div>
        </div>
      </div>
      <div class="select-week">
        <mat-checkbox *ngIf="hideSelection(booking.engagementSlots)"
                      color="primary"
                      [checked]="isWeekSelected(booking)"
                      [matTooltip]="'book.selectWeek' | translate"
                      (change)="selectWeek(booking?.engagementSlots)"
                      [disabled]="currentWeekEndDate < todayDate">
        </mat-checkbox>
      </div>
      <!--  days-->
      <ng-container *ngFor="let slot of booking?.engagementSlots">
        <div class="justify-start items-start gap-px flex"
        [ngClass]="{
          'opacity-50 cursor-not-allowed pointer-events-none': slot.startDate < todayDate,
          'cursor-pointer': slot.startDate >= todayDate
        }"
             *ngIf="slot.startDate | compareDates: currentWeekDays"
             (click)="slot.startDate >= todayDate && selectSlot(slot)">
          <div class="w-[66px] h-16 relative cursor-pointer"
               *ngIf="!slot.isBooked && !slot.isMainBooking">
            <div class="w-[66px] h-16 left-0 top-0 absolute bg-white border border-neutral-100"></div>
            <div class="left-[8px] top-[8px] absolute justify-center items-center gap-1.5 inline-flex">
              <div class="w-1 h-1 bg-teal-700 rounded-full"></div>
              <div class="text-zinc-600 text-[8px] font-normal font-['Inter'] lowercase leading-[10px]">{{
                'book.available' | translate}}</div>
            </div>
          </div>
          <div class="w-[66px] h-16 relative"
               *ngIf="slot.isBooked && !slot.isMainBooking">
            <div class="w-[66px] h-16 left-0 top-0 absolute bg-[#EFC2A9] border-b border border-neutral-100"></div>
          </div>
          <div class="w-[66px] h-16 relative cursor-pointer"
               *ngIf="slot.isMainBooking && !slot.isBooked">
            <div class="w-[66px] h-16 left-0 top-0 absolute bg-[#1f5792] border-b border border-neutral-100"></div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>