import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DetailMedia, MediaTypeEnum, StandardMedia } from "../../../../../_api";
import { environment } from "../../../../../environments/environment";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { unsubscribeMixin } from "../../../../core/unsubscribe";
import { takeUntil } from "rxjs";
import { MediaModalService } from "../../../../core/services/media-modal.service";

@Component({
  selector: 'flow-book-step-four',
  templateUrl: './book-step-four.component.html',
  styleUrls: ['./book-step-four.component.scss']
})
export class BookStepFourComponent extends unsubscribeMixin() implements OnInit {
  @Input() marketingAutomationForm!: FormGroup;
  @Input() bookForm!: FormGroup;
  @Input() mediaForm!: FormGroup;
  @Input() presentationTime!: number;
  @Input() isEdit!: boolean;
  medias: any[] = [{ id: 1, name: 'test1' }]
  selectedMedias: DetailMedia[] = [];
  protected readonly MediaTypeEnum = MediaTypeEnum;


  constructor(
    private mediaModalService: MediaModalService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
    super()
  }

  ngOnInit(): void {
    if (this.playlistMediaFiles.value.length == 0 && this.isEdit)
      this.populateMediaFromStep3()

    if (!this.isEdit)
      this.populateMediaFromStep3()

    this.playlistMediaFiles.controls.forEach
      ((m: any) => m.controls.media.value?.type === 2 ? m.controls.presentationTime.disable() : m.controls.presentationTime.enable())

    this.selectedMedias = this.playlistMediaFiles.getRawValue().map(adv => {
      if (this.isEdit) {
        adv.media.thumbnailUrl = `${environment.apiBaseUrl}${adv.media.thumbnailImageUrl}`;
        adv.media.url = `${environment.apiBaseUrl}${adv.media.imageUrl}`;
        return adv.media
      }
    });

    if (!this.hasAdvanceSetting.value && !this.sensitivitySettings.value)
      this.sensitivitySettings.setValue(2)

    this.hasAdvanceSetting.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(change => {
        if (!change) {
          this.sensitivitySettings.setValue(2)

        } else
          this.sensitivitySettings.setValue(null)
      })
  }

  addMediaRow(index: number) {
    this.playlistMediaFiles.insert(index + 1, this.createMarketingAutoPlaylist(index + 1));
    this.cdr.detectChanges();
  }

  removeMediaRow(mediaPl, index: number) {

    if (this.playlistMediaFiles.value.filter(ad => ad.isRemoved).length + 1 === this.playlistMediaFiles.value.length) {
      return;
    }

    if (!mediaPl.get('id').value) {
      this.playlistMediaFiles.removeAt(index);
    } else {
      mediaPl.get('isRemoved').setValue(true);
    }
  }
  openCreateMedia(propertyName: string, index: number): void {
    this.mediaModalService.openCreateMediaModal(this.playlistMediaFiles.at(index), propertyName, this.selectedMedias, { customerIdModal: this.bookForm.get('customer').getRawValue() }, index);
  }
  openSelectMedia(propertyName: string, index: number): void {
    this.mediaModalService.openSelectMediaModal(this.playlistMediaFiles.at(index), propertyName, this.selectedMedias, { customerIdModal: this.bookForm.get('customer').getRawValue() }, index);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.playlistMediaFiles.controls, event.previousIndex, event.currentIndex);
  }


  get playlistMediaFiles(): FormArray {
    return this.marketingAutomationForm.get('marketingAutomation.playlistMediaFiles') as FormArray;
  }

  get numberOfMinutes(): FormControl {
    return this.marketingAutomationForm.get('marketingAutomation.advancedSettings.numberOfMinutes') as FormControl;
  }

  get configurationRuleOneActivated(): FormControl {
    return this.marketingAutomationForm.get('marketingAutomation.advancedSettings.numberOfMinutes') as FormControl;
  }

  get configurationRuleTwoActivated(): FormControl {
    return this.marketingAutomationForm.get('marketingAutomation.advancedSettings.numberOfMinutes') as FormControl;
  }

  get hasAdvanceSetting(): FormControl {
    return this.marketingAutomationForm.get('marketingAutomation.hasAdvancedSettings') as FormControl;
  }

  get sensitivitySettings(): FormControl {
    return this.marketingAutomationForm.get('marketingAutomation.sensitivitySettings') as FormControl;
  }


  private populateMediaFromStep3() {
    this.mediaForm.value.advertisements.map((media, index) => {
      return this.playlistMediaFiles.push(this.formBuilder.group({
        id: [null],
        orderId: [index + 1 || 1],
        media: media.mediaDetails,
        triggers: media.triggers,
        numOfTriggers: [this.calculateNumOfTriggers(media?.triggers)],
        presentationTime: [media?.presentationTime || media?.mediaDetails?.duration, Validators.required],
        isRemoved: [false]
      }))
    })

  }

  private calculateNumOfTriggers(triggers: any) {
    return triggers ? (triggers.temperature ? 1 : 0) + (triggers.weatherConditions ? triggers.weatherConditions.length : 0) : 0
  }
  private createMarketingAutoPlaylist(index, mediaForm?): any {
    return this.formBuilder.group({
      id: [null],
      orderId: [index + 1 || 1, Validators.required],
      media: [],
      triggers: [{ temperature: null, weatherConditions: null }],
      numOfTriggers: [0],
      presentationTime: [this.presentationTime, Validators.required],
      isRemoved: [false, Validators.required]
    });
  }


  openViewMedia(standardMedia: StandardMedia, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openViewMediaModal(standardMedia)
  }

  onTriggerClick(mediaPl: any, $event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openTriggerMediaModal(mediaPl?.get('triggers').value,
      (triggers: any) => {
        if (!triggers || (triggers && (triggers.temperature == null && (!triggers.weatherConditions || (triggers.weatherConditions && triggers.weatherConditions.length == 0)))))
          triggers = null;
        mediaPl?.get('triggers')?.patchValue(triggers);
        mediaPl?.get('numOfTriggers')?.patchValue(this.getNumberOfTriggers(mediaPl));
      });
  }
  getNumberOfTriggers(mediaPl: any) {
    let weatherConditionTriggers = 0;
    let temperatureTriggers = 0

    if (mediaPl?.get('triggers').value) {
      weatherConditionTriggers = mediaPl?.get('triggers').value.weatherConditions && mediaPl?.get('triggers').value.weatherConditions.length > 0 ? mediaPl?.get('triggers').value.weatherConditions.length : 0;
      temperatureTriggers = mediaPl?.get('triggers').value.temperature && Object.keys(mediaPl?.get('triggers').value.temperature).length > 0 ? 1 : 0;
    }

    return weatherConditionTriggers + temperatureTriggers;
  }
}
