import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { DateTime } from 'luxon';
import { forkJoin, of, takeUntil } from 'rxjs';
import {
  AdminApi,
  Channel,
  Customer,
  EngagementsAdminApi,
  Screen,
  StatisticsApi,
  TimeframeEnum,
} from 'src/_api';
import { ActiveReportEnum } from 'src/_api/models/active-report-enum';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { AuthService, UserRoleEnum } from 'src/app/core/auth/auth.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { StatisticsFilterValues } from 'src/app/models/statistics-filter-values';
import { PeopleCountFormGroup, peopleTargetGroups } from '../people-count/people-count-models';
import { PermissionsService } from '@app/core/services/permissions.service';
import {IdName} from "@api/models/idname";
import {EnumService} from "@core/services/enum.service";
import {MonthTranslations} from "@models/month-translation";

@Component({
  selector: 'flow-reach',
  templateUrl: './reach.component.html',
  styleUrls: ['./reach.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReachComponent extends unsubscribeMixin() implements OnInit {
  pageLoad: boolean = false;
  computedFontSize: string = '2rem';
  filter: StatisticsFilterValues;
  initData: { screens: Screen[]; statistics: PeopleCounterResponse } = {
    screens: [],
    statistics: {},
  };
  statistics: PeopleCounterResponse;
  compareStatistics: PeopleCounterResponse;
  peopleTargetGroupsStatistics: { label: string; currentWeek: number }[] = [];
  channels: Channel[];
  filteredChannels: Channel[];
  customers: Customer[];
  form: FormGroup<PeopleCountFormGroup>;
  isAdmin: boolean;
  customerId: number;
  timeframeTypes = [];
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  totalReach: number = 0;
  selectedChannels: Channel[];
  selectedIndex: number = 0;
  consentKey = 'reach-consent';
  consentGiven = false;
  selectedChannel;
  ActiveReportEnum = ActiveReportEnum;
  activeStep: number = 0;
  vehiclesData = [];
  vehiclesDataCompare = [];
  periodWeek: string;
  targetGroupWithLabels: any[];
  disableReachAnalytics: boolean = false;
  disableReachReportsAnalytics: boolean = false;
  screens: IdName[];
  compare = false;
  start: DateTime;
  end: DateTime;
  range: any
  initialLoad = true;
  constructor(
    private adminApi: AdminApi,
    private statisticsApi: StatisticsApi,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private engagementsAdminApi: EngagementsAdminApi,
    private translateConfigService: TranslateConfigService,
    private permissionsService: PermissionsService,
    private enumService: EnumService,
  ) {
    super();
    this.timeframeTypes = this.enumService.timeframeTypes;
  }

  ngOnInit(): void {
    if (!this.permissionsService.hasPermissions(['ViewReachAnalytics'])) {
      this.disableReachAnalytics = true;
    }
    if (!this.permissionsService.hasPermissions(['ViewReachReportsAnalytics'])) {
      this.disableReachReportsAnalytics = true;
    }
    if (this.disableReachAnalytics && !this.disableReachReportsAnalytics) this.selectedIndex = 1;
    this.checkConsent();
    this.initForm();
    const user = this.authService.userData;
    this.isAdmin = user.roleId === UserRoleEnum.Admin && !user.currentCustomerId;
    if (!this.isAdmin) {
      const userCustomerId = user?.currentCustomerId || user?.customerId;
      this.customerId = userCustomerId;
      this.getData([userCustomerId]);
    } else {
      this.getData();
      this.form.get('timeframe').setValue(TimeframeEnum.CurrentWeek);
    }
  }

  getData(customerIds?: number[]) {
    let params: any = {status: true};
    if (customerIds && customerIds.length > 0)
      params.customerIds = customerIds;
    forkJoin([
      customerIds && customerIds.length > 0
        ? this.adminApi.getChannels(params)
        : of(null),
      this.isAdmin ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin
        ? this.adminApi.getCustomerById(this.customerId)
        : of(null),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([channels, customers, customer]) => {
        this.customers = customers;
        this.channels = channels || [];
        if (!this.isAdmin) {
          this.customers = [customer];
          this.filteredChannels = channels;
          this.form.get('customer').setValue(customer);
          if(channels[0]) {
            this.selectedChannel = [channels[0]];
            this.form.get('channels').setValue(channels);
          }
        }
      });
  }

  translateMonth(month: string) {
    if (month?.length > 0)
      return `${this.translateConfigService.instant(MonthTranslations[month])}`
    else return '';
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      customer: this.formBuilder.control(null, Validators.required),
      channels: this.formBuilder.control(null, Validators.required),
      screens: this.formBuilder.control(null, Validators.required),
      timeframe: this.formBuilder.control(1, Validators.required),
      start: this.formBuilder.control(DateTime.now().startOf('week'), Validators.required),
      end: this.formBuilder.control(DateTime.now().endOf('week'), Validators.required),
      rangeCompare: this.formBuilder.group({
        start: this.formBuilder.control(null),
        end: this.formBuilder.control(null),
      }),
    });
    if (this.isAdmin) {
      this.form.get('channels').disable();
    }
    this.form.get('customer').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((customer) => {
        if (this.isAdmin) {
          const channels = customer.channels;
          this.isChannels = false;
          this.filteredChannels = channels.filter(channel => channel.isActive);
          if (this.filteredChannels && this.filteredChannels?.length > 0) {
            this.form.get('channels').enable();
          } else {
            this.form.get('channels').disable();
          }
          this.computedFontSize = '2rem';
          this.pageLoad = false;
        }
      });
    this.form.get('channels').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((channels) => {
      this.isChannels = false;
      if (channels?.length > 0) {
        this.selectedChannels = channels;
        this.enableScreensControl();
        this.loadScreens();
      } else {
        this.resetScreensControl();
      }
    });

    this.form.get('timeframe').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((timeframe)=> {
        this.compare = false
        if(timeframe === TimeframeEnum.PreviousWeek || timeframe === TimeframeEnum.CurrentWeek) {
          this.form.get('rangeCompare').reset()
          this.compareStatistics = null
        }
      });

    this.form.get('rangeCompare').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comparison) => {
        if(comparison.start && comparison.end ) {
          this.compare = true
        }else {
          this.compare = false
        }
      })
  }

  onSubmit() {
    if(this.form.value.customer && this.form.value.channels) {
      if (this.activeStep == 1) {
        this.getVehiclesData();
      } else {
        this.getPeopleData();
      }
    }
  }

  private enableScreensControl() {
    this.form.get('screens').enable();
  }

  private resetScreensControl() {
    this.form.get('screens').disable();
    this.form.get('screens').reset();
    this.statistics = null;
    this.initData = null;
  }

  private loadScreens() {
    const customerId = this.form.get('customer').value.id;
    const channelIds = this.selectedChannels.map(x => x.id);

    this.engagementsAdminApi.getScreens({ customerIds: [customerId], channelIds }).subscribe((screens) => {
      this.screens = screens as IdName[];
      this.handleScreensForNonAdmin();
    });
  }

  private handleScreensForNonAdmin() {
    if (!this.isAdmin) {
      this.form.get('screens').setValue([this.screens[0]]);
      if (this.initialLoad) {
        this.onSubmit();
        this.initialLoad = false;
      }
    }
  }

  getStatistics(customerId: number, channels: Channel[], screens: IdName[], start: DateTime, end: DateTime, compare: boolean) {
    const screenIds = screens?.map(x => x.id)
    const channelIds = channels.map((l) => l.id);
    return this.statisticsApi.reachPerDay({
      startTime: start?.toISODate(),
      endTime: end?.toISODate(),
      customerId,
      channelIds,
      screenIds
    });
  }

  private calculateTargetGroupStatistic(statistics: PeopleCounterResponse) {
    const currentWeekStart = this.start.startOf('day').startOf('week');
    const currentWeekEnd = this.end
      .startOf('day')
      .endOf('week')
      .endOf('day');

    const currentWeekStats = statistics.screenHours.filter((s) => {
      const dt = DateTime.fromISO(s.time);
      return dt >= currentWeekStart && dt <= currentWeekEnd;
    });
    const targetGroupsStats = peopleTargetGroups.map((group) => ({
      currentWeek: currentWeekStats.reduce(
        (sum, curr) =>
          sum +
          curr.peoples
            .filter((p) => group.value === p.targetGroup)
            .reduce((sum, stat) => sum + stat.count, 0),
        0
      ),
      label: group.label,
    }));

    const result = [];

    for (let i = 0; i <= 6; i++) {
        result.push({
            currentWeek: targetGroupsStats[i].currentWeek + targetGroupsStats[i + 7].currentWeek,
            label: targetGroupsStats[i].label
        });
    }

    const opportunityToSeeTotal = statistics.screenHours.reduce(
      (sum, curr) => (sum += curr.opportunityToSee),
      0
    );
    return [
      {
        label: 'peopleCount.total',
        currentWeek: opportunityToSeeTotal + targetGroupsStats.reduce(
          (sum, curr) => (sum += curr.currentWeek),
          0
        ),
      },
      ...result,
      {
        label: 'peopleCount.targetGroups.opportunityToSee',
        currentWeek: opportunityToSeeTotal,
      },
    ];
  }

  onTabChange(event) {
    if(event.index === 1) {
      this.router.navigate(['/statistics/reach']);
    }
  }

  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }

  setActiveStep(value) {
    if (value == 0 && !this.statistics){
      this.getPeopleData();
    } else if (value === 1) {
      this.getVehiclesData();
    }
    this.activeStep = value;
  }
  getVehiclesData() {
    forkJoin([
      this.statisticsApi.getVehicles({ startTime: this.form.get('start').value.toFormat('yyyy-MM-dd'), endTime: this.form.get('end').value.toFormat('yyyy-MM-dd'), customerId: this.form.get('customer').value.id, channelIds: this.form.get('channels').value.map(channel => channel.id), screenIds:  this.form.get('screens').value.map(s => s.id) }),
      this.compare ? this.statisticsApi.getVehicles({ startTime: this.form.get('rangeCompare').get('start').value.toFormat('yyyy-MM-dd'), endTime: this.form.get('rangeCompare').get('end').value.toFormat('yyyy-MM-dd'), customerId: this.form.get('customer').value.id, channelIds: this.form.get('channels').value.map(channel => channel.id), screenIds:  this.form.get('screens').value.map(s => s.id) }) : of(null)
    ]).pipe(takeUntil(this.ngUnsubscribe)).subscribe(([vehiclesData,vehiclesCompareData]) => {
      this.start = this.form.get('start').value
      this.end = this.form.get('end').value
      this.range = this.form.get('rangeCompare').value
      this.vehiclesData = [
          { label: this.translateConfigService.instant('global.total'), value: vehiclesData.total ?? 0 },
          { label: this.translateConfigService.instant('reach.vehicles.cars'), value: vehiclesData.cars ?? 0 },
          { label: this.translateConfigService.instant('reach.vehicles.vans'), value: vehiclesData.vans ?? 0},
          { label: this.translateConfigService.instant('reach.vehicles.buses'), value: vehiclesData.busses ?? 0},
          { label: this.translateConfigService.instant('reach.vehicles.trucks'), value: vehiclesData.trucks ??  0},
          { label: this.translateConfigService.instant('reach.vehicles.ots'), value: vehiclesData.opportunityToSee ?? 0}
        ]

      this.vehiclesDataCompare = [
        { label: this.translateConfigService.instant('global.total'), value: vehiclesCompareData?.total ?? 0},
        { label: this.translateConfigService.instant('reach.vehicles.cars'), value: vehiclesCompareData?.cars ?? 0},
        { label: this.translateConfigService.instant('reach.vehicles.vans'), value: vehiclesCompareData?.vans ?? 0 },
        { label: this.translateConfigService.instant('reach.vehicles.buses'), value: vehiclesCompareData?.busses ?? 0},
        { label: this.translateConfigService.instant('reach.vehicles.trucks'), value: vehiclesCompareData?.trucks ?? 0},
        { label: this.translateConfigService.instant('reach.vehicles.ots'), value: vehiclesCompareData?.opportunityToSee ?? 0}
      ]
    })
  }

  getPeopleData() {
    this.statistics = null;
    this.compareStatistics = null;
    forkJoin([
      this.getStatistics(this.form.get('customer').value.id, this.selectedChannels, this.form.get('screens').value, this.form.get('start').value, this.form.get('end').value, this.compare),
      this.compare ? this.getStatistics(this.form.get('customer').value.id, this.selectedChannels, this.form.get('screens').value, this.form.get('rangeCompare').get('start').value, this.form.get('rangeCompare').get('end').value, this.compare) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([statistics, compareStatistics]) => {
        this.isChannels = true;
        this.statistics = statistics;
        this.compareStatistics = compareStatistics;
        this.start = this.form.get('start').value
        this.end = this.form.get('end').value
        this.range = this.form.get('rangeCompare').value
        this.periodWeek = `${this.start.toFormat('MM-dd')} - ${this.end.toFormat('MM-dd')}`;
        this.peopleTargetGroupsStatistics = this.calculateTargetGroupStatistic(statistics);
        this.totalReach = this.peopleTargetGroupsStatistics.find(target => target.label === 'peopleCount.total').currentWeek;
        this.targetGroupWithLabels = this.peopleTargetGroupsStatistics.map((item: any) => {
          if (item.label.includes('targetGroups')) {
            return {
              ...item,
              label: item.label.replace('targetGroups', 'targetGroups.metrics')
            }
          } else {
            return item;
          }
        }).slice();
      });
  }

}
