import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfiguration, BaseService, BookEngagement } from 'src/_api';
import { BookingsResponse } from '../models/booking/bookings-response';
import { BookingsRequest } from '../models/booking/bookings-request';
import { UpdateAdvertisement } from '../models/booking/update-advertisment';

@Injectable({
  providedIn: 'root'
})
export class BookingApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  static readonly GetBookingPath = 'Bookings';

  getBookings(bookingsRequest: BookingsRequest) : Observable<any> {
    return this.http.get<BookingsResponse>(`${this.rootUrl}/${BookingApiService.GetBookingPath}/weekly`, {params: bookingsRequest as any});
  }
  createNewBooking(booking: BookEngagement): Observable<any> {
    return this.http.post(`${this.rootUrl}/${BookingApiService.GetBookingPath}/book`, booking);
  }
  copyBooking(engagementId: number, booking: BookEngagement): Observable<any> {
    return this.http.post(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}/copy`, booking);
  }
  getSegmentDetails(segmentId:number): Observable<any> {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/segment/${segmentId}`);
  }
  getPlaylistDetails(playlistId:number): Observable<any> {
    return this.http.get(`${this.rootUrl}/${BookingApiService.GetBookingPath}/playlist/${playlistId}`);
  }
  updateGeneralInfoAndTimePeriod(engagementId: number, booking: BookEngagement): Observable<any> {
    return this.http.put(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}/generalinfo`, {
      name: booking.name,
      bookingSlots: booking.bookingSlots
    });
  }
  updateMediaSegment(engagementId: number, advertisements: UpdateAdvertisement): Observable<any> {
    return this.http.put(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}/advertisements`, advertisements);
  }
  updateMediaPlaylist(engagementId: number, playlsitMediaFiles: UpdateAdvertisement): Observable<any> {
    return this.http.put(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}/playlistmediafiles`, playlsitMediaFiles);
  }
  updateMarketingAutomation(booking: any, engagementId: number): Observable<any> {
    if (!booking.marketingAutomation.hasAdvancedSettings){
      booking.marketingAutomation.advancedSettings = null;
    }
    return this.http.put(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}/marketingautomation`, booking);
  }
  deleteBooking(engagementId: number): Observable<any>{
    return this.http.delete(`${this.rootUrl}/${BookingApiService.GetBookingPath}/${engagementId}`)
  }
}
