import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdminApi, Customer, StatisticsApi, TimeframeEnum} from "../../../_api";
import {EnumService} from "../../core/services/enum.service";
import {TranslateConfigService} from "../../core/translate-config.service";
import {DateTime} from "luxon";
import {AuthService, UserRoleEnum} from "../../core/auth/auth.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {SmsStatisticsResponse} from 'src/_api/models/sms-statistics-response';
import {SmsStatisticData} from 'src/_api/models/sms-statistics-data';
import { MonthTranslations } from '@app/models/month-translation';


@Component({
  selector: 'flow-sms', templateUrl: './sms.component.html', styleUrls: ['./sms.component.scss']
})
export class SmsComponent implements OnInit {
  form: FormGroup;
  timeframeTypes = [];
  startDate: any;
  endDate: any;
  isAdmin: boolean
  customerId: number;
  customers: Customer[];
  chartData: SmsStatisticsResponse;
  topRecipient: { noSmsForTopRecipinet: number, topRecipient: string }

  displayedColumns: string[] = ['name', 'emailAddress', 'phoneNumber', 'smsNotifications', 'status'];
  dataSource: MatTableDataSource<SmsStatisticData>;
  @ViewChild(MatSort) sort: MatSort;

  public translations = {
    totalNumberOfSms: 'sms.totalSms',
    topRecipient: 'sms.topRecipient',
    estimatedCostOfSmsNotifications: 'sms.estimatedCostOfSmsNotifications',
  }
  consentGiven: boolean = false;
  consentKey = 'sms-consent';

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private enumService: EnumService, private translateConfigService: TranslateConfigService, private adminApi: AdminApi, private statisticsApi: StatisticsApi,) {
    this.timeframeTypes = this.enumService.timeframeTypes;
  }

  ngOnInit(): void {
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    if (this.isAdmin) {
      this.adminApi.getCustomers().subscribe(data => this.customers = data)
    }
    this.initForm()
    this.getSmsData()
    this.checkConsent();
  }

  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }

  translateMonth(month: string) {
    if (month && month.length > 0)
      return `${this.translateConfigService.instant(MonthTranslations[month])}`
    else return '';
  }

  checkTimeFrame() {
    return this.form.get('timeframe')?.value === TimeframeEnum.Custom
  }

  clearDatePickerFrom() {
    this.form.get('start').setValue('');
  }

  clearDatePickerTo() {
    this.form.get('end').setValue('');
  }

  getCurrentWeek() {
    const {start, end} = this.getStartWeekAndEndWeekDate();

    const startOfCurrentWeek = start.toISODate();
    const endOfCurrentWeek = end.toISODate();
    return [startOfCurrentWeek, endOfCurrentWeek]
  }

  getPreviousWeek() {
    const {start, end} = this.getStartWeekAndEndWeekDate();

    const startOfPreviousWeek = start.minus({weeks: 1}).toISODate();
    const endOfPreviousWeek = end.minus({weeks: 1}).toISODate();
    return [startOfPreviousWeek, endOfPreviousWeek]
  }

  getCurrentMonth() {
    const {start, end} = this.getStartMonthAndEndMonthDate();
    const startOfCurrentMonth = start.toISODate();
    const endOfCurrentMonth = end.toISODate();
    return [startOfCurrentMonth, endOfCurrentMonth]
  }

  getPreviousMonth() {
    const {start, end} = this.getStartMonthAndEndMonthDate();
    const startOfPreviousMonth = start.minus({month: 1}).toISODate();
    const endOfPreviousMonth = end.minus({month: 1}).endOf('month').toISODate();
    return [startOfPreviousMonth, endOfPreviousMonth]
  }

  private getStartWeekAndEndWeekDate() {
    const today = DateTime.local();
    const start = today.startOf('week');
    const end = today.endOf('week');
    return {start, end};
  }

  private getStartMonthAndEndMonthDate() {
    const today = DateTime.local();
    const start = today.startOf('month');
    const end = today.endOf('month');
    return {start, end}
  }

  private getSmsData() {
    let isCustomer = this.form.get('customer').value;
    if (!isCustomer) return;

    let params = {
      ...this.form.value, customerId: this.form.get('customer').value
    }

    if (this.form.get('timeframe').value === TimeframeEnum.Custom) {
      let invalidDate = this.form.get('start').value == '' || this.form.get('end').value == '' || this.form.get('start').value == null || this.form.get('end').value == null
      if (invalidDate) return;

      params = {
        ...params,
        startDate: new Date(this.form.get('start').value).toDateString(),
        endDate: new Date(this.form.get('end').value).toDateString()
      }
    }

    if (this.form.get('timeframe').value !== TimeframeEnum.Custom) {
      let selectedTimeFrame;
      if (this.form.get('timeframe').value === TimeframeEnum.CurrentWeek) selectedTimeFrame = "getCurrentWeek";
      if (this.form.get('timeframe').value === TimeframeEnum.PreviousWeek) selectedTimeFrame = "getPreviousWeek";
      if (this.form.get('timeframe').value === TimeframeEnum.CurrentMonth) selectedTimeFrame = "getCurrentMonth";
      if (this.form.get('timeframe').value === TimeframeEnum.PreviousMonth) selectedTimeFrame = "getPreviousMonth";

      const [startDate, endDate] = this[selectedTimeFrame]();
      params = {
        ...params, startDate, endDate
      }
    }

    this.statisticsApi.getSmsStatistics(params).subscribe((data: SmsStatisticsResponse) => {
      this.dataSource = new MatTableDataSource(data.smsStatistics);
      this.chartData = data
      this.topRecipient = {noSmsForTopRecipinet: data.noSmsForTopRecipinet, topRecipient: data.topRecipient }
      this.dataSource.sort = this.sort;
    });
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      timeframe: [3], start: [''], end: [''], customer: [this.customerId],

    });
    this.form.get('end').valueChanges.subscribe(() => {
      this.getSmsData()
    });
    this.form.get('customer').valueChanges.subscribe(() => this.getSmsData())


    this.form.get('timeframe').valueChanges.subscribe(() => {
      if (this.form.get('timeframe').value === TimeframeEnum.Custom) {
        this.form.get('start').setValidators(Validators.required);
        this.form.get('end').setValidators(Validators.required);
        this.startDate = '';
        this.endDate = '';
      }
      if (this.form.get('timeframe').value !== TimeframeEnum.Custom) {
        this.form.get('start').removeValidators([Validators.required]);
        this.form.get('end').removeValidators([Validators.required]);
        this.form.get('start').setValue(null);
        this.form.get('end').setValue(null);
        let selectedTimeFrame;
        if (this.form.get('timeframe').value === TimeframeEnum.CurrentWeek) selectedTimeFrame = "getCurrentWeek";
        if (this.form.get('timeframe').value === TimeframeEnum.PreviousWeek) selectedTimeFrame = "getPreviousWeek";
        if (this.form.get('timeframe').value === TimeframeEnum.CurrentMonth) selectedTimeFrame = "getCurrentMonth";
        if (this.form.get('timeframe').value === TimeframeEnum.PreviousMonth) selectedTimeFrame = "getPreviousMonth";


        const [startDate, endDate] = this[selectedTimeFrame]();
        this.startDate = startDate;
        this.endDate = endDate;
      }
    })
  }
}
