<ng-container *ngIf="!continueWithMarketingAuto">
  <div [formGroup]="mediaForm"
       class="w-full h-full flex flex-col gap-8 step-three-wrapper">
    <div
         class="max-w-[707px] h-14 pb-2 border-b border-neutral-100 justify-start items-start gap-[13px] inline-flex truncate">
      <div class="w-fit py-2 justify-center items-center gap-2 flex">
        <div class="text-neutral-800 text-sm font-semibold font-['Inter'] leading-none">
          {{ 'editEngagement.selectedScreens' | translate }}
        </div>
      </div>
      <div class="w-fit justify-start items-start gap-2 flex">
        <div *ngFor="let screen of screens"
             class="w-fit p-2 bg-neutral-50 rounded justify-center items-center gap-2 flex">
          <div class="text-neutral-800 text-sm font-normal font-['Inter'] capitalize leading-none">{{ screen.name }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="engagementType == EngagementTypeEnum.Segment"
         class="w-full justify-start items-end gap-4 inline-flex">
      <div *ngIf="!mediaForm.get('defaultMediaDetails')?.value"
           class="step-three-default-media-dimensions">
        <mat-label>{{ 'editSegment.defaultMedia' | translate }}</mat-label>
        <mat-form-field appearance="outline"
                        class="w-full">
          <mat-select formControlName="defaultMediaDetails" placeholder="{{'editSegment.form.media' | translate}}">
            <mat-option *ngFor="let media of selectedMedias"
                        [value]="media"
                        class="hidden">
              {{ media?.name }}
            </mat-option>
            <mat-option *flowHasPermission="['UpdateMediaCms']; disabled true;"
                        (click)="openCreateMedia( 'defaultMediaDetails')"
                        [value]="null"
                        class="option-create">{{ 'editSegment.form.createNewMedia' | translate }}
            </mat-option>
            <mat-option (click)="openSelectMedia('defaultMediaDetails')"
                        [value]="null"
                        class="option-create">{{ 'editSegment.form.chooseMedia' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div *ngIf="mediaForm.get('defaultMediaDetails')?.value"
           class="flex flex-col self-start gap-[5px]" [matTooltip]="mediaForm.get('defaultMediaDetails')?.value?.name">
        <mat-label>{{ 'editSegment.defaultMedia' | translate }}</mat-label>
        <div *ngIf="mediaForm.get('defaultMediaDetails')?.value"
             class="preview">
          <div (click)="openViewMedia(mediaForm.get('defaultMediaDetails').value, $event)"
               class="list-thumbnail-container">
            <div class="relative">
              <div class="image-name">{{ mediaForm.get('defaultMediaDetails')?.value?.name }}</div>
            </div>
            <img *ngIf="mediaForm.get('defaultMediaDetails').value?.type === MediaTypeEnum.Image"
                 [src]="mediaForm.get('defaultMediaDetails').value?.url"
                 class="list-thumbnail-container">

            <div *ngIf="mediaForm.get('defaultMediaDetails').value?.type === MediaTypeEnum.Video"
                 class="thumbnail-placeholder">
              <mat-icon>play_circle</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="mediaForm.get('defaultMediaDetails').value" class="flex flex-col w-[140px]">
        <mat-label>{{ 'editEngagement.presentationTimeLabel' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <input
                type="number"
                min="1"
                max="999"
                formControlName="defaultMediaPresentationTime"
                matInput
                placeholder="{{'editEngagement.presentationTimeLabel' | translate }}"
                (keypress)="restrictNonNumericalInput($event)"
                (input)="removeLeadingZero($event)"
                [ngClass]="{'is-invalid': mediaForm.get('defaultMediaPresentationTime')?.invalid && mediaForm.get('defaultMediaPresentationTime').touched}"
                >
        </mat-form-field>
        <mat-error
          *ngIf="mediaForm.get('defaultMediaPresentationTime').hasError('max') && mediaForm.get('defaultMediaPresentationTime').touched">
          {{ 'createScreen.validationErrors.presentationTime' | translate }}
        </mat-error>
      </div>
      <div (click)="resetDefaultMedia()"
           *ngIf="mediaForm.get('defaultMediaDetails').value"
           class="p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex">
        <app-icon [ngStyle]="{cursor:'pointer'}"
                  iconName="x"
                  size="md"
                  stroke="#202020">
        </app-icon>
      </div>
    </div>
    <div class="overflow-auto"
         formArrayName="advertisements"
         cdkDropList
         (cdkDropListDropped)="drop($event)">
      <ng-container *ngFor="let ad of advertisements.controls; let index = index"
                    [formGroupName]="index">
        <div *ngIf="!ad.get('isRemoved').value"
             cdkDrag
             class="w-full pb-10 flex-col justify-start items-start gap-6 inline-flex overflow-auto">
          <div class="w-full justify-start items-end gap-2 inline-flex">
            <div class="justify-start items-end gap-4 flex">
              <div class="menu-drag"
                   *ngIf="engagementType===EngagementTypeEnum.Playlist">
                <a cdkDragHandle
                   mat-flat-button
                   [disableRipple]="true"
                   class="drag-button">
                  <mat-icon inline="true">menu</mat-icon>
                </a>
              </div>
              <div *ngIf="!ad.get('mediaDetails')?.value"
                   class="step-three-default-media-dimensions">
                <mat-label>{{ 'editSegment.form.media' | translate }} *</mat-label>
                <mat-form-field appearance="outline"
                                class="w-full">
                  <mat-select formControlName="mediaDetails"
                              placeholder="{{'editSegment.form.media' | translate}}">
                    <mat-option *ngFor="let media of selectedMedias"
                                [value]="media"
                                class="hidden">
                      {{ media?.name }}
                    </mat-option>
                    <mat-option *flowHasPermission="['UpdateMediaCms']; disabled true;"
                                (click)="openCreateMedia('mediaDetails', index)"
                                [value]="null"
                                class="option-create">{{ 'editSegment.form.createNewMedia' | translate }}
                    </mat-option>
                    <mat-option (click)="openSelectMedia('mediaDetails', index)"
                                [value]="null"
                                class="option-create">{{ 'editSegment.form.chooseMedia' | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div *ngIf="ad.get('mediaDetails')?.value" class="flex flex-col w-[3.75rem]" [matTooltip]="ad.get('mediaDetails')?.value?.name">
                <mat-label class="mb-[0.25rem]">{{ 'editSegment.form.media' | translate }} *</mat-label>
                <div *ngIf="ad.get('mediaDetails')?.value"
                     class="preview">

                  <div [matMenuTriggerFor]="menu"
                       class="list-thumbnail-container">
                    <div class="relative">
                      <div class="image-name">{{ ad.get('mediaDetails')?.value?.name }}</div>
                    </div>
                    <img *ngIf="ad.get('mediaDetails')?.value?.type === MediaTypeEnum.Image"
                         [src]="ad.get('mediaDetails')?.value?.url"
                         class="list-thumbnail-container">
                    <div *ngIf="ad.get('mediaDetails')?.value?.type === MediaTypeEnum.Video"
                         class="thumbnail-placeholder">
                      <mat-icon>play_circle</mat-icon>
                    </div>
                  </div>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="openViewMedia(ad.get('mediaDetails')?.value, $event)">
                      <span>{{ 'media.table.preview' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="openSelectMedia('mediaDetails', index)">
                      <span>{{ 'editSegment.form.chooseMedia' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="openCreateMedia('mediaDetails', index)">
                      <span>{{ 'editSegment.form.createNewMedia' | translate }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <div *ngIf="engagementType == EngagementTypeEnum.Segment"
                   class="step-three-target-groups-dimensions">
                <mat-label>{{ 'editSegment.form.targetGroups' | translate }} *</mat-label>
                <mat-form-field appearance="outline"
                                class="w-full">
                  <mat-select formControlName="targetGroups"
                              multiple
                              placeholder="{{'editSegment.form.targetGroups' | translate}}">
                    <label class="w-full px-[1rem] pt-[0.5rem]"
                           style="display: block; margin: 0; padding: 0;">
                      <mat-checkbox color="primary"
                                    [checked]="ad.value.AllWomenSelected"
                                    (change)="selectTargetGroupsByGender(1, ad)">
                        {{ "global.allWomen" | translate }}
                      </mat-checkbox>
                    </label>
                    <label class="w-full px-[1rem] py-[0.5rem]"
                           style="display: block; margin: 0; padding: 0;">
                      <mat-checkbox color="primary"
                                    [checked]="ad.value.allMenSelected"
                                    (change)="selectTargetGroupsByGender(2, ad)">
                        {{ "global.allMen" | translate }}
                      </mat-checkbox>
                    </label>
                    <mat-divider></mat-divider>
                    <mat-option *ngFor="let targetGroup of targetGroups"
                                [value]="targetGroup.id">
                      {{ targetGroup.name | translate }}
                    </mat-option>
                  </mat-select>

                </mat-form-field>
              </div>
              <div>
                <button (click)="onTriggerClick(ad, $event)"
                        class="btn-trigger"
                        [ngClass]="ad.get('numOfTriggers')?.value && ad.get('numOfTriggers')?.value > 0 ? 'border-[#1F5792]' : 'border-[#DADADA]'"
                        mat-stroked-button
                        tabIndex=-1>
                  <span *ngIf="ad.get('numOfTriggers')?.value && ad.get('numOfTriggers')?.value > 0"
                        class="trigger-notification w-6 h-6 text-xs">{{ ad.get('numOfTriggers')?.value ?
                    ad.get('numOfTriggers')?.value : '' }}</span>
                  <app-icon [ngStyle]="{cursor:'pointer'}"
                            stroke="#202020"
                            size="md"
                            iconName="triggers"></app-icon>
                  <span>{{ 'editEngagement.triggerButton' | translate }}</span>
                </button>
              </div>
              <div class="flex flex-col w-[160px] relative">
                <mat-label>{{ 'editEngagement.presentationTimeLabel' | translate }}</mat-label>
                <mat-form-field appearance="outline">
                  <input
                        type="number"
                        min="1"
                        max="999"
                        formControlName="presentationTime"
                        matInput
                        placeholder="{{'editEngagement.presentationTimePlaceholder' | translate }}"
                        (keypress)="restrictNonNumericalInput($event)"
                        (input)="removeLeadingZero($event)"
                        [ngClass]="{'is-invalid': ad.get('presentationTime')?.value?.invalid && ad.get('presentationTime').touched}"
                        >
                </mat-form-field>
                <mat-error class="absolute bottom-[-40px]"
                        *ngIf="ad.get('presentationTime').hasError('max') && ad.get('presentationTime').touched">
                        {{ 'createScreen.validationErrors.presentationTime' | translate }}
                </mat-error>
              </div>
            </div>
            <div (click)="onRemoveAdvertisementRow(index, ad)"
                 class="p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex">
              <app-icon [ngStyle]="{cursor:'pointer'}"
                        iconName="x"
                        size="md"
                        stroke="#202020">
              </app-icon>
            </div>
            <div (click)="onAddAdvertisementRow(index)"
                 class="p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex">
              <app-icon [ngStyle]="{cursor:'pointer'}"
                        iconName="plus"
                        size="md"
                        stroke="#202020">
              </app-icon>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="continueWithMarketingAuto && engagementType == EngagementTypeEnum.Segment">
  <div class="w-[613px] h-[78px] flex-col justify-start items-start gap-6 inline-flex">
    <div class="justify-center items-center inline-flex">
      <div class="text-neutral-800 text-base font-semibold font-['Inter'] leading-[18px]">
        Do you want to activate Marketing Automation for this segment?
      </div>
    </div>
    <div class="justify-center items-center inline-flex">
      <div class="text-neutral-800 text-sm font-normal font-['Inter'] leading-[18px]">
        You can add Marketing Automation for this segment.
      </div>
    </div>
  </div>
</ng-container>
