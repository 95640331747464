import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { SelectedFile } from '@app/models/media-files';
import { environment } from '@environments/environment';

@Component({
  selector: 'flow-drag-to-upload',
  templateUrl: './drag-to-upload.component.html',
  styleUrls: ['./drag-to-upload.component.scss']
})
export class DragToUploadComponent {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Output() selectedFile = new EventEmitter<any>();

  selectedBody: SelectedFile;
  isDragging = false;

  constructor(
    private systemMessageService: SystemMessageService,
    private translateConfigService: TranslateConfigService
  ) { }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  selectFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    const selectedFile = input.files && input.files[0];

    if (!selectedFile) {
      console.warn('No file selected');
      return;
    }

    this.readyFile(selectedFile)
  }

  async readyFile(selectedFile) {
    const file = {
      index: null,
      selectedFile,
      fileSize: selectedFile.size,
      filePreview: null,
      format: this.getFileFormat(selectedFile),
    };

    if (!this.isValidFileType(file?.selectedFile?.type)) {
      this.systemMessageService.error(this.translateConfigService.instant("createMedia.error.supports"))
      return;
    }

    if (file?.format === 'video') {
      try {
        const duration = await this.getVideoDuration(selectedFile)
        file.selectedFile.videoDuration = duration.toFixed(2);
      } catch (error) {
        file.selectedFile.videoDuration = 0;
      }
    }

    const reader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      file.filePreview = e.target?.result as string;

      this.selectedBody = file;
      this.selectedFile.emit(file);
    };

    reader.onerror = () => {
      console.error('File reading failed');
    };

    reader.readAsDataURL(selectedFile);
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
    const droppedFiles = event.dataTransfer?.files[0];

    if (droppedFiles) {
      this.readyFile(droppedFiles);
    }
  }

  isValidFileType(fileType: string): boolean {
    const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    return allowedTypes.includes(fileType);
  }

  getFileFormat(file: File): 'image' | 'video' {
    if (file.type.startsWith('image/')) {
      return 'image';
    }

    return 'video';
  }

  getVideoDuration(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = window.URL.createObjectURL(file);
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };
      video.onerror = (error) => {
        reject(error);
      };
    });
  }
}
