import { DateTime } from "luxon";
import { Engagement } from "@api/models/engagement";
import { AuthService, UserRoleEnum } from "@app/core/auth/auth.service";
import { IdName } from "@api/models/idname";
import { OrientationEnum } from "@api/models/orientation-enum";
import { TranslateConfigService } from "@app/core/translate-config.service";
import {ScreenGroup} from "@api/models/screen-group";

export const ITEM_ROW_HEIGHT = 35;

export interface CalendarBounds {
  from: DateTime;
  to: DateTime;
}

export interface CalendarPeriod {
  startmonth: DateTime;
  endmonth: DateTime;
  months?: DateTime[];
}

export interface CalendarWeekday {
  name: string;
}

export interface Month {
  name: string;
}
export class CalendarWeek {
  number: number;
  monthLabel: string;
  days: CalendarDay[];
  items?: CalendarItem[];
  rowCount?: number;

  constructor(number: number, monthLabel: string, days: CalendarDay[], slots: CalendarSlot[], authService: AuthService, translateConfigService: TranslateConfigService) {
    this.number = number;
    this.monthLabel = monthLabel;
    this.days = days;
    if (slots?.length) {
      this.items = slots?.map(slot => {
        return new CalendarItem(slot.start, slot.end, this, slot, authService, translateConfigService);
      }).sort((a, b) => a.leftPercentage - b.leftPercentage || a.startUnix - b.startUnix);
      this.calculateItemRows(this.items);
      // this.segments = segments.map(o => new CalendarItem(o, this))
      // 	.sort((a, b) => a.leftPercentage - b.leftPercentage || a.startUnix - b.startUnix);
      // this.calculateItemRows(this.occurrences);
    }
  }

  static buildWeek(startOfWeek: DateTime, slots: CalendarSlot[], authService: AuthService, translateConfigService: TranslateConfigService): CalendarWeek {
    const days: CalendarDay[] = [];
    let dt = startOfWeek;
    for (let i = 0; i < 7; i++) {
      const isoString = dt.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true });
      const day = new CalendarDay(
        dt.toISODate(),
        dt.day,
        dt,
        isoString,
        dt.month % 2 > 0
      );
      days.push(day);
      dt = dt.plus({ day: 1 });
    }
    const endOfWeek = startOfWeek.endOf('week');
    const startOfWeekMonth = this.capitalize(startOfWeek.toFormat('MMMM yyyy'));
    const startMonthNum = startOfWeek.month + " " + startOfWeek.year
    const endOfWeekMonth = this.capitalize(endOfWeek.toFormat('MMMM yyyy'));
    const endMonthNum = endOfWeek.month + " " + endOfWeek.year
    const monthLabel = startOfWeekMonth === endOfWeekMonth ? startMonthNum + "" : startMonthNum + ' - ' + endMonthNum;
    const weekSlots: CalendarSlot[] = slots?.filter(slot =>
      this.isTimePeriodMatching(slot.start, slot.end, startOfWeek, endOfWeek));
    return new CalendarWeek(startOfWeek.weekNumber, monthLabel, days, weekSlots, authService, translateConfigService);
  }

  private static isTimePeriodMatching(slotStart: DateTime, slotEnd: DateTime, start: DateTime, end: DateTime): boolean {
    return (slotStart >= start && slotStart <= end) ||
      (slotEnd >= start && slotEnd <= end) ||
      (start >= slotStart && start <= slotEnd) ||
      (end >= slotStart && end <= slotEnd);
  }

  private static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  calculateItemRows(items?: CalendarItem[]): void {
    const rows: CalendarItem[][] = [];
    items.forEach(o => {
      this.insertInRow(o, 0, rows);
    });
    rows.forEach((row, index) => {
      row.forEach(res => {
        res.topPx = index * ITEM_ROW_HEIGHT;
      });
    });
    this.rowCount = rows.length;
  }

  private insertInRow(item: CalendarItem, rowIndex: number, rows: CalendarItem[][]): void {
    if (!rows[rowIndex]) {
      rows.push([item]);
    } else if (this.isColliding(item, rows[rowIndex])) {
      rowIndex++;
      this.insertInRow(item, rowIndex, rows);
    } else {
      rows[rowIndex].push(item);
    }
  }

  private isColliding(item: CalendarItem, rowItems: CalendarItem[]): boolean {
    const minRight = Math.min(...rowItems.map(r => r.rightPercentage));
    return item.leftPercentage < (100 - minRight); // right är procent från höger, 100 - right för att få procent från left
  }
}

export class CalendarDay {

  constructor(
    readonly id: string,
    readonly number: number,
    readonly dateTime: DateTime,
    readonly utcString: string,
    readonly isOddMonth: boolean
  ) { }

}

export class CalendarItem {
  startUnix: number;
  startDiffInMinutes: number;
  endDiffInMinutes: number;
  topPx: number;
  leftPercentage: number;
  rightPercentage: number;
  slot: CalendarSlot;
  isAdmin: boolean;
  translateConfigService: TranslateConfigService;

  constructor(start: DateTime, end: DateTime, week: CalendarWeek, slot: CalendarSlot, authService: AuthService, translateConfigService: TranslateConfigService) {
    this.slot = slot;
    this.topPx = 0;
    this.translateConfigService = translateConfigService;
    this.startUnix = start.toSeconds();
    if (authService) {
      const user = authService?.userData;
      this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    }
    let points = 7 * 24 * 60;
    //lägg till eller ta bort 1 timme (60 min) på veckan pga sommar/vintertid
    const startTimezoneOffset = week.days[0].dateTime.offset;
    const endTimezoneOffset = week.days[week.days.length - 1].dateTime.endOf('day').offset;
    if (startTimezoneOffset > endTimezoneOffset) {
      points += 60;
    } else if (startTimezoneOffset < endTimezoneOffset) {
      points -= 60;
    }
    this.startDiffInMinutes = Math.round(week.days[0].dateTime.diff(start, 'minutes').minutes) * -1;
    const startDiffInPercentage = this.startDiffInMinutes / points * 100;
    this.leftPercentage = startDiffInPercentage > 0 ? startDiffInPercentage : 0;
    this.endDiffInMinutes = Math.round(week.days[week.days.length - 1].dateTime.endOf('day').diff(end, 'minutes').minutes);
    const endDiffInPercentage = this.endDiffInMinutes / points * 100;
    this.rightPercentage = endDiffInPercentage > 0 ? endDiffInPercentage : 0;
  }

  toString(): string {
    if (this.slot.bookedSlotsCount != null) {
      return `(${this.slot?.bookedSlotsCount}/${this.slot?.totalSlotsCount}) SCREEN: ${this.slot?.screenName}, CHANNEL: ${this.slot?.channel?.name}`;
    } else if (this.slot?.slot?.engagementId && (this.slot?.singleScreen || this.slot.screen?.id )) {
      return `${this.slot?.slot.engagementName} (SCREEN: ${this.slot?.slot.screenName}, CHANNEL: ${this.slot?.channel?.name}${this.isAdmin ? ', ' : ''}${this.isAdmin ? `CUSTOMER: ${this.slot?.slot?.customer?.name}` : ''})`;
    }else if (this.slot?.slot?.engagementId && this.slot?.screenGroup) {
      const channels = this.slot.screenGroup?.channels.map(x => x.name).join(';').toString()
        return `${this.slot?.slot.engagementName} (GROUP: ${this.slot.screenGroup.screenGroup.name}, ALLCH: ${channels}${this.isAdmin ? ', ': ''}${this.isAdmin ? `CUSTOMER: ${this.slot?.slot?.customer?.name}` : ''})`;
    } else if (this?.slot?.slot?.screenId  || this.slot.singleScreen) {
      return `SCREEN: ${this.slot?.screen?.name}, CHANNEL: ${this.slot?.channel?.name}, ORIENTATION: ${this.translateConfigService.instant(`${OrientationEnum[this.slot?.slot?.orientation || this.slot?.singleScreen?.orientation]}`)}`;
    }else if(this.slot?.screenGroup && !this.slot?.engagement){
      const channels = this.slot.screenGroup?.channels?.map(x => x.name).join(';').toString()
      return  `GROUP: ${this.slot.screenGroup.screenGroup.name}, ALLCH: ${channels}, ORIENTATION: ${this.translateConfigService.instant(`${OrientationEnum[this.slot.screenGroup.orientation]}`)}`
    } else if(this.slot?.screenGroup && this.slot?.engagement){
       const channels = this.slot.screenGroup?.channels.map(x => x.name).join(';').toString()
      return  `GROUP: ${this.slot.screenGroup.screenGroup.name}, ALLCH: ${channels}, ${this.isAdmin ? `CUSTOMER: ${this.slot?.engagement?.customer?.name}` : ''})`
    }
    else {
      return '';
    }
  }
}

export interface CalendarSlot {
  id?: number;
  start: DateTime;
  screenId?: number
  end: DateTime;
  screen?: IdName;
  screenName?: string
  channel?: IdName | any;
  engagement: Engagement;
  engagementType?: number;
  engagementSlotId?:number;
  engagementId?:number;
  slot:any,
  identifier?: string;
  customer: any;
  selected?: boolean;
  bookedSlotsCount?: number;
  totalSlotsCount?: number;
  slotId?: number;
  engagementName?: string
  booked?: boolean;
  isSameEngagementId?: boolean;
  orientation?: number;
  screenGroup?: ScreenGroup | any;
  singleScreen?: any;
  isEdit?: boolean
  mediaCount?: number

}

