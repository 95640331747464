import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SystemMessageService } from 'src/app/core/services/system-message.service';
import { TranslateConfigService } from 'src/app/core/translate-config.service';

@Component({
  selector: 'flow-book-segment-info-modal',
  templateUrl: './book-segment-info-modal.component.html',
  styleUrls: ['./book-segment-info-modal.component.scss']
})
export class BookSegmentInfoModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BookSegmentInfoModalComponent>,
    public dialog: MatDialog,
    private systemMessageService: SystemMessageService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public translateService: TranslateConfigService
  ) { }

  ngOnInit(): void {
  }
  onOk() {
    this.data.onOk()
  }
}

interface ModalData {
  onOk: Function
}
