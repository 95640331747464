<div class="flex flex-col items-start w-[220px] rounded border border-neutral-100 ">
  <div *ngIf="selectedBookings.length > 0" class="flex flex-1 w-full p-2 bg-gray-200 items-center">
    
    <div class="flex w-6 h-6 mr-1 cursor-pointer" (click)="toggleBookings()">
      <app-icon 
          *ngIf="selectedBookings.length > 0 && showBookings; else showCaretUp"
          fill="rgba(0,0,0,0)"
          iconName="caret-down"
          size="md">
      </app-icon>
      <ng-template #showCaretUp>
          <app-icon 
            fill="rgba(0,0,0,0)"
            iconName="caret-up"
            size="md">
        </app-icon>
        </ng-template>
      </div>
 
    <div class="flex py-2 bg-gray-200 rounded items-center">
      <div class="text-neutral-800 text-sm font-normal font-['Inter'] capitalize leading-tight">{{ 'book.bookingSummary' | translate }}</div>
    </div>
  </div>

  <div *ngIf="showBookings && selectedBookings.length > 0" class="grid grid-cols-1 gap-0 divide-y w-full max-h-[32vh] overflow-y-auto">
    <ng-container *ngFor="let booking of selectedBookings; let i = index; let last = last">
      <div class="p-3" *ngIf="booking?.engagementSlots && booking?.engagementSlots.length > 0 ">
          <p class="flex flex-1 p-0 m-0 text-neutral-800 text-sm font-semibold font-['Inter'] capitalize leading-[18px]">
            {{booking?.screenGroup ? booking?.screenGroup?.name : booking?.screens[0].name}}
          </p>      
          <flow-book-summary-item [engagementSlots]="booking.engagementSlots" (navigate)="navigateToWeek($event)"></flow-book-summary-item>
      </div>
    </ng-container>
  </div>
</div>
