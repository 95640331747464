<mat-dialog-title class="flex flex-row justify-between border-b border-gray-200 p-0">
   <span class="title">{{'media.createMedia' | translate}}</span>
   <app-icon stroke="#202020"
              [ngStyle]="{'cursor': 'pointer'}"
              (click)="onCancel()"
              size="md"
              iconName="x"></app-icon>
</mat-dialog-title>
<mat-dialog-content
     class="py-2">
   <div class="h-full flex-col justify-start items-start gap-10 inline-flex">
      <form [formGroup]="form">
         <div class="w-[324px] flex-col justify-center items-start gap-4 flex">
            <div class="justify-start items-start gap-2 inline-flex">
                <mat-checkbox formControlName="isClientBoxChecked" color="primary"></mat-checkbox>
                <div class="text-gray-900 text-[0.75rem] font-normal ">{{'createMedia.moveClientMediaToFolder' | translate}}</div>
            </div>
            <div class="input-dimensions">
               <mat-label>{{'media.form.name' | translate}} *</mat-label>
               <mat-form-field class="w-full">
                 <input matInput
                        type="text"
                        formControlName="name">
               </mat-form-field>
            </div>
            <div class="input-dimensions">
               <mat-label>{{ 'media.form.client' | translate }}</mat-label>
               <mat-form-field class="w-full">
                  <mat-select formControlName="clientId"
                              placeholder="{{'media.form.clientPlaceholder' | translate}}">
                     <mat-option *ngFor="let client of clients"
                                 [value]="client.id">
                        {{ client.name | translate }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
            <div class="input-dimensions">
               <mat-label>{{ 'media.form.tags' | translate }}</mat-label>
               <mat-form-field class="w-full">
                  <flow-multi-select-autocomplete formControlName="tags"
                                                  [options]="tags"
                                                  [displayFn]="displayTag"
                                                  [newOptionFn]="newTag"></flow-multi-select-autocomplete>
               </mat-form-field>
            </div>
       
            <flow-drag-to-upload *ngIf="form.get('medias')?.value?.length == 0" (selectedFile)="selectFile($event)"  class="input-dimensions"></flow-drag-to-upload>

            <div class="w-full h-full flex flex-col gap-4"
                 *ngIf="form.get('medias')?.value?.length > 0">
               <div *ngFor="let media of form.get('medias').value; let i = index"
                    class="w-[365px] h-[49px] justify-between items-center inline-flex pb-1 border-b border-[#f5f5f5]">
                  <div class="justify-center items-center gap-2 flex">
                     <img class="w-[60px] h-[49px] rounded"
                          [src]="media.filePreview" />
                     <div class="flex-col justify-center items-start gap-1 inline-flex">
                        <div class="text-[#202020] text-sm font-normal  leading-[14px]">{{media.selectedFile.name}}
                        </div>
                        <div class="w-[102px] text-[#595959] text-xs font-normal leading-none">{{media.fileSize |
                           filesize}}</div>
                     </div>
                  </div>
                  <div (click)="clearFile(media.id)"
                       class="p-2 bg-[#fafafa] rounded justify-start items-start gap-2 flex">
                     <app-icon [ngStyle]="{cursor:'pointer'}"
                               iconName="x"
                               size="md"
                               stroke="#202020">
                     </app-icon>
                  </div>
               </div>
            </div>
         </div>
      </form>
   </div>
</mat-dialog-content>
<mat-dialog-actions>
   <div class="h-10 justify-end items-start gap-4 inline-flex">
      <div (click)="onCancel()" class="p-4 bg-white rounded border border-[#bbbbbb] justify-start items-center gap-2 flex cursor-pointer">
         <div class="text-[#595959] text-sm font-medium font-['Inter'] leading-[14px]">{{'global.cancel' | translate}}</div>
      </div>
      <div (click)="onSaved()"
            [ngClass]="{'disabled': !form.valid }"
            class="p-4 bg-[#1f5792] rounded justify-start items-center gap-2 flex cursor-pointer">
         <div class="text-white text-sm font-medium font-['Inter'] leading-[14px]">{{'global.add' | translate}}</div>
      </div>
   </div>
</mat-dialog-actions>