import { Component, Input, OnInit } from '@angular/core';
import {DateTime} from "luxon";
import {EnumService} from "../../../../core/services/enum.service";
import {TranslateConfigService} from "../../../../core/translate-config.service";
import {CalendarWeekday, Month} from "../../calendar/calendar-models";
import {FormControl, FormGroup} from '@angular/forms';
import {BookService} from "../services/book.service";
import { Bookings, EngagementTypeEnum } from 'src/_api';
import {BookingApiService} from 'src/_api/services/booking-api.service';
import {unsubscribeMixin} from "../../../../core/unsubscribe";
import {takeUntil} from "rxjs";

export interface WeekDay {
  dayName: string;
  dateNumber: number;
}

@Component({
  selector: 'flow-book-step-two',
  templateUrl: './book-step-two.component.html',
  styleUrls: ['./book-step-two.component.scss']
})
export class BookStepTwoComponent extends unsubscribeMixin() implements OnInit {
  @Input() bookForm: FormGroup;
  @Input() engagementId: number;
  @Input() continueWithMedia: boolean;
  @Input() startOfWeek!: DateTime;

  selected: DateTime | null;
  week: WeekDay[];
  weekNumber = 22
  todayNumber: number;
  bookings: Bookings[] = [];
  calendarWeekdays: CalendarWeekday[];
  months: Month[];
  EngagementTypeEnum = EngagementTypeEnum;
  @Input() isCopy!: boolean;

  constructor(
    private bookService: BookService,
    public enumService: EnumService,
    public translateConfigService: TranslateConfigService,
    private bookingApiService: BookingApiService
  ) {
    super();
    this.calendarWeekdays = enumService.weekDays.map((w) => ({name: w.name}))
    this.months = enumService.months.map((m) => ({name: m.name}))
  }

  ngOnInit(){
    this.week = this.getWeek(this.startOfWeek);
    this.bookings = this.bookService.bookings;

  }

  getWeek(date?: DateTime): WeekDay[] {
    const selectedDate = date || DateTime.now()
    this.todayNumber = DateTime.now().day
    this.startOfWeek = selectedDate.startOf('week');
    const week: WeekDay[] = [];

    for (let i = 0; i < 7; i++) {
      const day = this.startOfWeek.plus({ days: i });
      week.push({
        dayName: this.enumService.weekDays[i].name,
        dateNumber: day.day
      });
    }
    return week;
  }

  onDateChanged(date:DateTime) {
    this.week = this.getWeek(date);
    this.selected = this.startOfWeek;
    const startOfWeek = date.startOf('week');
    this.bookService.updateWeekDays(startOfWeek);
    this.bookings = [];
    const engagementId = this.isCopy ? null : this.engagementId;
    this.bookingApiService.getBookings(this.bookService.getBookingsParams( this.bookForm.get('screens').value, startOfWeek, engagementId))
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      data => {
        this.bookings = this.bookService.setBookings(data.bookings, startOfWeek);
      }
    );
  }

  get engagementType(): number {
    return this.bookForm.get('engagementType').value as number;
  }
}
