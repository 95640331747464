
<div mat-dialog-content
     [mat-dialog-close]="true"
     class="py-2">
<h1 mat-dialog-title>  <a class="mat-dialog-close-button"
     mat-icon-button
     [mat-dialog-close]="true"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a></h1>
  <div>
    <div>{{'bookSegmentInfoModal.text' | translate }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button
          type="button"
          color="primary"
          (click)="onOk()">{{'bookSegmentInfoModal.ok' | translate}}</button>
</div>