import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'compareDates'
})
export class CompareDatesPipe implements PipeTransform {
  transform(date: Date | string, dateArray: (Date | string)[]): boolean {
    if (!date || !dateArray || !Array.isArray(dateArray)) return false;
    const targetDate = new Date(date).getTime();
    return dateArray.some(d => new Date(d).getTime() === targetDate);
  }
}
