import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { ConfirmationModalComponent } from 'src/app/shared/confirmation-modal/confirmation-modal.component';
import { CustomerTypeTranslations } from './customer-type-translations';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { MatPaginator } from '@angular/material/paginator';
import { PaginationResult } from '@api/models/paginator';
import { Customer, CustomerApi } from '@api/index';
import { GenericDialogComponent } from '@app/shared/generic-dialog/generic-dialog.component';

@Component({
  selector: 'flow-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['id', 'name', 'organizationId', 'type', 'person', 'presentationTime', 'numbersOfWeekForFiltering', 'storageLimitSize', 'poiLimit', 'contactPersonLimit', 'actions'];
  dataSource = new MatTableDataSource<Customer>();
  CustomerTypeTranslations = CustomerTypeTranslations;
  hideBox = false;
  params: any = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc'
  }
  totalCount: number = 0;
  pageSizeOptions = [5, 10, 25, 100];

  constructor(
    private customerApi: CustomerApi,
    public dialog: MatDialog,
    private router: Router,
    public platform: Platform
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.getCustomers();
   }

  getCustomers(): void {
    this.customerApi.getCustomersList(this.params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: PaginationResult<Customer>) => {
        this.dataSource = new MatTableDataSource(response?.items);
        this.params = { ...this.params, pageNumber: response?.currentPage };
        this.totalCount = response?.totalCount;
      });
  }

  onDeleteClick(event: Event, customer: Customer): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => this.onRemoveCustomer(customer.id),
        logic: true

      }
    })
  }


  private onRemoveCustomer(customerId: number) {
    this.customerApi.removeCustomer(customerId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialog.closeAll();
        this.getCustomers();
      });
  }

  redirectToEditPage(customerId: number): void {
    this.router.navigate(['/customers', customerId]);
  }

  contactPersonLabel(customer: Customer): string {
    return customer.contactPersons?.map(c => c.name)?.join(', ');
  }

  handleSortEvent(sort: Sort) {
    this.params = { ...this.params, pageNumber: 0, pageSize: 10, sortBy: sort?.active, sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getCustomers();
  }

  handlePageEvent(e) {
    this.params = { ...this.params, pageNumber: e.pageIndex, pageSize: e.pageSize };
    this.getCustomers();
  }

  openCustomerDialog(users: any[]): void {
    const dialogData = {
      title: 'Customers',
      type: 'list',
      items: users.map(user => user.name)
    };

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      width: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

