<div *ngIf="displayMessage"
     class="display-message-container"
     [ngClass]="hasError ? 'bg-brand-red' : 'bg-brand-green'">
  <div class="display-message-title"
       *ngIf="titleMessage">
    <div class="display-message-title-text">{{titleMessage}}</div>
    <app-icon (click)="cleanData()"
              [style]="{cursor: 'pointer'}"
              size="md"
              stroke="#FAFAFA"
              iconName="x"></app-icon>
  </div>
  <div class="display-message-title">
    <div class="display-message-text">{{displayMessage}}</div>
    <app-icon *ngIf="!titleMessage"
              (click)="cleanData()"
              [style]="{cursor: 'pointer'}"
              size="md"
              stroke="#FAFAFA"
              iconName="x"></app-icon>
  </div>
</div>
<div class="header">
  <div class="header-content">
    <div class="header-title-box">
      <div class="header-title-text">
        {{title | translate: {engagementId, name: bookForm ? bookForm.value.name : '' } }}
      </div>
    </div>
  </div>
</div>
<div class="container-book">
  <div #itemsBook
       class="items-book">
    <div>
      <div *ngFor="let step of steps; let i = index"
         (click)="setActiveStep(i, $event)"
         [ngClass]="i | bookStep: activeStep: isEdit : bookForm?.value"
         class="stepper-book">
        <div class="header-stepper-book">
          <div class="header-title-stepper-book">{{'book.step' | translate}} {{i+1}}</div>
        </div>
        <div class="header-stepper-book">
          <div class="header-subtitle-stepper-book">{{step | translate}}</div>
        </div>
      </div>
    </div>
    <div class="buttons-group gap-2">
      <div *ngIf="isEdit && !continueWithMarketingAuto && !continueWithMedia"
            class="action-book-button border-brand-red"
            (click)="deleteBooking()">
        <app-icon iconName="delete"
                  stroke="#fc4b47"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <div class="action-book-button-text text-brand-red">
          {{'global.delete' | translate}}
        </div>
      </div>
      <div (click)="onLaterClick()"
           class="action-book-button">
        <app-icon iconName="x-square"
                  stroke="#202020"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <div class="action-book-button-text">
          {{'global.cancel' | translate}}
        </div>
      </div>
      <div *ngIf="activeStep == 1 && !continueWithMedia && !isEdit"
           (click)="stateEngagement ? copy() : book()"
           class="action-book-button">
        <app-icon [iconName]="stateEngagement ? 'copy-engagement' : 'book-engagement'"
                  stroke="#202020"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <div class="action-book-button-text">{{(stateEngagement ? 'global.copy' : 'global.book') | translate}}
        </div>
      </div>
      <div *ngIf="activeStep > 1 && !isEdit || continueCreateMode"
            class="action-book-button"
            [ngClass]="{'disabled': !allowPublish}"
            (click)="publishBooking()">
        <app-icon iconName="publish"
                  stroke="#202020"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <div class="action-book-button-text">
          {{'global.publish' | translate}}
        </div>
      </div>
      <button *ngIf="isEdit && !continueCreateMode" class="action-book-button border-brand-chathams-blue" (click)="publishBooking()">
        <app-icon iconName="save"
                  stroke="#1f5792"
                  fill="#fff"
                  size="md"
                  [ngStyle]="{'cursor': 'pointer'}"></app-icon>
        <span class="action-book-button-text text-brand-chathams-blue">
          {{'global.saveChanges' | translate}}
        </span>
      </button>
    </div>
  </div>
  <div class="content-book"
       *ngIf="(stateEngagement && isStateReady) || (!isEdit && !stateEngagement) || (isEdit && details)">
    <ng-container [ngSwitch]="activeStep">
      <div *ngSwitchCase="0"
           class="step-container-book">
        <flow-book-step-one [bookForm]="bookForm"
                            [isAdmin]="isAdmin"
                            [customerId]="customerId"
                            [formArraysData]="formArraysData"
                            [engagementId]="engagementId"
                            [stateBookData]="stateBookData"></flow-book-step-one>
        <div class="buttons-group gap-4">
          <div (click)="setActiveStep(1, $event)"
               [ngClass]="{'disabled': !bookForm.get('name').value || bookForm.get('screens').value.length == 0}"
               class="navigation-book-button bg-brand-chathams-blue">
            <div class="navigation-book-button-text">{{'global.next' | translate}}</div>
          </div>
          <!-- <div (click)="onLaterClick()"
               class="skip-book-button">
            <div class="skip-book-button-text">{{'global.cancel' | translate}}</div>
          </div>
          <div *ngIf="isEdit"
               class="navigation-book-button bg-brand-red"
               (click)="deleteBooking()">
            <div class="navigation-book-button-text">{{'global.delete' | translate}}</div>
          </div> -->
        </div>
      </div>
      <div *ngSwitchCase="1"
           class="step-container-book">
        <flow-book-step-two [bookForm]="bookForm"
                            [engagementId]="engagementId"
                            [continueWithMedia]="continueWithMedia"
                            [isCopy] = isCopy
                            [startOfWeek]="startOfWeek"></flow-book-step-two>
        <div class="buttons-group gap-4">
          <div *ngIf="continueWithMedia && !isEdit"
               (click)="onLaterClick()"
               class="skip-book-button">
            <div class="skip-book-button-text">{{'global.later' | translate}}</div>
          </div>
          <div *ngIf="continueWithMedia && !isEdit"
               class="navigation-book-button bg-brand-chathams-blue"
               (click)="onContinueClick()">
            <div class="navigation-book-button-text">{{'global.continue' | translate}}</div>
          </div>
          <div *ngIf="!continueWithMedia"
               (click)="setActiveStep(0, $event)"
               class="back-book-button">
            <div class="back-book-button-text">{{ 'global.back' | translate}}</div>
          </div>
          <div *ngIf="isEdit"
               class="navigation-book-button bg-brand-chathams-blue"
               (click)="setActiveStep(2, $event)">
            <div class="navigation-book-button-text">{{'global.next' | translate}}</div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="2"
           class="step-container-book">
        <flow-book-step-three [screens]="getSelectedScreens()"
                              [mediaForm]="mediaForm"
                              [formArraysData]="formArraysData"
                              [presentationTime]="presentationTime"
                              [continueWithMarketingAuto]="continueWithMarketingAuto"
                              [bookForm]="bookForm"
                              [imageToken]="imageToken"
                              [engagementType]="engagementType"
                              [isEdit]="isEdit"></flow-book-step-three>
        <div class="flex flex-col gap-6">
          <div class="buttons-group gap-4">
            <div *ngIf="continueWithMarketingAuto"
                 (click)="onLaterClick()"
                 class="back-book-button">
              <div class="back-book-button-text">{{'global.later' | translate}}</div>
            </div>
            <div *ngIf="continueWithMarketingAuto"
                 class="navigation-book-button bg-brand-chathams-blue"
                 (click)="setActiveStep(3, $event)">
              <div class="navigation-book-button-text">{{'global.continue' | translate}}</div>
            </div>
            <div *ngIf="!continueWithMarketingAuto"
                 class="back-book-button"
                 (click)="setActiveStep(1, $event)">
              <div class="back-book-button-text">{{'global.back' | translate}}</div>
            </div>
            <div *ngIf="!continueWithMarketingAuto && engagementType !== EngagementTypeEnum.Playlist"
                 class="navigation-book-button bg-brand-chathams-blue"
                 (click)="setActiveStep(3, $event)">
              <div class="navigation-book-button-text">{{'global.next' | translate}}</div>
            </div>
          </div>
          <div *ngIf="!continueWithMarketingAuto"
               class="w-[367px] h-[72px] p-4 bg-neutral-100 rounded justify-start items-center inline-flex">
            <div class="grow shrink basis-0 text-neutral-800 text-sm font-normal font-['Inter'] leading-tight">
              {{ (engagementType == EngagementTypeEnum.Segment ? 'bookConfirmModal.bookSegment' :
              'bookConfirmModal.bookPlaylist') | translate }}
            </div>
          </div>
        </div>
      </div>
      <div *ngSwitchCase="3"
           class="step-container-book">
        <flow-book-step-four [marketingAutomationForm]="marketingAutomationForm"
                             [mediaForm]="mediaForm"
                             [presentationTime]="presentationTime"
                             [isEdit]="isEdit"
                             [bookForm]="bookForm"></flow-book-step-four>
        <div class="buttons-group gap-4">
          <div class="back-book-button"
               (click)="setActiveStep(2, $event)">
            <div class="back-book-button-text">{{'global.back' | translate}}</div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
