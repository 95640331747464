import { Component, AfterViewInit, OnChanges, ElementRef, Input, ViewChild, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { EngagementSlot } from '@api/index';
import { DateTime } from 'luxon';

interface DateRange {
  range: [];
  startDate: DateTime;
}

type FormatedEngagementSlot = Omit<EngagementSlot, 'startDate'> & { startDate: DateTime };

@Component({
  selector: 'flow-book-summary-item',
  templateUrl: './book-summary-item.component.html',
  styleUrls: ['./book-summary-item.component.scss']
})
export class BookSummaryItemComponent implements AfterViewInit, OnChanges {
  @ViewChild('parentContainer') parentContainer!: ElementRef;
  @Input() engagementSlots: EngagementSlot[] | null = null;
  @Output() navigate = new EventEmitter<DateTime>();

  showAll!: boolean;
  dateRange: DateRange[] = [];

  parentWidth!: number;
  slotsWidth!: number;

  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    const parent = this.parentContainer.nativeElement;
    this.parentWidth = this.getElementWidth(parent);

    const slots = parent.children[0];
    const slotsArr = Array.from(slots.children);

    this.slotsWidth = slotsArr.reduce((acc, slot) => {
      acc = acc + this.getElementWidth(slot)
      return acc
    }, 0) as number;

    this.cdr.detectChanges();
  }

  ngOnChanges() {
    const groupSequentialDates = this.groupSequentialDates(this.engagementSlots);
    this.dateRange = [...this.dateRange, ...groupSequentialDates];
  }

  formatEngagementSlots(engagementSlots: EngagementSlot[]): FormatedEngagementSlot[] {
    return engagementSlots.map((slot) =>({ ...slot, startDate: DateTime.fromISO(slot.startDate) }));
  }

  groupSequentialDates(engagementSlots: EngagementSlot[]) {
    const formatedEngagementSlots = this.formatEngagementSlots(engagementSlots);
    const newDates = formatedEngagementSlots.map((slot: FormatedEngagementSlot) => slot.startDate).sort((a, b) => a.valueOf() - b.valueOf());

    let groupedRanges = [];
    let start = newDates[0];
    let end = start;

    for (let i = 1; i < newDates.length; i++) {
      const current = newDates[i];

      // Check if the current date is the next day after previous
      if (current.diff(end, 'days').days === 1) {
        end = current;
      } else {
        // If not, push the current range and reset start and end
        groupedRanges.push({ start, end });
        start = current;
        end = current;
      }
    }

    // Push the last range
    groupedRanges.push({ start, end });

    // Format the grouped ranges
    const updatedDates: DateRange[] = groupedRanges.map(dateRange => {
      let range: any;

      if (dateRange.start.toFormat('d/M') === dateRange.end.toFormat('d/M')) {
        range = dateRange.start.toFormat('d/M');
      } else if (dateRange.start.toFormat('M') === dateRange.end.toFormat('M')) {
        // If the range is within the same month
        range = `${dateRange.start.toFormat('d')}-${dateRange.end.toFormat('d')}/${dateRange.start.toFormat('M')}`;
      } else {
        // If the range spans across different months
        range = `${dateRange.start.toFormat('d/M')}-${dateRange.end.toFormat('d/M')}`;
      }

      return {
        range,
        startDate: dateRange.start
      }
    });

    return updatedDates
  }

  getElementWidth(el: any) {
    return el.offsetWidth +
      this.getStyleProperty(el, 'marginLeft') +
      this.getStyleProperty(el, 'marginRight')
  }

  getStyleProperty(el: any, property: any): any {
    return parseFloat(window.getComputedStyle(el)[property])
  }

  toggleShowAll() {
    this.showAll = !this.showAll;
  }

  navigateToWeek(date: DateTime) {
    this.navigate.emit(date)
  }
}
