import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { uniqueId } from 'lodash-es';
import { Customer, ImageToken, MediaFile, Tag } from '@api/index';
import { TranslateConfigService } from '@app/core/translate-config.service';
import { SystemMessageService } from '@app/core/services/system-message.service';
import { Client } from '@api/models/client';
import { unsubscribeMixin } from '@app/core/unsubscribe';
import { MediaApi } from '@api/services/media-api';
import { SelectedFile } from '@app/models/media-files';

@Component({
  selector: 'flow-media-create-modal',
  templateUrl: './media-create.modal.html',
  styleUrls: ['./media-create.modal.scss']
})
export class MediaCreateModal extends unsubscribeMixin() implements OnInit {
  selectedFiles: SelectedFile[] = [];
  clients: Client[] = [];
  customers: Customer[] = [];
  form: FormGroup;
  imageToken: ImageToken;
  tags: Tag[];
  constructor(
    public dialogRef: MatDialogRef<MediaCreateModal>,
    private mediaApi: MediaApi,
    private formBuilder: FormBuilder,
    private translateConfigService: TranslateConfigService,
    private systemMessageService: SystemMessageService,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.form = this.formBuilder.group({
      customerId: [this.data.customerIdModal, Validators.required],
      name: ['', Validators.required],
      clientId: [],
      tags: [],
      medias: this.formBuilder.array([], Validators.required),
      isClientBoxChecked: [false]
    });
  }

  triggerFileInput() {
    const fileInput = document.getElementById('fileInput') as HTMLElement;
    fileInput.click();
  }

  get mediasArray(): FormArray {
    return this.form.get('medias') as FormArray;
  }
  
  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  selectFile(event: any): void {
    const id = uniqueId();
    const formValues = this.form.getRawValue();
    const body: any = {
      id: id,
      name: formValues?.name,
      clientId: formValues.clientId,
      customerId: formValues.customerId,
      tags: formValues.tags,
      isClientBoxChecked: formValues?.isClientBoxChecked,
      index: id,
    };

    const updatedFile = {...event, ...body};
    this.mediasArray.push(this.formBuilder.control(updatedFile));
    this.mediasArray.updateValueAndValidity();
    this.selectedBody = updatedFile;
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSaved(): void {
    if (this.form.invalid) return;
    const formValues = this.form.getRawValue();
    const selectedFile = formValues.medias[0].selectedFile;
    const tagsJson = JSON.stringify(formValues?.tags || []);
    let paramsString = '';
    if (formValues.customerId) {
      paramsString += `customerId=${this.form?.getRawValue().customerId}`;
    }
    if (formValues?.name && formValues?.name.length > 0) {
      paramsString += `&name=${formValues?.name}`;
    }
    if (formValues?.client) {
      paramsString += `&clientId=${formValues?.client}`;
    }
    if (selectedFile.videoDuration) {
      paramsString += `&duration=${selectedFile.videoDuration}`;
    }
    paramsString += `&shouldCreateFolder=${formValues?.isClientBoxChecked || false}&tags=${tagsJson}`;
    const uploadRequest = selectedFile.type.includes('image') ? this.mediaApi.mediaUploadImage({
      file: selectedFile,
      paramsString
    }) :
      this.mediaApi.mediaUploadVideo({
        file: selectedFile,
        paramsString
      });
    uploadRequest.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((media: any) => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.mediaCreate", this.form.value.name));

        if (media?.id) {
          this.dialogRef.close(media);
        }
    });
  }

  clearFile(id: string): void {
    const existingIndex = this.form.get('medias').value.findIndex((item) => item.index === id);

    if (existingIndex !== -1) {
      this.mediasArray.removeAt(existingIndex);
      this.mediasArray.updateValueAndValidity();
    }
  }
}

interface ModalData {
  media?: MediaFile;
  customerIdModal?: number;
}
