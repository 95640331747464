import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { EnumService } from '@app/core/services/enum.service';
import { MediaModalService } from '@app/core/services/media-modal.service';
import { IdName } from '@api/models/idname';
import {
  DetailMedia,
  EngagementTypeEnum,
  ImageToken,
  MediaTypeEnum,
  StandardMedia
} from "@api/index";
import { environment } from "@environments/environment";
import { FormArraysData } from "../book-step-one/book-step-one.component";

@Component({
  selector: 'flow-book-step-three',
  templateUrl: './book-step-three.component.html',
  styleUrls: ['./book-step-three.component.scss']
})
export class BookStepThreeComponent implements OnInit {
  @Input() bookForm: FormGroup;
  @Input() mediaForm: FormGroup;
  @Input() screens: IdName[] = [];
  @Input() imageToken!: ImageToken;
  @Input() continueWithMarketingAuto!: boolean;
  @Input() presentationTime!: number;
  @Input() formArraysData!: FormArraysData;
  @Input() engagementType!: number;
  @Input() isEdit!: boolean;
  targetGroups: IdName[] = [];
  mediaLinks: IdName[] = [];
  selectedMedias: DetailMedia[] = [];
  allWomenSelected: boolean = false;
  allMenSelected: boolean = false;
  protected readonly EngagementTypeEnum = EngagementTypeEnum;
  protected readonly MediaTypeEnum = MediaTypeEnum;

  constructor(private mediaModalService: MediaModalService,
    private enumService: EnumService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef) {
  }


  ngOnInit(): void {
    this.mediaLinks = this.enumService.mediaLinks;
    this.targetGroups = this.enumService.targetGroups.filter(tg => tg.id != 1 && tg.id != 8);

    if (this.advertisements.value.length === 0) {
      this.advertisements.push(this.toAdvertisementFormGroup())
    }

    if (this.defaultMediaPresentationTime?.value?.type === 2) {
      this.defaultMediaPresentationTime.disable()
    }

    this.advertisements.controls.forEach((m: any) => {
      m.controls.mediaDetails.value?.type === 2 ? m.controls.presentationTime.disable() : m.controls.presentationTime.enable(),
        m.controls.AllMenSelected = false,
        m.controls.AllWomenSelected = false
    });

    this.selectedMedias = this.mediaForm.get('advertisements').getRawValue().map(adv => {
      if (adv.advertisementId != null) {
        adv.mediaDetails.thumbnailUrl = `${environment.apiBaseUrl}${adv.mediaDetails.thumbnailImageUrl}`;
        adv.mediaDetails.url = `${environment.apiBaseUrl}${adv.mediaDetails.imageUrl}`;
        return adv.mediaDetails
      }
    });

    const defaultMediaDetails = this.mediaForm.get('defaultMediaDetails').getRawValue();

    if (defaultMediaDetails) {
      const updatedMediaDetails = {
        ...defaultMediaDetails,
        url: `${environment.apiBaseUrl}${defaultMediaDetails.imageUrl}`
      };
      this.mediaForm.get('defaultMediaDetails').setValue(updatedMediaDetails);
    }

  }
  onAddAdvertisementRow(index: number): void {
    this.advertisements.insert(index + 1, this.toAdvertisementFormGroup());
    this.cdr.detectChanges();
  }
  onRemoveAdvertisementRow(index: number, advGroup: any): void {

    if (this.advertisements.value.filter(ad => ad.isRemoved).length + 1 === this.advertisements.value.length) {
      return;
    }

    if (this.advertisements.value.length < 1)
      return

    if (!advGroup.get('advertisementId').value) {
      this.advertisements.removeAt(index);
    } else {
      advGroup.get('isRemoved').setValue(true);
    }
    this.cdr.detectChanges();
  }
  openCreateMedia(propertyName: string, index?: number): void {
    this.mediaModalService.openCreateMediaModal(index != undefined ? this.advertisements.at(index) : this.mediaForm, propertyName, this.selectedMedias, { customerIdModal: this.bookForm.get('customer').getRawValue() });
  }
  openSelectMedia(propertyName: string, index?: number): void {
    let i = index + 1;
    this.mediaModalService.openSelectMediaModal(i ? this.advertisements.at(index) : this.mediaForm, propertyName, this.selectedMedias, { customerIdModal: this.bookForm.get('customer').getRawValue() });
  }

  get advertisements(): FormArray {
    return this.mediaForm.get('advertisements') as FormArray;
  }

  get defaultMediaDetails(): FormControl {
    return this.mediaForm.get('defaultMediaDetails') as FormControl;
  }

  get defaultMediaPresentationTime(): FormControl {
    return this.mediaForm.get('defaultMediaPresentationTime') as FormControl;
  }


  onTriggerClick(advGroup: any, $event: Event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openTriggerMediaModal(advGroup?.get('triggers').value,
      (triggers: any) => {
        if (!triggers || (triggers && (triggers.temperature == null && (!triggers.weatherConditions || (triggers.weatherConditions && triggers.weatherConditions.length == 0)))))
          triggers = null;
        advGroup?.get('triggers')?.patchValue(triggers);
        advGroup?.get('numOfTriggers')?.patchValue(this.getNumberOfTriggers(advGroup));
      });
  }
  getNumberOfTriggers(advGroup: any) {
    let weatherConditionTriggers = 0;
    let temperatureTriggers = 0

    if (advGroup?.get('triggers').value) {
      weatherConditionTriggers = advGroup?.get('triggers').value.weatherConditions && advGroup?.get('triggers').value.weatherConditions.length > 0 ? advGroup?.get('triggers').value.weatherConditions.length : 0;
      temperatureTriggers = advGroup?.get('triggers').value.temperature && Object.keys(advGroup?.get('triggers').value.temperature).length > 0 ? 1 : 0;
    }

    return weatherConditionTriggers + temperatureTriggers;
  }

  restrictNonNumericalInput(event: KeyboardEvent): void {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }

  removeLeadingZero(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.length > 1 && input.value.startsWith('0')) {
      input.value = input.value.replace(/^0+/, '');
    }
  }

  selectTargetGroupsByGender(gender: number, ad: any) {
    let isChangedWomen = false;
    let isChangedMen = false;

    switch (gender) {
      case 1: {
        ad.AllWomenSelected = !ad.AllWomenSelected
        isChangedWomen = true;
      } break;

      case 2: {
        ad.allMenSelected = !ad.allMenSelected;
        isChangedMen = true;
      } break;
    }

    let newValues = ad.get('targetGroups').value.filter(group => isChangedWomen ? group > 7 : group <= 7);
    if (ad.allMenSelected)
      newValues = [...newValues, ...this.targetGroups.filter(tg => tg.id > 7).map(tg => tg.id)];
    if (ad.AllWomenSelected)
      newValues = [...newValues, ...this.targetGroups.filter(tg => tg.id <= 7).map(tg => tg.id)];

    ad.get('targetGroups').setValue(newValues);
  }

  private toAdvertisementFormGroup(): FormGroup<any> {
    if (this.engagementType == EngagementTypeEnum.Playlist) {
      return this.formBuilder.group({
        advertisementId: null,
        mediaDetails: [null, Validators.required],
        isRemoved: [false],
        engagementSlotId: [],
        presentationTime: [this.presentationTime],
        triggers: [{ temperature: null, weatherConditions: null }],
        numOfTriggers: [0]
      });
    } else {
      return this.formBuilder.group({
        advertisementId: null,
        mediaDetails: [null, Validators.required],
        targetGroups: [[], Validators.required],
        isRemoved: [false],
        engagementSlotId: [],
        presentationTime: [this.presentationTime],
        triggers: [{ temperature: null, weatherConditions: null }],
        numOfTriggers: [0]
      });
    }
  }

  openViewMedia(standardMedia: StandardMedia, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.mediaModalService.openViewMediaModal(standardMedia)
  }

  resetDefaultMedia() {
    this.mediaForm.get('defaultMediaDetails').setValue(null)
    this.mediaForm.get('defaultMediaPresentationTime').enable()
    this.mediaForm.get('defaultMediaPresentationTime').setValue(this.presentationTime);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.advertisements.controls, event.previousIndex, event.currentIndex);
  }

}
