import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { BookEngagement, Bookings, BookingsRequest, EngagementSlot, EngagementTypeEnum, } from 'src/_api';
import { BookingApiService } from 'src/_api/services/booking-api.service';
import { BookingSlot } from 'src/_api';
import { IdName } from "../../../../../_api/models/idname";
import { UpdateAdvertisement } from 'src/_api/models/booking/update-advertisment';

@Injectable({
  providedIn: 'root'
})
export class BookService {
  bookings: Bookings[] = [];
  currentWeekDays: string[] = [];
  params2 : BookingsRequest;

  constructor(private bookingApiService: BookingApiService) { }

  getBookings(startOfWeek, bookForm, engagementId?: number) {
    this.updateWeekDays(startOfWeek);
    const params = this.getBookingsParams(bookForm.get('screens').value, startOfWeek, engagementId)

    if(!engagementId){
      this.bookingApiService.getBookings(params).subscribe(
        data => {
          bookForm.get('bookings').setValue(this.setActiveBookings(this.setBookings(data.bookings, startOfWeek)));
        }
      );
    } else {
      this.bookingApiService.getBookings(params).subscribe(data => {
        bookForm.get('bookings').setValue(this.setActiveBookings(this.setBookings(data.bookings, startOfWeek, true)));
      })
    }

  }
  getBookingsParams(screenIds, startOfWeek, engagementId?: number): BookingsRequest {
    let params: BookingsRequest = {
      screenIds,
      weekStartDate: startOfWeek.toISODate()
    };
    if (engagementId)
      params.engagementId = engagementId;
    return params;
  }

  setBookings(newBookings: Bookings[], startOfWeek, editMode?) {
    newBookings = newBookings.map(s => {

      const splitFunction = editMode
        ? this.splitSummaryBookingSlots.bind(this)
        :  this.splitBookingSlots.bind(this)

      s.engagementSlots = splitFunction(
        s.engagementSlots.sort((a, b) => DateTime.fromISO(a.startDate).toMillis() - DateTime.fromISO(b.startDate).toMillis()),
        startOfWeek?.startOf('week').toISODate() || DateTime.now().startOf('week').toISODate()
      );

      return s;
    });

    if (this.bookings.length === 0) {
      this.bookings = newBookings;
    } else {
      this.bookings = this.getUpdatedBookings(this.bookings, newBookings);
    }

    return this.bookings;
  }

  createBooking(bookForm: any) {
    let bookRequest = this.setBookRequest(bookForm);
    return this.bookingApiService.createNewBooking(bookRequest);
  }
  copyBooking(bookForm: any) {
    let bookRequest = this.setBookRequest(bookForm);
    return this.bookingApiService.copyBooking(bookForm.engagementId, bookRequest);
  }
  publishGeneralInfo(bookForm: any, engagementId: number) {
    let bookRequest = this.setBookRequest(bookForm);
    return this.bookingApiService.updateGeneralInfoAndTimePeriod(engagementId, bookRequest);
  }
  publishMedia(mediaForm: any, engagementId: number, engagementType: number) {
    if (engagementType === EngagementTypeEnum.Segment) {
      let mediaRequest = this.setMediaRequest(mediaForm, engagementType);
      return this.bookingApiService.updateMediaSegment(engagementId, mediaRequest);
    } else {
      let playlistRequest = this.setPlaylistRequest(mediaForm);
      return this.bookingApiService.updateMediaPlaylist(engagementId, playlistRequest)
    }
  }

  setPlaylistRequest(mediaForm: any) {
    let mediaRequest: UpdateAdvertisement = {
      playlistMediaFiles: mediaForm.advertisements.map((adv, index) => {
        return {
          id: adv.advertisementId,
          isRemoved: adv.isRemoved,
          presentationTime: adv.presentationTime,
          mediaId: adv.mediaDetails?.mediaId,
          mediaName: adv.mediaDetails?.name,
          mediaType: adv.mediaDetails?.type,
          triggers: adv.triggers || null,
          orderId: index + 1
        }
      }) || []
    }
    return mediaRequest;
  }


  setMediaRequest(mediaForm: any, engagementType?: number) {
    let mediaRequest: UpdateAdvertisement = {
      defaultMediaId: mediaForm.defaultMediaDetails?.mediaId || null,
      defaultMediaPresentationTime: mediaForm.defaultMediaPresentationTime || null,
      advertisements: mediaForm.advertisements.map(adv => {
        if (engagementType == EngagementTypeEnum.Playlist) {
          return {
            id: adv.advertisementId,
            isRemoved: adv.isRemoved,
            presentationTime: adv.presentationTime,
            mediaId: adv.mediaDetails?.mediaId,
            name: adv.mediaDetails?.name,
            triggers: adv.triggers || null
          }
        } else {
          return {
            id: adv.advertisementId,
            isRemoved: adv.isRemoved,
            presentationTime: adv.presentationTime,
            mediaId: adv.mediaDetails?.mediaId,
            name: adv.mediaDetails?.name,
            targetGroups: adv.targetGroups,
            triggers: adv.triggers || null
          }
        }
      }) || []
    }
    return mediaRequest;
  }
  setBookRequest(bookForm: any) {
    let bookingSlots: BookingSlot[] = [];
    bookForm.bookings.filter(booking => {
      bookingSlots.push({
        screenIds: booking.screens.map(s => s.id),
        engagementSlots: booking.engagementSlots
      });
    })
    let bookRequest: BookEngagement = {
      customerId: bookForm.customer || 0,
      engagementType: bookForm.engagementType || null,
      name: bookForm.name || '',
      bookingSlots: bookingSlots || []
    }
    return bookRequest;
  }
  setActiveBookings(bookings: any) {
    return this.filterBookings(bookings);
  }
  updateWeekDays(currentDate: any) {
    const startOfWeek = currentDate.startOf('week');
    this.currentWeekDays = [];
    for (let i = 0; i < 7; i++) {
      this.currentWeekDays.push(startOfWeek.plus({ days: i }).toISODate());
    }
  }
  filterPlacements(placements) {
    const filteredPlacements = placements.reduce((acc, item) => {
      // Check if the item with this id already exists in the accumulator
      const existingItem = acc.find(({ id }) => id === item.id);

      if (existingItem) {
        // If it exists, push the screen to the screens array
        existingItem.screenIds.push(item.screen.id);
      } else {
        // If it doesn't exist, create a new entry in the accumulator
        const { screen, ...rest } = item;
        acc.push({
          ...rest,
          screenIds: [screen.id]
        });
      }

      return acc;
    }, [])
    return filteredPlacements;
  }
  private filterBookings(bookings) {
    return bookings.map(booking => ({
      ...booking,
      engagementSlots: booking.engagementSlots.filter(slot => slot.isMainBooking)
    })).filter(b => b.engagementSlots.length > 0);
  }

  private getUpdatedBookings(currentBookings: any, newBookings: any): Bookings[] {
    return newBookings.map(newBooking => {
      const existingBooking = currentBookings.find(currentBooking =>
        this.matchBookings(currentBooking.screens, newBooking.screens)
      );

      if (existingBooking) {
        const newEngagementSlots = newBooking.engagementSlots.filter(newSlot =>
          !existingBooking.engagementSlots.some(existingSlot =>
            DateTime.fromISO(existingSlot.startDate).equals(DateTime.fromISO(newSlot.startDate))
          )
        );

        if (newEngagementSlots.length > 0) {
          return {
            ...existingBooking,
            engagementSlots: [...existingBooking.engagementSlots, ...newEngagementSlots]
          };
        }

        return existingBooking;
      }
      return newBooking;
    }).filter(booking => booking.engagementSlots.length > 0);
  }

  private matchBookings(existingBookings: IdName[], newBookings: IdName[]): boolean {
    return existingBookings.some(existingScreen =>
      newBookings.some(newScreen =>
        existingScreen.id === newScreen.id
      )
    );
  }

  private splitSummaryBookingSlots (bookingSlots: EngagementSlot[], date: string): EngagementSlot[] {
    const splitSlots: EngagementSlot[] = [];

    // Sort slots based on the startDate to ensure they are in the correct order
    bookingSlots.sort((a, b) => DateTime.fromISO(a.startDate).toMillis() - DateTime.fromISO(b.startDate).toMillis());

    // Get the first and last date from the slots
    const firstDate = DateTime.fromISO(bookingSlots[0].startDate);
    const lastDate = DateTime.fromISO(bookingSlots[bookingSlots.length - 1].endDate);

    // Create a map of existing dates for fast lookup
    const existingDates = new Map();
    bookingSlots.forEach(slot => {
      const startDate = DateTime.fromISO(slot.startDate);
      const endDate = DateTime.fromISO(slot.endDate);

      // For each day between startDate and endDate, add the slot to the map
      for (let d = startDate; d <= endDate; d = d.plus({ days: 1 })) {
        existingDates.set(d.toISODate(), {
          ...slot,
          startDate: d.toISODate(),
          endDate: d.toISODate(),
          weekStartDate: d.startOf('week').toISODate(),
          weekEndDate: d.endOf('week').toISODate(),
        });
      }
    });

    // Now, iterate through each day from the firstDate to the lastDate, and add missing dates
    for (let d = firstDate; d.toISODate() <= lastDate.toISODate(); d = d.plus({ days: 1 })) {
      const currentDate = d.toISODate();

      if (existingDates.has(currentDate)) {
        // If the date already exists, push it to the result
        splitSlots.push(existingDates.get(currentDate));
      } else {
        // If the date is missing, add a default slot for that day with the week start/end
        splitSlots.push({
          isBooked: false,
          isMainBooking: false,
          startDate: currentDate,
          endDate: currentDate,
          engagementId: null,
          engagementSlotId: null,
          weekStartDate: d.startOf('week').toISODate(),
          weekEndDate: d.endOf('week').toISODate(),
        });
      }
    }
    return splitSlots;
  }


  private splitBookingSlots(bookingSlots: EngagementSlot[], date: string): EngagementSlot[] {
    const splitSlots: EngagementSlot[] = [];
    const { start, end } = this.getWeekBounds(date);

    bookingSlots.forEach(slot => {
      const slotStart = DateTime.fromISO(slot.startDate);
      const slotEnd = DateTime.fromISO(slot.endDate);

      // If the slot is completely outside the week, skip it
      if (slotEnd < start || slotStart > end) {
        return;
      }

      // Adjust the start and end dates to be within the week bounds
      const adjustedStart = slotStart < start ? start : slotStart;
      const adjustedEnd = slotEnd > end ? end : slotEnd;

      let currentDate = adjustedStart;
      while (currentDate <= adjustedEnd) {
        splitSlots.push({
          ...slot,
          startDate: currentDate.toISODate(),
          weekEndDate: currentDate.endOf('week').toISODate(),
          weekStartDate: currentDate.startOf('week').toISODate()
        });
        currentDate = currentDate.plus({ days: 1 });
      }
    });
    return splitSlots;
  }

  private getWeekBounds(date: string) {
    const start = DateTime.fromISO(date).startOf('week');
    const end = DateTime.fromISO(date).endOf('week');
    return { start, end };
  }
}
