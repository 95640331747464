<div *ngIf="data?.length > 0">
  <mat-paginator
    (page)="handlePageEvent($event)"
    [pageSize]="params.pageSize"
    [pageIndex]="params.pageNumber"
    [pageSizeOptions]="pageSizeOptions"
    [length]="totalCount">
  </mat-paginator>
  <div class="table-container">
    <table mat-table
          [dataSource]="dataSource"
          class="table-hover bordered-table"
          (matSortChange)="handleSortEvent($event)"
          matSort
          multiTemplateDataRows
          [matSortDisableClear]="true">

      <ng-container matColumnDef="date">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.date' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          <div *ngIf="reports?.personOfInterestStatisticsReports?.length > 0">{{data.date | date: 'yyyy-MM-dd HH:mm:ss':'UTC'}}</div>
          <div *ngIf="!(reports?.personOfInterestStatisticsReports?.length > 0)">{{data.date | date: 'yyyy-MM-dd'}}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="screenName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="center-header"> {{(activeReport === ActiveReportEnum.PeopleCounter ? 'reports.table.screenAndSensors' : 'reports.table.screen') | translate }} </th>
        <td mat-cell
            *matCellDef="let data"> {{data?.screenName}} </td>
      </ng-container>

      <ng-container matColumnDef="customerName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.customer' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.customerName}}
        </td>
      </ng-container>

      <ng-container matColumnDef="channelName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.channel' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.channelName}}
        </td>
      </ng-container>


      <ng-container matColumnDef="placement">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.placement' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.placement}}
        </td>
      </ng-container>

      <ng-container *ngIf="activeReport === ActiveReportEnum.PeopleCounter || activeReport === ActiveReportEnum.POI"
                    matColumnDef="trackingType">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.tracking' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.trackingType === 1 ? '' : (data?.trackingType === 2 ? 'reports.form.trackingValues.inbound' :
          data?.trackingType === 3 ? 'reports.form.trackingValues.outbound' : '') | translate}}
        </td>
      </ng-container>

      <ng-container matColumnDef="visitors">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.visitors' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.visitors}}
        </td>
      </ng-container>
      <ng-container *ngIf="isIncludeAverageTimeInChannel" matColumnDef="averageTimeInChannel">
        <th mat-header-cell
            *matHeaderCellDef> {{'reports.table.avgTimeInChannel' | translate }} </th>
            <!-- mat-sort-header TODO: add when BE procedure is fixed-->
        <td mat-cell
            *matCellDef="let data">
          {{data?.averageTimeInChannel}}
        </td>
      </ng-container>
      <ng-container matColumnDef="bookingType">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.bookingType' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.bookingType}}
        </td>
      </ng-container>
      <ng-container matColumnDef="bookingNumber">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.bookingNumber' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.bookingNumber}}
        </td>
      </ng-container>
      <ng-container matColumnDef="bookingName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.bookingName' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.bookingName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="fileType">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.fileType' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.fileType === MediaTypeEnum.Image ? ('global.image' | translate) : data?.fileType === MediaTypeEnum.Video
          ? ('global.video' | translate) : ''}}
        </td>
      </ng-container>
      <ng-container matColumnDef="client">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.client' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.clientName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="mediaFileName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.mediaFile' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.mediaFileName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="totalExposures">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.totalExposures' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.totalExposures}}
        </td>
      </ng-container>
      <ng-container matColumnDef="primaryExposures">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.primaryExposures' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.primaryExposures}}
        </td>
      </ng-container>
      <ng-container matColumnDef="secondaryExposures">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.secondaryExposures' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.secondaryExposures}}
        </td>
      </ng-container>
      <ng-container matColumnDef="shareOfVoice">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.shareOfVoice' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.shareOfVoice}}
        </td>
      </ng-container>
      <ng-container matColumnDef="airTime">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.airTime' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.airtime}}
        </td>
      </ng-container>
      <ng-container matColumnDef="attentionTime">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.attentionTime' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.attentionTime}}
        </td>
      </ng-container>
      <ng-container matColumnDef="impressions">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.impressions' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.impression}}
        </td>
      </ng-container>
      <ng-container matColumnDef="reach">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'reports.table.reach' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.reach}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup1">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.under18' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup1}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup2">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.18-24' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup2}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup3">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.25-34' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup3}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup4">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.35-44' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup4}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup5">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.45-54' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup5}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup6">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.55-64' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup6}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup7">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.women' | translate }}: {{ 'reports.table.targetGroups.65+' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup7}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup8">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.under18' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup8}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup9">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.18-24' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup9}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup10">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.25-34' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup10}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup11">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.35-44' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup11}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup12">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.45-54' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup12}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup13">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.55-64' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup13}}
        </td>
      </ng-container>
      <ng-container matColumnDef="targetGroup14">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.targetGroups.men' | translate }}: {{ 'reports.table.targetGroups.65+' | translate | lowercase }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.targetGroup14}}
        </td>
      </ng-container>
      <ng-container matColumnDef="opportunityToSee" *ngIf="activeReport !== ActiveReportEnum.Exposures">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{ 'reports.table.opportunityToSee' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.opportunityToSee}}
        </td>
      </ng-container>
      <ng-container *ngFor="let timeRange of timeRanges; let i = index;"
                    [matColumnDef]="timeRange">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>{{ timeRange }}</th>
        <td mat-cell
            *matCellDef="let data">
          {{ data[timeRange] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="poiCategoryName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'poi.table.poiCategoryName' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.category}}
        </td>
      </ng-container>

      <ng-container matColumnDef="poiName">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'poi.table.poiName' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.personOfInterest}}
        </td>
      </ng-container>
      <ng-container matColumnDef="poiCreatedDate">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'poi.table.poiCreated' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.createdDate | date: 'yyyy-MM-dd'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="poiCreatedBy">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header> {{'poi.table.poiCreatedBy' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.createdBy}}
        </td>
      </ng-container>
      <ng-container matColumnDef="smsNotifications">
        <th mat-header-cell
            *matHeaderCellDef> {{'poi.table.notificationSentTo' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          <div *ngFor="let sentTo of data.smsSentTo; let last = last ">{{sentTo.name}}<span *ngIf="!last">;</span></div>
        </td>
      </ng-container>
      <ng-container matColumnDef="vehicles">
        <th mat-header-cell
            *matHeaderCellDef> {{'reach.vehicles.vehicles' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
          {{data?.totalVehicles}}
        </td>
      </ng-container>
      <ng-container matColumnDef="cars">
        <th mat-header-cell
            *matHeaderCellDef> {{'reach.vehicles.cars' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
            {{data?.cars}}
        </td>
      </ng-container>
      <ng-container matColumnDef="vans">
        <th mat-header-cell
            *matHeaderCellDef> {{'reach.vehicles.vans' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
            {{data?.vans}}
        </td>
      </ng-container>
      <ng-container matColumnDef="buses">
        <th mat-header-cell
            *matHeaderCellDef> {{'reach.vehicles.buses' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
            {{data?.buses}}
        </td>
      </ng-container>
      <ng-container matColumnDef="trucks">
        <th mat-header-cell
            *matHeaderCellDef> {{'reach.vehicles.trucks' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
            {{data?.trucks}}
        </td>
      </ng-container>
      <ng-container matColumnDef="opportunityToSeeVehicles" *ngIf="activeReport !== ActiveReportEnum.Exposures">
        <th mat-header-cell
            *matHeaderCellDef> {{'reach.vehicles.ots' | translate }} </th>
        <td mat-cell
            *matCellDef="let data">
            {{data?.opportunityToSeeVehicles}}
        </td>
      </ng-container>
      <tr mat-header-row
          *matHeaderRowDef="displayedColumns"></tr>
      <ng-container>
        <tr mat-row
            *matRowDef="let data; columns: displayedColumns;"></tr>
      </ng-container>
    </table>
  </div>
</div>
<div *ngIf="data?.length == 0"
      class="no-data-selected-container flex flex-col justify-center items-center">
    <img src="../../../../assets/no-data.png" />
    <p>{{ "global.noDataFound" | translate }}</p>
    <p class="no-data-subtitle">{{ "global.noDataFoundInfo" | translate }}</p>
</div>
