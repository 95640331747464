<div [ngClass]="navMini ? 'container-mini' : 'container-sidenav'">
  <div routerLink="/"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{ exact: true }"
       [ngClass]="{'bg-white hover:bg-neutral-100': !isActive('/') && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': isActive('/') && !navMini, 'active-item-mini': isActive('/') && navMini}">
      <div class="item-icon">
          <app-icon fill="none"
                    [ngStyle]="{cursor:'pointer'}"
                    size="md"
                    [stroke]="isActive('/') && navMini ? '#FAFAFA' : '#202020'"
                    iconName="home-icon"></app-icon>
      </div>
      <div *ngIf="!navMini" class="item-text">{{'sidebar.home' | translate}}</div>
  </div>
  <div *ngIf="!navMini" class="section-cms">
      <div *flowHasPermission="['ViewBookingCms', 'ViewMediaCms']; operation 'OR'" class="section-title">{{'sidebar.sections.cms' | translate | uppercase}}</div>
  </div>
  <div *flowHasPermission="['ViewBookingCms']"
       routerLink="/bookings"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{ exact: true }"
       [ngClass]="{'bg-white hover:bg-neutral-100': (!isActive('/bookings/calendar') && !isActive('/bookings/list')) && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': (isActive('/bookings/calendar') || isActive('/bookings/list')) && !navMini, 'active-item-mini': (isActive('/bookings/calendar') || isActive('/bookings/list')) && navMini}">
       <div class="item-icon">
           <app-icon fill="none"
                     [ngStyle]="{cursor:'pointer'}"
                     size="md"
                     [stroke]="(isActive('/bookings/calendar') || isActive('/bookings/list')) && navMini ? '#FAFAFA' : '#202020'"
                     iconName="overview-icon"></app-icon>
       </div>
       <div *ngIf="!navMini" class="item-text">{{'sidebar.manage' | translate}}</div>
  </div>
  <div *flowHasPermission="['ViewMediaCms']"
        routerLink="/medias"
        routerLinkActive="active"
        [ngClass]="{'bg-white hover:bg-neutral-100': !isActive('/medias') && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': isActive('/medias') && !navMini, 'active-item-mini': isActive('/medias') && navMini}">
      <div class="item-icon">
            <app-icon fill="none"
                      [ngStyle]="{cursor:'pointer'}"
                      size="md"
                      [stroke]="isActive('/medias') && navMini ? '#FAFAFA' : '#202020'"
                      iconName="media-icon"></app-icon>
      </div>
      <div *ngIf="!navMini" class="item-text">{{'sidebar.media' | translate}}</div>
  </div>
  <div *ngIf="!navMini" class="section-analytics">
      <div *flowHasPermission="['ViewReachAnalytics', 'ViewReachReportsAnalytics', 'ViewExposuresAnalytics', 'ViewExposuresReportsAnalytics']; operation 'OR'" class="section-title">{{'sidebar.sections.analytics' | translate | uppercase}}</div>
  </div>
  <div *flowHasPermission="['ViewReachAnalytics', 'ViewReachReportsAnalytics']; operation 'OR'"
        routerLink="/statistics/reach"
        routerLinkActive="active"
        [ngClass]="{'bg-white hover:bg-neutral-100': !isActive('/statistics/reach') && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': isActive('/statistics/reach') && !navMini, 'active-item-mini': isActive('/statistics/reach') && navMini}">
      <div class="item-icon">
            <app-icon fill="none"
                      [ngStyle]="{cursor:'pointer'}"
                      size="md"
                      [stroke]="isActive('/statistics/reach') && navMini ? '#FAFAFA' : '#202020'"
                      iconName="reach-icon"></app-icon>
      </div>
      <div *ngIf="!navMini" class="item-text">{{'sidebar.reach' | translate}}</div>
  </div>
  <div *flowHasPermission="['ViewExposuresAnalytics', 'ViewExposuresReportsAnalytics']; operation 'OR'"
        routerLink="/statistics/views"
        routerLinkActive="active"
        [ngClass]="{'bg-white hover:bg-neutral-100': !isActive('/statistics/views') && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': isActive('/statistics/views') && !navMini, 'active-item-mini': isActive('/statistics/views') && navMini}">
      <div class="item-icon">
            <app-icon fill="none"
                      [ngStyle]="{cursor:'pointer'}"
                      size="md"
                      [stroke]="isActive('/statistics/views') && navMini ? '#FAFAFA' : '#202020'"
                      iconName="exposures-icon"></app-icon>
      </div>
      <div *ngIf="!navMini" class="item-text">{{'sidebar.exposures' | translate}}</div>
  </div>
  <div *ngIf="!navMini" class="section-insights">
      <div *flowHasPermission="['ViewPeopleCounterInsights', 'ViewPeopleCounterReportsInsights', 'ViewPoiInsights', 'ViewPoiCategoriesInsights', 'ViewPoiReportsInsights']; operation 'OR'" class="section-title">{{'sidebar.sections.visitorInsights' | translate | uppercase}}</div>
  </div>
  <div *flowHasPermission="['ViewPeopleCounterInsights', 'ViewPeopleCounterReportsInsights']; operation 'OR'"
        routerLink="/statistics/people-count"
        routerLinkActive="active"
        [ngClass]="{'bg-white hover:bg-neutral-100': !isActive('/statistics/people-count') && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': isActive('/statistics/people-count') && !navMini, 'active-item-mini': isActive('/statistics/people-count') && navMini}">
      <div class="item-icon">
            <app-icon fill="none"
                      [ngStyle]="{cursor:'pointer'}"
                      size="md"
                      [stroke]="isActive('/statistics/people-count') && navMini ? '#FAFAFA' : '#202020'"
                      iconName="people-counter-icon"></app-icon>
      </div>
      <div *ngIf="!navMini" class="item-text">{{'sidebar.peopleCount' | translate}}</div>
  </div>
  <div *flowHasPermission="['ViewPoiInsights', 'ViewPoiCategoriesInsights', 'ViewPoiReportsInsights']; operation 'OR'"
        routerLink="/person-of-interest"
        routerLinkActive="active"
        [ngClass]="{'bg-white hover:bg-neutral-100': !isActive('/person-of-interest') && !navMini, 'item': !navMini, 'item-mini': navMini, 'active-item': isActive('/person-of-interest') && !navMini, 'active-item-mini': isActive('/person-of-interest') && navMini}">
      <div class="item-icon">
            <app-icon fill="none"
                      [ngStyle]="{cursor:'pointer'}"
                      size="md"
                      [stroke]="isActive('/person-of-interest') && navMini ? '#FAFAFA' : '#202020'"
                      iconName="poi-icon"></app-icon>
      </div>
      <div *ngIf="!navMini" class="item-text">{{'sidebar.poi' | translate}}</div>
  </div>
</div>
