<h1 mat-dialog-title>{{'bookConfirmModal.title' | translate}}<a class="mat-dialog-close-button"
     mat-icon-button
     [mat-dialog-close]="true"
     tabindex="0">
    <mat-icon color="warn">close</mat-icon>
  </a></h1>
<div mat-dialog-content
     class="py-2">
  <div>
    <div> {{ data.engagementType===EngagementTypeRouteEnum.Segments ? ('bookConfirmModal.bookSegment' |
      translate):('bookConfirmModal.bookPlaylist' | translate) }}</div>
  </div>
  <div class="mt-3">
    <div>{{'bookConfirmModal.text2' | translate }}</div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button
          mat-dialog-close
          (click)="onLater()"
          type="button">{{'bookConfirmModal.later' | translate}}</button>
  <button mat-raised-button
          type="button"
          color="primary"
          (click)="onContinue()">{{'bookConfirmModal.continue' | translate}}</button>
</div>