import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DateTime} from "luxon";
import {MediaFile} from "../../../../../../_api";
import { BookService } from '../../services/book.service';

@Component({
  selector: 'flow-week-navigation',
  templateUrl: './week-navigation.component.html',
  styleUrls: ['./week-navigation.component.scss']
})
export class WeekNavigationComponent implements OnChanges {
  @Input() startOfWeek!: DateTime;
  @Output() weekChange = new EventEmitter<DateTime>();
  weekNumber: number;
  startDate: Date | string;
  endDate: Date | string;
  year: number

  constructor(private bookService: BookService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startOfWeek) {
      this.getWeekStrings(this.startOfWeek);
    }
  }
  getWeekStrings(date) {
    this.endDate = date.endOf('week');
    this.weekNumber = date.weekNumber
    this.startDate = date.startOf('week');
  }
  getPreviousWeek() {
   const prevWeek = this.startOfWeek.minus({days: 7});
   this.bookService.updateWeekDays(prevWeek);
    this.weekChange.emit(prevWeek)
  }
  getNextWeek() {
   const nextWeek = this.startOfWeek.plus({days: 7});
   this.bookService.updateWeekDays(nextWeek);
    this.weekChange.emit(nextWeek)
  }
  navigateToToday() {
    const today = DateTime.now().startOf('week');
    this.bookService.updateWeekDays(today);
    this.weekChange.emit(today)
  }
}
