<form *ngIf="bookForm"
      [formGroup]="bookForm">
  <div class="container-book-step-one">
    <div class="inputs-content-book">
      <div class="input-dimensions">
        <mat-label>{{ 'book.form.customerLabel' | translate }} *</mat-label>
        <mat-form-field appearance="outline"
                        class="w-full">
          <mat-select formControlName="customer"
                      placeholder="{{'book.form.customerPlaceholder' | translate}}"
                      (selectionChange)="onSelectionChange($event,1)">
            <mat-option *ngFor="let customer of formArraysData.customers"
                        [value]="customer.id">
              {{ customer.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <flow-dropdown-input formControlName="customer"
                             (onSelectionChange)="onSelectionChange($event, 1)"
                             [options]="formArraysData.customers"
                             [label]="'book.form.customerLabel' | translate"
                             [placeholder]="'book.form.customerPlaceholder' | translate">
          <div *ngIf="bookForm.get('customer')?.invalid && (bookForm.get('customer')?.dirty || bookForm.get('customer')?.touched)" error-messages>This is a custom error message!</div>
        </flow-dropdown-input> -->
      </div>
      <div class="input-dimensions">
        <mat-label>{{ 'book.form.channelLabel' | translate }} *</mat-label>
        <mat-form-field appearance="outline"
                        class="w-full">
          <mat-select formControlName="channels"
                      multiple
                      placeholder="{{'book.form.channelPlaceholder' | translate}}"
                      (selectionChange)="onSelectionChange($event,2)">
            <mat-option *ngFor="let channel of formArraysData.channels"
                        [value]="channel.id">
              {{ channel.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <flow-dropdown-input formControlName="channels"
                             (onSelectionChange)="onSelectionChange($event, 2)"
                             [options]="this.formArraysData.channels"
                             [multiSelect]="true"
                             [label]="'book.form.channelLabel' | translate"
                             [placeholder]="'book.form.channelPlaceholder' | translate">
          <div *ngIf="bookForm.get('channels')?.invalid && (bookForm.get('channels')?.dirty || bookForm.get('channels')?.touched)" error-messages>This is a custom error message!</div>
        </flow-dropdown-input> -->
      </div>
      <div class="input-dimensions">
        <mat-label>{{ 'book.form.placementLabel' | translate }} *</mat-label>
        <mat-form-field appearance="outline"
                        class="w-full">
          <mat-select formControlName="placements"
                      multiple
                      placeholder="{{'book.form.placementPlaceholder' | translate}}"
                      (selectionChange)="onSelectionChange($event,3)">
            <mat-option *ngFor="let placement of formArraysData.placements"
                        [value]="placement.id">
              {{ placement.name | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <flow-dropdown-input formControlName="placements"
                             (onSelectionChange)="onSelectionChange($event, 3)"
                             [options]="this.formArraysData.placements"
                             [multiSelect]="true"
                             [label]="'book.form.placementLabel' | translate"
                             [placeholder]="'book.form.placementPlaceholder' | translate">
          <div *ngIf="bookForm.get('placements')?.invalid && (bookForm.get('placements')?.dirty || bookForm.get('placements')?.touched)" error-messages>This is a custom error message!</div>
        </flow-dropdown-input> -->
      </div>
      <div>
        <div class="input-dimensions">
          <mat-label>{{'book.form.nameLabel' | translate}} *</mat-label>
          <mat-form-field class="w-full">
            <input matInput
                   type="text"
                   formControlName="name">
          </mat-form-field>
        </div>
        <div class="error-message"
             *ngIf="bookForm.get('name')?.invalid && (bookForm.get('name')?.dirty || bookForm.get('name')?.touched)">
          Please enter the booking name!</div>
      </div>
    </div>
    <div class="note-book-wrap">
      <div class="note-book-text">{{(bookForm.get('engagementType').value == EngagementTypeEnum.Segment ?
        'book.stepOneNote.segmentInfo' : 'book.stepOneNote.playlistInfo') | translate}}</div>
    </div>
  </div>
</form>