export interface PaginatorParams {
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc' | "";
}

export interface PaginationResult<T> {
  items: T[]
  currentPage: number,
  totalPages: number,
  pageSize: number,
  totalCount: number,
  hasPrevious: boolean,
  hasNext: boolean
}
