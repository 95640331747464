<div *ngIf="!continueWithMedia"
    class="flex gap-6">
  <div class="flex-col">
    <div class="w-[220px] mt-[-1.375rem]">
      <mat-calendar [(selected)]="selected" (selectedChange)="onDateChanged($event)"></mat-calendar>
    </div>
    <flow-booking-summary [selectedBookings]="bookForm.get('bookings').value" (weekChange)="onDateChanged($event)"></flow-booking-summary>
  </div>

  <div class="flex-col">
      <flow-week-navigation [startOfWeek]="startOfWeek" (weekChange)="onDateChanged($event)"></flow-week-navigation>
    <div>
      <flow-calendar *ngIf="bookings.length > 0" [bookings]="bookings" [week]="week" [bookForm]="bookForm"></flow-calendar>
    </div>
  </div>
</div>
<div *ngIf="continueWithMedia"
     class="w-[613px] h-[78px] flex-col justify-start items-start gap-6 inline-flex">
    <div class="justify-center items-center inline-flex">
        <div class="text-neutral-800 text-base font-semibold font-['Inter'] leading-[18px]">
            {{'editEngagement.mediaQuestionnaireTitle' | translate }}</div>
    </div>
    <div class="justify-center items-center flex flex-col gap-4">
        <div class="text-neutral-800 text-sm font-normal font-['Inter'] leading-[18px]">
            {{'editEngagement.mediaQuestionnaireSubtitle' | translate: {engagementType: EngagementTypeEnum[engagementType] | lowercase} }}
        </div>
      <div *ngIf="engagementType == EngagementTypeEnum.Segment" class="text-neutral-800 text-sm font-normal font-['Inter'] leading-[18px]">
            {{'editEngagement.mediaQuestionnaireSubtitleSegment' | translate}}
        </div>
    </div>
</div>


