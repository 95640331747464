
<div
  class="flex w-full min-h-40 h-fit px-2 py-4 rounded border border-gray-400 flex-col justify-center items-center bg-white"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (drop)="onDrop($event)"
  (click)="triggerFileInput()" 
  [ngClass]="{'border-2 border-gray-300 border-dashed': isDragging}">
  
  <input type="file" #fileInput name="fileInput" accept="image/*, video/*" (change)="selectFile($event)" class="hidden" />

  <div class="w-full h-[78px] flex justify-center items-center">
    <img *ngIf="selectedBody && selectedBody?.format === 'image'"
          class="w-fit h-fit max-h-[78px] rounded"
          [src]="selectedBody.filePreview" />
    <img *ngIf="!selectedBody"
          class="w-fit h-fit max-h-[78px] rounded"
          [src]="'../../../../assets/TechLife.png'" />
    <mat-icon *ngIf="selectedBody?.format === 'video'">play_circle</mat-icon>
  </div>
  
  <div class="flex-col justify-start items-center gap-2 flex">
    <div class="w-full text-center">
      <span class="text-gray-900 text-center text-xs font-semibold leading-3">{{'createMedia.dropFiles' | translate}} </span>
      <span class="text-brand-bright-orange text-center text-xs font-semibold underline leading-3 cursor-pointer">{{'createMedia.selectFromLibrary' | translate}}</span>
    </div>
    <div class="m-0 text-gray-800 text-center text-xs font-normal  leading-[1rem]">{{'createMedia.supports' | translate}}</div>  
  </div>
</div>