import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActiveReportEnum } from "@api/models/active-report-enum";
import { DateTime } from 'luxon';
import {forkJoin, mergeMap, of, takeUntil} from 'rxjs';
import {AdminApi, Channel, Customer, EngagementsAdminApi, PeopleCounterResponse, StatisticsApi} from 'src/_api';
import { PeopleCounterStatisticsResponse } from 'src/_api/models/people-counter-statistics-response';
import { AuthService, UserRoleEnum } from 'src/app/core/auth/auth.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { PeopleCountFormGroup } from './people-count-models';
import { PermissionsService } from '@app/core/services/permissions.service';
import {IdName} from "@api/models/idname";
import {EnumService} from "@core/services/enum.service";

@Component({
  selector: 'flow-people-count',
  templateUrl: './people-count.component.html',
  styleUrls: ['./people-count.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PeopleCountComponent extends unsubscribeMixin() implements OnInit {
  pageLoad: boolean = false;
  channels: Channel[];
  filteredChannels: Channel[];
  customers: Customer[];
  form: FormGroup<PeopleCountFormGroup>;
  isChannels: boolean = false;
  isAdmin: boolean;
  customerId: number;
  screens: IdName[];
  consentGiven: boolean = false;
  consentKey = 'peopleCount-consent'
  protected readonly ActiveReportEnum = ActiveReportEnum;
  peopleCounterStatisticsResponse: PeopleCounterStatisticsResponse;
  days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  selectedIndex: number = 0;
  disablePeopleCounterInsights: boolean = false;
  disablePeopleCounterReportsInsights: boolean = false;
  timeframeTypes = []
  selectedChannels: Channel[];
  compare: boolean;
  showKpiBox: boolean;
  visitorsData: VisitorsPerHour[];
  visitorsCompareData: VisitorsPerHour[];
  ageDistributionData: AgeRangeDistributionResponse;
  ageDistributionCompareData: AgeRangeDistributionResponse;
  genderDistributionData: GenderDistribution;
  genderDistributionCompareData: any;
  initialLoad = true;
  startDate: string;
  endDate: string;
  compareStartDate: string;
  compareEndDate: string;
  selectedScreens: IdName[];
  compareMode: boolean;

  constructor(
    private adminApi: AdminApi,
    private statisticsApi: StatisticsApi,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private engagementsAdminApi: EngagementsAdminApi,
    private permissionsService: PermissionsService,
    private enumService: EnumService,
  ) {
    super();
    this.timeframeTypes = this.enumService.timeframeTypes;
  }

  ngOnInit(): void {
    if (!this.permissionsService.hasPermissions(['ViewPeopleCounterInsights'])) {
      this.disablePeopleCounterInsights = true;
    }
    if (!this.permissionsService.hasPermissions(['ViewPeopleCounterReportsInsights'])) {
      this.disablePeopleCounterReportsInsights = true;
    }
    if (this.disablePeopleCounterInsights && !this.disablePeopleCounterReportsInsights) this.selectedIndex = 1;
    this.initForm();
    const user = this.authService.userData;
    this.isAdmin = user.roleId === UserRoleEnum.Admin && !user.currentCustomerId;
    if (!this.isAdmin) {
      const userCustomerId = user?.currentCustomerId || user?.customerId;
      this.customerId = userCustomerId;
      this.getData([userCustomerId]);
    } else {
      this.getData();
    }

    this.form.get('customer').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((customer) => {
        if (this.isAdmin) {
          const channels = customer.channels;
          this.isChannels = false;
          this.filteredChannels = channels.filter(channel => channel.isActive);
          if (this.filteredChannels && this.filteredChannels?.length > 0) {
            this.form.get('channels').enable();
          } else {
            this.form.get('channels').disable();
          }
          this.pageLoad = false;
        }
      });

    this.form.get('channels').valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((channels) => {
      this.isChannels = false;
      if (channels?.length > 0) {
        this.selectedChannels = channels;
        this.enableScreensControl();
        this.loadScreens();
      } else {
        this.resetScreensControl();
      }
    });

    this.form.get('timeframe').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((timeframe)=> {this.compare = false})


    this.form.get('rangeCompare').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((comparison) => {
        if(comparison.start && comparison.end ) {
          this.compare = true
        }else {
          this.compare = false
        }
      })

    this.form.get('screens').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((screens) => {this.selectedScreens = screens})
  }

  getData(customerIds?: number[]) {
    let params: any = {status: true};
    if (customerIds && customerIds.length > 0)
      params.customerIds = customerIds;
    forkJoin([
      customerIds && customerIds.length > 0 ? this.adminApi.getChannels(params) : of(null),
      this.isAdmin ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin ? this.adminApi.getCustomerById(this.customerId) : of(null),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([channels, customers, customer]) => {
        this.customers = customers;
        this.channels = channels || [];
        if (!this.isAdmin) {
          this.customers = [customer];
          this.filteredChannels = channels;
          this.form.get('customer').setValue(customer);
          this.form.get('channels').setValue(channels);
        }
      });
  }


  getCounterPageData() {
    this.isChannels = true
    this.compareMode = this.compare
    const customerId = this.form.get('customer').value.id;
    const channelIds = this.form.get('channels').value.map((l) => l.id);
    const screenIds = this.form.get('screens').value.map(s => s.id);
    this.startDate = this.start.value.toISODate()
    this.endDate = this.end.value.toISODate()
    this.compareStartDate = this.compareStart.value?.toISODate()
    this.compareEndDate = this.compareEnd.value?.toISODate()
    const currentTime = DateTime.now().toISODate();
    this.showKpiBox = this.isTodayInRange(this.startDate, this.endDate)

    this.getKpiData(customerId, channelIds, screenIds, currentTime)

    this.getVisitorsPerHour(customerId, screenIds, this.startDate, this.endDate, this.compareStartDate, this.compareEndDate)

    this.getAgeDistribution(customerId, screenIds, this.startDate, this.endDate, this.compareStartDate, this.compareEndDate)

    this.getGenderDistribution(customerId, screenIds, this.startDate, this.endDate, this.compareStartDate, this.compareEndDate)

   }

  submitForm(){
    this.getCounterPageData()
  }

  get start(): FormControl {
    return this.form.get('start') as FormControl;
  }

  get end(): FormControl {
    return this.form.get('end') as FormControl;
  }


  get compareStart(): FormControl {
    return this.form.get('rangeCompare').get('start') as FormControl
  }

  get compareEnd(): FormControl {
    return this.form.get('rangeCompare').get('end') as FormControl
  }


  checkConsent(): void {
    const consent = localStorage.getItem(this.consentKey);
    if (consent) {
      this.consentGiven = true;
    }
  }

  private initForm(): void {
    this.checkConsent()
    this.form = this.formBuilder.group({
      customer: this.formBuilder.control(null, Validators.required),
      channels: this.formBuilder.control(null, Validators.required),
      screens: this.formBuilder.control(null, Validators.required),
      timeframe: this.formBuilder.control(1, Validators.required),
      start: this.formBuilder.control(DateTime.now().startOf('week'), Validators.required),
      end: this.formBuilder.control(DateTime.now().endOf('week'), Validators.required),
      rangeCompare: this.formBuilder.group({
        start: this.formBuilder.control(null),
        end: this.formBuilder.control(null)
      }),
    });
    if (this.isAdmin) {
      this.form.get('channels').disable();
    }
  }

  private enableScreensControl() {
    this.form.get('screens').enable();
  }

  private resetScreensControl() {
    this.form.get('screens').disable();
    this.form.get('screens').reset();
  }

  private handleScreensForNonAdmin() {
    if (!this.isAdmin) {
      this.form.get('screens').setValue([this.screens[0]]);
      if (this.initialLoad) {
        this.submitForm();
        this.initialLoad = false;
      }
    }
  }

  private loadScreens() {
    const customerId = this.form.get('customer').value.id;
    const channelIds = this.selectedChannels.map(x => x.id);

    this.engagementsAdminApi.getScreens({ customerIds: [customerId], channelIds }).subscribe((screens) => {
      this.screens = screens as IdName[];
      this.handleScreensForNonAdmin();
    });
  }

  private isTodayInRange(startDate, endDate) {
    const start = DateTime.fromISO(startDate).startOf('day');
    const end = DateTime.fromISO(endDate).endOf('day');

    const today = DateTime.now().startOf('day');

    return today >= start && today <= end;
  }

  private getKpiData(customerId, channelIds, screenIds, currentTime){
    this.statisticsApi.getPeopleCounterStatistics({customerId, channelIds, screenIds, currentTime: currentTime})
      .subscribe((statistics) => {this.peopleCounterStatisticsResponse = statistics})
  }

  private getVisitorsPerHour( customerId, screenIds, startDate, endDate, compareStart, compareEnd){
    this.statisticsApi.getVisitorsPerHour({ customerId, screenIds, startDate, endDate }).pipe(
      mergeMap((currentData) =>
        this.compare
          ? this.statisticsApi.getVisitorsPerHour({ customerId, screenIds, startDate: compareStart, endDate: compareEnd }).pipe(
            mergeMap((compareData) => of([currentData, compareData]))
          )
          : of([currentData, null])
      )
    ).subscribe(([currentData, compareData]) => {
      this.visitorsData = currentData?.visitors
      this.visitorsCompareData = compareData?.visitors

    });
  }

  private  getAgeDistribution(customerId, screenIds, startDate, endDate, compareStart?, compareEnd?){
    this.statisticsApi.getAgeDistribution({ customerId, screenIds, startDate, endDate }).pipe(
      mergeMap((currentAgeDistribution) =>
        this.compare
          ? this.statisticsApi.getAgeDistribution({ customerId, screenIds, startDate: compareStart, endDate:compareEnd }).pipe(
            mergeMap((compareAgeDistribution) => of([currentAgeDistribution, compareAgeDistribution]))
          )
          : of([currentAgeDistribution, null])
      )
    ).subscribe(([currentAgeDistribution, compareAgeDistribution]) => {
      this.ageDistributionData = currentAgeDistribution
      this.ageDistributionCompareData = compareAgeDistribution
    });
  }

  private getGenderDistribution(customerId, screenIds, startDate, endDate, compareStart?, compareEnd?){
    this.statisticsApi.getGenderDistribution({ customerId, screenIds, startDate, endDate }).pipe(
      mergeMap((currentGenderDistribution) =>
        this.compare
          ? this.statisticsApi.getGenderDistribution({ customerId, screenIds, startDate: compareStart, endDate:compareEnd }).pipe(
            mergeMap((compareGenderDistribution) => of([currentGenderDistribution, compareGenderDistribution]))
          )
          : of([currentGenderDistribution, null])
      )
    ).subscribe(([currentGenderDistribution, compareGenderDistribution]) => {
      this.genderDistributionData = currentGenderDistribution
      this.genderDistributionCompareData = compareGenderDistribution
    });
  }
}


export interface VisitorsResponse {
  visitors: VisitorsPerHour[];
}

export interface VisitorsPerHour {
  hour: number;
  totalMen: number;
  totalWomen: number;
}

export interface AgeDistribution {
  ageRange: string;
  totalMen: number;
  totalWomen: number;
  total: number;
}

export interface AgeRangeDistributionResponse {
  ageRangeDistribution: AgeDistribution[];
  total: number;
}

export interface GenderDistribution {
  percentageOfMen: number
  percentageOfWomen: number
}
