import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@api/index';
import { AuthService } from '../auth.service';

@Component({
  selector: 'flow-choose-tenant',
  templateUrl: './choose-tenant.component.html',
  styleUrls: ['./choose-tenant.component.scss']
})
export class ChooseTenantComponent implements OnInit {
  customers: Customer[];
  loadingLogin = false;
  navigating = false;
  form: FormGroup;
  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.customers = navigation.extras?.state?.customers;

    if (!this.customers) {
      this.router.navigate(['login']);
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      customerId: ['', Validators.required]
    });
  }

  selectCustomer(): void {
    if (this.form.valid) {
      const customerId = this.form.value.customerId;
      this.loadingLogin = true;
      this.authService.userProfile(customerId, this.authService.user.email).subscribe({
        next: () => {
          this.loadingLogin = false;
          this.router.navigate(['dashboard']);
        },
        error: (err) => {
          this.loadingLogin = false;
          console.error(err);
        }
      });
    }
  }
}
