import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin, of, takeUntil, map } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

import { BookService } from "./services/book.service";
import { FormArraysData } from "./book-step-one/book-step-one.component";
import { unsubscribeMixin } from "@core/unsubscribe";
import {
  AdminApi,
  Channel,
  Customer,
  EngagementTypeEnum,
  ImageToken,
  MediaFile,
  Placement,
  PlaylistMediaFileSettings,
  Slot,
  StandardMedia
} from "@api/index";
import { BookingList } from "@api/models/booking";
import { BookingApiService } from "@api/services/booking-api.service";
import { AuthService, UserRoleEnum } from "@core/auth/auth.service";
import { SystemMessageService } from "@core/services/system-message.service";
import { TranslateConfigService } from "@core/translate-config.service";
import { ConfirmationModalComponent } from "@shared/confirmation-modal/confirmation-modal.component";

@Component({
  selector: 'flow-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent extends unsubscribeMixin() implements OnInit, OnDestroy {
  bookForm: FormGroup;
  mediaForm: FormGroup;
  marketingAutomationForm: FormGroup;
  title: string = '';
  isEdit: boolean;
  continueCreateMode = false;
  isAdmin: boolean;
  activeStep: number = 0;
  customerId: number;
  engagementId: number;
  engagementTypePath: string;
  medias: MediaFile[];
  engagementType: number;
  continueWithMedia: boolean;
  continueWithMarketingAuto: boolean;
  startOfWeek: any;
  details;
  steps = [
    'book.generalInformation',
    'book.timePeriod'
  ];
  formArraysData: FormArraysData = { customers: [], channels: [], placements: [] };
  imageToken: ImageToken;
  childPlaylist: any;
  presentationTime: number;
  stateEngagement: BookingList;
  isStateReady: boolean = false;
  titleMessage: string;
  displayMessage: string;
  hasError: boolean = false;
  stateBookData: StateBookData;
  allowPublish: boolean = true;
  EngagementTypeEnum = EngagementTypeEnum;
  isCopy = false;
  @ViewChild('itemsBook') itemsBook: ElementRef;

  constructor(private activatedRoute: ActivatedRoute,
    private bookService: BookService,
    private fb: FormBuilder,
    private authService: AuthService,
    private adminApi: AdminApi,
    private bookingApiService: BookingApiService,
    private router: Router,
    private translateConfigService: TranslateConfigService,
    private translateService: TranslateService,
    private systemMessageService: SystemMessageService,
    public dialog: MatDialog
  ) {
    super();
  }

  get playlistMediaFiles(): FormArray {
    return this.marketingAutomationForm.get('marketingAutomation.playlistMediaFiles') as FormArray;
  }

  ngOnInit(): void {
    this.engagementId = +this.activatedRoute.snapshot.params['id'] || null;
    this.isEdit = Boolean(this.engagementId);
    this.engagementTypePath = this.activatedRoute.snapshot.routeConfig.path;
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId && !user.customerId;
    this.customerId = user?.currentCustomerId || user?.customerId;
    this.activatedRoute
      .paramMap
      .pipe(map(() => window.history.state))
      .subscribe(state => {
        if (state?.data?.engagement) {
          this.stateEngagement = state.data.engagement;
          this.isCopy = true
          this.engagementType = this.stateEngagement.engagementType;
          this.title = this.getEngagementTitle();
          this.engagementId = this.stateEngagement.engagement.id;
          let modifiedPlacements = this.stateEngagement?.placements.flatMap((p) => ({
            id: p.id,
            name: p.name,
            screenIds: p.screens.map(s => s.id),
            isSelected: p.isSelected
          }))
          this.formArraysData = {
            customers: [this.stateEngagement.customer],
            channels: this.stateEngagement?.channels,
            placements: modifiedPlacements.map((placement) => ({ id: placement, name: placement.name }))
          };
          this.stateEngagement.placements = modifiedPlacements;
          this.initForm(this.stateEngagement);
          this.bookForm.get('customer').disable();
          const today = DateTime.now();
          const startOfWeek = today.startOf('week');
          this.bookService.getBookings(startOfWeek, this.bookForm, null);
          this.isStateReady = true;

        } else {
          if (state?.data?.channel) {
            this.startOfWeek = DateTime.fromISO(state.data.startDate);
            this.stateBookData = state.data;
            this.stateBookData.startDate = this.startOfWeek;
          }

          if (state.continueWithMedia) {
            this.activeStep = 2;
            this.continueCreateMode = state?.continueCreateMode;
          }

          this.title = this.getEngagementTitle();

          if (this.engagementType == EngagementTypeEnum.Segment)
            this.steps = [...this.steps, ...['book.media', 'book.marketingAutomation']];
          else
            this.steps.push('book.media');
          if (this.isEdit)
            this.getData();
          else
            this.initForm();
        }
      });
  }
  getData(publishIndex?) {
    forkJoin([
      this.engagementType === EngagementTypeEnum.Playlist ?
        this.bookingApiService.getPlaylistDetails(this.engagementId) : of(null),
      this.engagementType === EngagementTypeEnum.Segment ?
        this.bookingApiService.getSegmentDetails(this.engagementId) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([playlist, segment]) => {
        this.details = playlist || segment.segmentDetails;
        this.childPlaylist = segment?.childPlaylist;
        this.formArraysData = {
          customers: [this.details.customer],
          channels: this.details.channels,
          placements: this.details.placements.map(p => {
            return { id: p, name: p.name }
          })
        }
        this.initForm(this.details, this.childPlaylist);
        this.bookForm.get('customer').disable();
        const today = DateTime.now();
        const startOfWeek = today.startOf('week');
        this.startOfWeek = DateTime.fromISO(this.details.dateOfFirstEngagementSlot).startOf('week') || startOfWeek
        this.bookService.getBookings(DateTime.fromISO(this.details.dateOfFirstEngagementSlot).startOf('week') || startOfWeek, this.bookForm, this.engagementId);
      })
  }
  initForm(data?, childPlayList?) {
    this.bookForm = this.fb.group({
      customer: [data?.customer.id || this.customerId || 0, Validators.required],
      name: [(data?.segment?.name || data?.playlist?.name) || (this.stateEngagement ? `${this.stateEngagement.engagement.name} - ${this.translateService.instant('global.copy')}` : ''), Validators.required],
      screens: [data?.placements.filter(p => p.isSelected === true).flatMap(p => p.screenIds) || [], Validators.required],
      channels: [(data?.channels.filter(c => c.isSelected).map(c => c.id)) || [], Validators.required],
      placements: [data?.placements.filter(p => p.isSelected) || [], Validators.required],
      bookings: [[], Validators.required],
      engagementType: [this.engagementType, Validators.required],
      engagementId: [this.engagementId],
    });
    if (!this.stateEngagement) {
      this.mediaForm = this.fb.group({
        advertisements: this.fb.array([], Validators.required),
        defaultMediaDetails: [data?.defaultMediaDetails],
        defaultMediaPresentationTime: [data?.defaultMediaPresentationTime || this.presentationTime]
      });
      if (this.engagementType !== EngagementTypeEnum.Playlist) {
        this.marketingAutomationForm = this.fb.group({
          isEnabled: [data?.hasMarketingAutomation || false, Validators.required],
          marketingAutomation: this.fb.group({
            childPlayListId: [childPlayList?.childPlaylist.id],
            engagementName: [data?.segment.name, Validators.required],
            playlistMediaFiles: this.fb.array([]),
            hasAdvancedSettings: [data?.hasAdvancedSettings, Validators.required],
            advancedSettings: this.fb.group({
              numberOfPeople: [data?.marketingAutomationConfiguration?.numberOfPeople || 10],
              numberOfMinutes: [data?.marketingAutomationConfiguration?.numberOfMinutes || 1],
              configurationRuleOneActivated: [data?.marketingAutomationConfiguration?.configurationRuleOneActivated || false
              ],
              configurationRuleTwoActivated: [data?.marketingAutomationConfiguration?.configurationRuleTwoActivated || false
              ]
            }),
            sensitivitySettings: [data?.sensitivitySettings || null]
          })
        });
      }
      if (data && data.advertisements) {
        this.setAdvertisements(data.advertisements);
      }
      if (data && data.playlistMediaFiles) {
        this.setAdvertisements(data.playlistMediaFiles);
      }
      if (data?.hasMarketingAutomation) {
        this.setMarketingAutoPlaylist(childPlayList.childPlaylistMediaFiles);
      }

      this.bookForm.get('customer').valueChanges.pipe(
        takeUntil(this.ngUnsubscribe),
        switchMap(customerID => {
          if (customerID) {
            return this.adminApi.getCustomerById(customerID);
          } else {
            return of(null);
          }
        })
      ).subscribe(data => {
        if (data) {
          this.presentationTime = data.presentationTime;
          if (!this.mediaForm.get('defaultMediaPresentationTime').value) {
            this.mediaForm.get('defaultMediaPresentationTime').setValue(this.presentationTime);
          }
        }
      });

    }
  }

  setActiveStep(index: number, event: Event) {
    let element = event.currentTarget as HTMLElement;
    if (!element.classList.contains('disabled')) {
      this.activeStep = index;
    }
  }

  book() {
    this.cleanData();
    if (this.bookForm.valid) {
      this.bookService.createBooking(this.bookForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
        this.engagementId = data
        this.continueWithMedia = true;
        this.titleMessage = this.translateConfigService.instantWithParams(this.engagementType == EngagementTypeEnum.Playlist ? 'notifications.success.playlistBook' : 'notifications.success.segmentBook', { text: this.bookForm.value.name });
        this.displayMessage = this.translateConfigService.instant('bookSegmentInfoModal.text');
      });
    } else {
      this.hasError = true;
      this.displayMessage = this.translateConfigService.instant('book.formErrorMessage');
    }
  }

  copy() {
    this.cleanData();
    if (this.bookForm.valid) {
      this.bookService.copyBooking(this.bookForm.getRawValue()).subscribe((data) => {
        this.systemMessageService.success(this.translateConfigService.instant("notifications.success.bookingCopy", this.bookForm.value.name));
        this.onLaterClick();
      });
    } else {
      this.hasError = true;
      this.displayMessage = this.translateConfigService.instant('book.formErrorMessage');
    }
  }

  publishBooking() {
    switch(this.activeStep) {
      case 0:
      case 1: this.publishGeneralInfo(); break;
      case 2: this.publishMedia(); break;
      case 3: this.publishMarketingAuto(); break;
    }
  }
  publishGeneralInfo() {
    if (this.allowPublish) {
      this.cleanData();
      if (this.bookForm.valid) {
        this.allowPublish = false;
        let data = this.bookForm.getRawValue();
        this.bookService.publishGeneralInfo(data, this.engagementId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.allowPublish = true;
            this.displayMessage = this.translateConfigService.instantWithParams(this.engagementType == EngagementTypeEnum.Playlist ? 'notifications.success.playlistEdit' : 'notifications.success.segmentEdit', { text: this.bookForm.value.name });
            !this.isEdit && (this.activeStep = 0);
          },
          error: () => {
            this.allowPublish = true;
          }
        });
      } else {
        this.hasError = true;
        this.displayMessage = this.translateConfigService.instant('book.formErrorMessage');
      }
    }
  }

  publishMedia() {
    if (this.allowPublish) {
      this.cleanData();
      if (this.mediaForm.valid) {
        let data = this.mediaForm.getRawValue();
        this.allowPublish = false;
        this.bookService.publishMedia(data, this.engagementId, this.engagementType)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.allowPublish = true;
            const editMode = this.isEdit || this.engagementType === EngagementTypeEnum.Playlist
            const messageKey = this.continueCreateMode
            ? (editMode ? 'notifications.success.playlistBook' : 'notifications.success.segmentBook')
            : (editMode ? 'notifications.success.playlistEdit' : 'notifications.success.segmentEdit');

            if (this.isEdit || this.engagementType === EngagementTypeEnum.Playlist) {
              this.displayMessage = this.translateConfigService.instantWithParams(messageKey, { text: this.bookForm.value.name });
            }

            !this.isEdit && (this.activeStep = 0);

            if (this.continueCreateMode) {
              this.systemMessageService.success(this.translateConfigService.instant(this.displayMessage));
              this.router.navigate(['/bookings']);
            }
          },
          error: () => {
            this.allowPublish = true;
          }
        });
      } else {
        this.hasError = true;
        this.displayMessage = this.translateConfigService.instant('book.mediaErrorMessage');
      }
    }
  }
  publishMarketingAuto() {
    if (this.allowPublish) {
      this.cleanData();
      if (this.marketingAutomationForm.valid) {
        this.allowPublish = false;
        let data = this.marketingAutomationForm.getRawValue();

        const marketingAutomationData = {
          isEnabled: data.isEnabled,
          marketingAutomation: {
            advancedSettings: data.marketingAutomation.advancedSettings,
            childPlayListId: data.marketingAutomation.childPlayListId,
            engagementName: data.marketingAutomation.engagementName || this.bookForm.value.name,
            sensitivitySettings: data.marketingAutomation.sensitivitySettings,
            hasAdvancedSettings: data.marketingAutomation.hasAdvancedSettings || false,
            playlistMediaFiles: data.marketingAutomation.playlistMediaFiles.map((m, index) => {
              return {
                ...m,
                mediaId: m?.media?.mediaId,
                mediaName: m?.media?.name,
                mediaType: m?.media?.type,
                orderId: index + 1
              }
            })
          }
        }
        this.bookingApiService.updateMarketingAutomation(marketingAutomationData, this.engagementId)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe({
          next: () => {
            this.allowPublish = true;
            this.displayMessage = this.translateConfigService.instantWithParams('notifications.success.segmentEdit', { text: this.bookForm.value.name });
            !this.isEdit && (this.activeStep = 0);

            if (this.continueCreateMode) {
              this.systemMessageService.success(this.translateConfigService.instant(this.displayMessage));
              this.router.navigate(['/bookings']);
            }
          },
          error: () => {
            this.allowPublish = true;
          }
        });
      } else {
        this.hasError = true;
        this.displayMessage = this.translateConfigService.instant('book.mediaErrorMessage');
      }
    }
  }

  onLaterClick() {
    this.cleanData();
    this.router.navigate(['/bookings']);
  }
  onContinueClick() {
    this.cleanData();
    this.router.navigateByUrl(`/${EngagementTypeEnum[this.bookForm.value.engagementType].toLowerCase()}s/${this.engagementId}`, { state: {
      continueWithMedia: true,
      continueCreateMode: true
    } });
  }
  getSelectedScreens() {
    return this.bookForm.get('bookings').value.map(b => b.screens).flat();
  }
  cleanData() {
    this.hasError = false;
    this.titleMessage = undefined;
    this.displayMessage = undefined;
  }

  deleteBooking() {
    this.dialog.open(ConfirmationModalComponent, {
      width: '500px',
      data: {
        remove: () => {
          this.dialog.closeAll();
          this.onDeleteBooking();
        },
        logic: true
      }
    })
  }

  onDeleteBooking() {
    if (this.engagementId) {
      this.bookingApiService.deleteBooking(this.engagementId).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (() => {
          this.systemMessageService.success(this.translateConfigService.instant("notifications.success.bookingDeleted"));
          this.router.navigate(['/bookings']);
        }),
        error: (error) => console.log(error)
      })
    } else {
      this.systemMessageService.error(this.translateConfigService.instant("notifications.backendErrors.engagementNotFound"));
    }
  }

  override ngOnDestroy() {
    this.cleanData();
    this.bookService.bookings = [];
    this.bookService.currentWeekDays = [];
  }

  private setAdvertisements(advertisements: any[]): void {
    const adFormArray = this.mediaForm.get('advertisements') as FormArray;
    advertisements.forEach(ad => {
      adFormArray.push(this.createAdvertisementGroup(ad));
    });
  }

  private setMarketingAutoPlaylist(media: any[]): void {
    media.forEach(m => {
      this.playlistMediaFiles.push(this.createMarketingAutoPlaylist(m));
    });
  }

  private createMarketingAutoPlaylist(media): FormGroup {
    return this.fb.group({
      id: [media.id, Validators.required],
      orderId: [media.orderId, Validators.required],
      media: [media.media],
      triggers: [media.triggers || null],
      numOfTriggers: [media.triggers == null ? 0 : media.triggers.weatherConditions.length + (media.triggers.temperature != null ? 1 : 0)],
      presentationTime: [media.presentationTime, Validators.required],
      isRemoved: [false, Validators.required]
    });
  }

  private createAdvertisementGroup(ad: any): FormGroup {
    if (this.engagementType == EngagementTypeEnum.Playlist) {
      return this.fb.group({
        advertisementId: [(ad.advertisementId || ad.id)],
        triggers: [ad.triggers || null],
        numOfTriggers: [ad.triggers == null ? 0 : (ad.triggers.weatherConditions && ad.triggers.weatherConditions.length || 0) + (ad.triggers.temperature != null ? 1 : 0)],
        targetGroups: [ad.targetGroups || []],
        presentationTime: [ad.presentationTime || this.presentationTime],
        mediaDetails: [(ad.mediaDetails || ad.media), Validators.required],
        defaultMediaDetails: [ad.defaultMediaDetails],
        defaultMediaPresentationTime: [ad.defaultMediaPresentationTime],
        isRemoved: [false]
      });
    } else {
      return this.fb.group({
        advertisementId: [(ad.advertisementId || ad.id)],
        triggers: [ad.triggers || null],
        numOfTriggers: [ad.triggers == null ? 0 : (ad.triggers.weatherConditions && ad.triggers.weatherConditions.length || 0) + (ad.triggers.temperature != null ? 1 : 0)],
        targetGroups: [ad.targetGroups || [], Validators.required],
        presentationTime: [ad.presentationTime || this.presentationTime],
        mediaDetails: [(ad.mediaDetails || ad.media), Validators.required],
        defaultMediaDetails: [ad.defaultMediaDetails],
        defaultMediaPresentationTime: [ad.defaultMediaPresentationTime],
        isRemoved: [false]
      });
    }
  }

  private getEngagementTitle() {
    const isSegment = this.engagementTypePath.includes(EngagementTypeRouteEnum.Segments);
    const isPlaylist = this.engagementTypePath.includes(EngagementTypeRouteEnum.Playlists);

    if (isSegment || isPlaylist) {
      this.engagementType = isSegment ? EngagementTypeEnum.Segment : EngagementTypeEnum.Playlist;
      const baseTitle = isSegment ? 'bookSegment.title' : 'bookPlaylist.title';

      if (this.isEdit) {
        return this.continueCreateMode ? baseTitle : isSegment ? 'editSegment.title' : 'editPlaylist.title';
      } else {
        return this.stateEngagement ? `${baseTitle}Copy` : baseTitle;
      }
    }

    return '';
  }


}
export enum EngagementTypeRouteEnum {
  Segments = "segment",
  Playlists = "playlist"
} //TODO remove this method when do improvements
export interface StepOneFormValues {
  id: number;
  customer: Customer;
  name: string;
  engagementaType: number;
  mediaCount: number;
  channels: Channel[];
  placements: Placement[];
  slots: Array<Slot>;
}
export interface StepTwoPlaylistFormValues {
  playlistMediaFiles: PlaylistMediaFileSettings[];
}
export interface StepTwoSegmentFormValues {
  advertisements: any[];
  standardMedia: StandardMedia;
}
export interface StateBookData {
  channel: number;
  customer: number;
  placements: number[];
  startDate: DateTime;
}
