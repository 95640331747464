import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, defer, filter, of, switchMap, takeUntil } from "rxjs";
import { MatDialog } from "@angular/material/dialog";

import { unsubscribeMixin } from "../unsubscribe";
import { AuthService, UserRoleEnum } from "../auth/auth.service";
import { AdminApi, Customer, UsersApi } from "../../../_api";
import { AppService } from "../../app.service";
import { indicateLoading } from "../indicate-loading";
import { LanguageModalComponent } from "../../shared/language-modal/language-modal.component";
import { TranslateConfigService } from "../translate-config.service";
import { CurrentUser } from "../auth/current-user";

@Component({
  selector: 'flow-nav-settings',
  templateUrl: './nav-settings.component.html',
  styleUrls: ['./nav-settings.component.scss']
})
export class NavSettingsComponent extends unsubscribeMixin() implements OnInit {

  customers: Customer[];
  selectedCustomer: Customer;
  isAdmin: boolean;
  isRole: boolean;
  currentRoute = '';
  currentLanguage: string;
  initials = ''

  @Input() currentUser!: CurrentUser;

  @Output()
  public closeNavSettings = new EventEmitter(true);

  loading$ = {
    customers: new BehaviorSubject(false)
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private adminApi: AdminApi,
    private usersApi: UsersApi,
    public appService: AppService,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private transalteConfigService: TranslateConfigService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.authService.userChanged.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user) {
          this.getUserCustomers();
        } else {
          this.selectedCustomer = null;
        }
      });
    if (this.authService.isLoggedIn()) {
      this.getUserCustomers();
    }

    this.currentUser = this.authService.getUser()
    this.initials = this.getInitials(this.currentUser?.fullName)

    this.router.events.pipe(takeUntil(this.ngUnsubscribe), filter(event => event instanceof NavigationEnd))
      .subscribe(navigationEnd => {
        this.currentRoute = (navigationEnd as NavigationEnd)?.url;
      });

    this.currentLanguage = this.transalteConfigService.currentLanguage;
  }

  logout(): void {
    this.panelOpenState = false;
    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.closeNavSettings.emit(true);
  }

  closeNav() {
    this.closeNavSettings.emit(true);
  }

  getUserCustomers(): void {
    const user = this.authService.userData;
    this.isAdmin = user?.roleId === UserRoleEnum.Admin;
    this.isRole = user?.roleId === UserRoleEnum.Admin || user?.roleId === UserRoleEnum.Customer;

    defer(() => {
      return this.isAdmin
        ? this.adminApi.getCustomers()
        : this.usersApi.getUserCustomers(user.id);
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.customers, this.cdr))
      .subscribe((customers) => {
        this.customers = customers;
        this.selectedCustomerChange(user);
      });
  }

  onCustomerChange(customer: Customer) {
    const newCustomerId = customer.id === -1 ? null : customer.id;

    defer(() => {
      return this.isAdmin
        ? of(this.authService.user)
        : this.usersApi.selectProfile(newCustomerId, this.authService.user?.email)          
      }).pipe(
        switchMap((customer: Customer) => this.setCustomer(newCustomerId, customer))
      ).subscribe({
        next: (currentUser: CurrentUser) => {
          this.selectedCustomerChange(currentUser);
          this.router.navigate(['/']);

          //Temporary
          if (this.router.url === "/") {
            window.location.reload();
          }
        },
        error: () => { }
      })
  }

  setCustomer(newCustomerId: number, customer: Customer) {
    const currentUser = this.authService.setCustomer({...customer, customerId: this.authService.user?.customerId}, newCustomerId);
    return of(currentUser);
  }

  selectedCustomerChange(currentUser) {
    if (this.isRole) {
      this.selectedCustomer = this.customers?.find(c => c.id === (currentUser.currentCustomerId || currentUser.customerId)) || null;
    }

    if (this.isAdmin) {
      const all = { id: -1, name: 'Admin' };
      this.customers.splice(0, 0, all);
      if (!this.selectedCustomer) {
        this.selectedCustomer = all;
      }
    }
  }

  checkIfAdminCustomerOrUser() {
    return this.isAdmin && this.selectedCustomer?.id === -1;
  }

  openLanguageModal(): void {
    this.closeNavSettings.emit(true);
    this.dialog.open(LanguageModalComponent, {
      panelClass: 'new-modal',
      width: '600px',
      height: '294px',
      data: {
        changeLanguage: (lang: string) => this.changeLanguage(lang)
      }
    });
  }

  changeLanguage(lang: string) {
    const newLanguage = lang
    this.transalteConfigService.changeLanguage(newLanguage)
    this.currentLanguage = newLanguage
  }

  private getInitials(name) {
    return name?.split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }


  protected readonly onlanguagechange = onlanguagechange;

}
