import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {MatSort, Sort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, debounceTime, distinctUntilChanged, forkJoin, fromEvent, map, of, takeUntil } from 'rxjs';
import { AdminApi, Customer, Channel } from 'src/_api';
import { AuthService, UserRoleEnum } from '@core/auth/auth.service';
import { indicateLoading } from '@core/indicate-loading';
import { unsubscribeMixin } from '@core/unsubscribe';
import { Platform } from '@angular/cdk/platform';
import { PermissionsService } from '@app/core/services/permissions.service';
import {PageEvent} from "@angular/material/paginator";
import {PaginatorParams} from "@api/models/paginator";

@Component({
  selector: 'flow-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.scss']
})
export class ChannelComponent extends unsubscribeMixin() implements OnInit{
  displayedColumns: string[] = ['id', 'name', 'customer'];
  dataSource: MatTableDataSource<ChannelListItem>;
  customers: Customer[];
  isAdmin: boolean;
  filterForm: FormGroup<ChannelFilterFormGroup>;
  filterValues: ChannelFilterValues;
  filterRequest: Subscription;
  customerId: number;
  totalCount: number;
  params: ChannelParams = {
    pageNumber: 0,
    pageSize: 10,
    sortBy: 'name',
    sortDirection: 'asc'
  }
  pageSizeOptions = [5, 10, 25, 100];
  loading$ = {
    channel: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    public platform: Platform,
    private permissionsService: PermissionsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initFilter();
    const user = this.authService.userData;
    this.isAdmin = (user.roleId === UserRoleEnum.Admin) && !user.currentCustomerId;
    if (!this.isAdmin) {
      this.customerId = user?.currentCustomerId || user?.customerId;
      this.filterForm.get('customers').setValue([this.customerId])
      this.displayedColumns.push('actions');
    } else {
      this.displayedColumns.push('status', 'actions');
      this.getChannels(this.params);
    }
  }

  private initFilter(): void {
    this.filterForm = this.formBuilder.group({
      customers: this.formBuilder.control(null)
    });
    this.filterForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(50),
      distinctUntilChanged(),
    ).subscribe(this.onFilterChanges);
  }

  getChannels(params: ChannelParams): void {
    this.filterRequest?.unsubscribe();
    this.filterRequest = forkJoin([
      params?.customerIds && params?.customerIds.length > 0 ? this.adminApi.getChannelsList( params ) : of(null),
      this.isAdmin ? this.adminApi.getCustomers() : of(null),
      !this.isAdmin ? this.adminApi.getCustomerById(this.customerId) : of(null)
    ])
      .pipe(takeUntil(this.ngUnsubscribe), indicateLoading(this.loading$.channel))
      .subscribe(([channelsResponse, customersResponse, customerResponse]) => {
        const customersList = customersResponse || [customerResponse]
        if (!this.customers || this.customers?.length === 0) {
          this.customers = customersList.map((c) => ({ id: c.id, name: c.name }))
        }
        this.totalCount = channelsResponse.totalCount
        const channels = !channelsResponse ? [] : channelsResponse?.items.map(loc => {
          const listItem = loc as ChannelListItem;
          listItem.customerName = customersList?.find(c => c.id === loc.customerId)?.name || '';
          return listItem;
        })
        this.dataSource = new MatTableDataSource(channels);
        this.dataSource.sort = this.sort;
      });
  }

  onToggle(event: Event, channel: ChannelListItem, status: boolean): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.editChannel({ status, ...channel })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe({
        complete: () => {
          this.filterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        },
        error: () => {
          this.filterForm.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        }
      });
  }
  private onFilterChanges = (values: ChannelFilterValues): void => {
    this.filterValues = values;
    this.getChannels({...this.params, customerIds: this.filterValues.customers});
  }

  redirectToEditPage(channelId: number): void {
    if (this.permissionsService.hasPermissions(['UpdateChannelAdmin'])) {
      this.router.navigate(['/channels', channelId]);
    }
  }

  handlePageEvent(e: PageEvent) {
    this.params.pageNumber = e.pageIndex;
    this.params.pageSize = e.pageSize;
    if (this.customerId)
      this.params.customerIds = [this.customerId];
    this.getChannels({...this.params, customerIds: this.filterValues.customers});
  }

  handleSortEvent(sort: Sort) {
    this.params = { ...this.params, pageNumber: 0, pageSize: 10, sortBy: sort?.active, sortDirection: sort.direction || (this.params.sortDirection == 'asc' ? 'desc' : 'asc') || 'asc' };
    this.getChannels({...this.params, customerIds: this.filterValues.customers});
  }
}

interface ChannelParams extends PaginatorParams {
  customerIds?: number[]
}

interface ChannelFilterValues {
  customers?: number[]
}

interface ChannelFilterFormGroup {
  customers: FormControl<number[]>;
}

interface ChannelListItem extends Channel {
  customerName?: string;
}
