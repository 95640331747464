<div class="flex flex-row w-full items-center mb-6">
  <h1 class="m-0 mr-8 font-medium">{{ (screenId ? "editScreen.title" : "createScreen.title") | translate }}</h1>
</div>

<flow-stepper *ngIf="!(loading$.init  | async)"
              [steps]="steps"
              [(activeStep)]="selectedIndex"
              (activeStepChange)="selectedTabChange($event)"
              [isEdit]="screenId == null">
  <div header class="h-10 justify-end items-start inline-flex gap-2">
    <button class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer border-neutral-100" [routerLink]="['/screens']">
      <app-icon iconName="x-square"
                stroke="#202020"
                size="md"
                [ngStyle]="{'cursor': 'pointer'}"></app-icon>
      <div class="text-xs font-semibold capitalize leading-none text-black">
        {{'global.cancel' | translate}}
      </div>
    </button>
    <button
            [disabled]="(loading$.save | async) || generalInfoForm.invalid || detailsForm.invalid"
            class="h-10 p-2 rounded border justify-center items-center gap-2 inline-flex cursor-pointer"
            [ngClass]="generalInfoForm.invalid || detailsForm.invalid ? 'border-neutral-100' : 'border-brand-chathams-blue'"
            (click)="onSaveClick()">
      <app-icon iconName="save"
                [stroke]="generalInfoForm.invalid || detailsForm.invalid ? '#EBEBE4' : '#1f5792'"
                fill="#fff"
                size="md"
                [ngStyle]="{'cursor': 'pointer'}"></app-icon>
      <span class="text-xs font-semibold capitalize leading-none"
            [ngClass]="generalInfoForm.invalid || detailsForm.invalid ? 'text-[#EBEBE4]' : 'text-brand-chathams-blue'">
          {{(customerId ? 'global.saveChanges' : 'global.add') | translate}}
        </span>
    </button>
  </div>
  <flow-tabs-item [error]="generalInfoValidationErrors.length > 0">
    <form class="w-full"
          [formGroup]="generalInfoForm">
      <div *ngIf="generalInfoValidationErrors?.length > 0"
           class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50"
           role="alert">
        <div class="flex flex-row items-center">
          <span class="font-medium">{{ 'createScreen.validationErrors.requirementsMet' | translate }}</span>
        </div>
        <ul *ngFor="let validation of generalInfoValidationErrors"
            class="mt-1.5 list-disc list-inside">
          <li>{{ validation }}</li>
        </ul>
      </div>

      <mat-radio-group [disabled]="screenId != null"
                       class="flex gap-4 mb-8"
                       formControlName="isSensor">
        <mat-radio-button class="border border-solid border-gray-400 rounded px-4 py-2 flex items-center cursor-pointer"
                          [value]='false'
                          color="primary">
          <div class="flex flex-col text-xs">
            <div class="font-[600]">{{ 'createScreen.screenWithSensor' | translate }}</div>
            <div>{{ 'createScreen.default' | translate }}</div>
          </div>
        </mat-radio-button>
        <mat-radio-button class="border border-solid border-gray-400 rounded px-4 py-2 flex items-center cursor-pointer"
                          [value]='true'
                          color="primary">
          <div class="flex flex-col text-xs">
            <div class="font-[600]">{{ 'createScreen.sensorOnly' | translate }}</div>
            <div>{{ 'createScreen.forPeopleCounting' | translate }}</div>
          </div>
        </mat-radio-button>
      </mat-radio-group>

      <div class="flex gap-10 input-override">
        <div class="w-[330px] flex flex-col gap-4">
          <div class="w-[330px] rounded justify-start items-center gap-2 inline-flex">
            <div class="justify-start items-center gap-2 flex">
              <app-icon iconName="annotation-info"
                        size="md"
                        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                        stroke="#598fae"></app-icon>
            </div>
            <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'createScreen.generalInfo' | translate}}
            </div>
          </div>

          <div class="w-[330px] flex flex-col">
            <mat-label>{{ 'createScreen.form.name' | translate }} *</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full">

              <input formControlName="humanUniqueIdentifier"
                     matInput
                     type="text">
            </mat-form-field>
          </div>

          <div class="w-[330px] flex flex-col">
            <mat-label>{{ 'createScreen.form.customer' | translate }} *</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full">
              <mat-select formControlName="customerId">
                <mat-option *ngFor="let customer of customers"
                            [value]="customer.id">
                  {{ customer.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="w-[330px] flex flex-col">
            <mat-label>{{ 'createScreen.form.channel' | translate }} *</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full">
              <mat-select formControlName="channelId">
                <mat-option *ngFor="let channel of filteredChannels"
                            [value]="channel.id">
                  {{ channel.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="w-[330px] flex flex-col">
            <mat-label>{{ 'createScreen.form.placement' | translate }} *</mat-label>
            <mat-form-field class="w-full bg-white rounded-md">
              <flow-multi-select-autocomplete formControlName="channelDescription"
                                              [inputTouched]="inputTouched"
                                              [options]="filteredPlacements"
                                              [isLimitReached]="generalInfoForm.get('channelDescription').value?.length > 0"
                                              [isOneChipNeeded]="true"
                                              [displayFn]="displayPlacement"
                                              [newOptionFn]="newPlacement"></flow-multi-select-autocomplete>
            </mat-form-field>
          </div>

          <div class="w-[330px] flex flex-col">
            <mat-label>{{ 'createScreen.form.information' | translate }}</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full">
              <textarea formControlName="note"
                        matInput
                        type="text">
            </textarea>
            </mat-form-field>
          </div>

          <div class="w-[330px] flex flex-col">
            <mat-checkbox formControlName="isUsedForTrackingVehicle"
                          color="primary"> {{ 'createScreen.form.showVehicleData' | translate }}</mat-checkbox>
          </div>
        </div>
        <div class="w-full flex justify-between">
          <div class="w-[330px] flex flex-col p-4 text-xs">
            <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
            <p>{{ 'createScreen.crateScreenGeneralInfoNote' | translate }}</p>
          </div>
          <div class="flex flex-row justify-end">
            <div>
              <button (click)="onNextTab(1)"
                      class="btn-save"
                      color="primary"
                      mat-flat-button>
                {{ "global.next" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

  </flow-tabs-item>
  <flow-tabs-item [error]="detailsValidationErrors.length > 0">
    <form class="w-full"
          [formGroup]="detailsForm">
      <div *ngIf="detailsValidationErrors?.length > 0"
           class="flex flex-col p-4 mb-5 text-brand-red rounded-lg bg-red-50"
           role="alert">
        <div class="flex flex-row items-center">
          <span class="font-medium">{{ 'createScreen.validationErrors.requirementsMet' | translate }}</span>
        </div>
        <ul *ngFor="let validation of detailsValidationErrors"
            class="mt-1.5 list-disc list-inside">
          <li>{{ validation }}</li>
        </ul>
      </div>
      <div class="flex gap-10 input-override">
        <div class="w-[362px] flex flex-col gap-4">
          <div class="w-[362px] rounded justify-start items-center gap-2 inline-flex">
            <div class="justify-start items-center gap-2 flex">
              <app-icon iconName="annotation-info"
                        size="md"
                        class="w-8 h-8 bg-chathams-blue-400 rounded-full flex justify-center items-center"
                        stroke="#598fae"></app-icon>
            </div>
            <div class="text-gray-900 text-sm font-semibold leading-[14px]">{{'createScreen.details' | translate}}</div>
          </div>

          <div class="w-[362px] flex flex-col">
            <mat-label>{{ 'createScreen.form.tracking' | translate }} *</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full">
              <mat-select formControlName="tracking">
                <mat-option [value]="1"
                            class="option-create">{{ 'createScreen.form.trackingValues.notApplicable' | translate }}
                </mat-option>
                <mat-option [value]="2"
                            class="option-create">{{ 'createScreen.form.trackingValues.inbound' | translate }}
                </mat-option>
                <mat-option [value]="3"
                            class="option-create">{{ 'createScreen.form.trackingValues.outbound' | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="w-[362px] flex justify-between">
            <div class="w-[169px] flex flex-col">
              <mat-label>{{ 'createScreen.form.latitude' | translate }} *</mat-label>
              <mat-form-field appearance="outline"
                              class="w-full">
                <input flowDecimalNumberLimit
                       formControlName="latitude"
                       matInput
                       type="text">
              </mat-form-field>
            </div>

            <div class="w-[169px] flex flex-col">
              <mat-label>{{ 'createScreen.form.longitude' | translate }} *</mat-label>
              <mat-form-field appearance="outline"
                              class="w-full">
                <input flowDecimalNumberLimit
                       formControlName="longitude"
                       matInput
                       type="text">
              </mat-form-field>
            </div>
          </div>

          <ng-container *ngIf="!generalInfoForm.get('isSensor').value">
            <div class="w-[362px] flex flex-col">
              <mat-label>{{ 'createScreen.form.orientation' | translate }} *</mat-label>
              <mat-form-field appearance="outline"
                              class="w-full">
                <mat-select formControlName="orientation">
                  <mat-option *ngFor="let orientation of orientationArray"
                              [value]="orientation">
                    {{ OrientationEnumTranslations[orientation] | translate }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="w-[362px] preview flex items-end justify-between">
              <div *ngIf="!detailsForm.get('standardMedia').value"
                   class="w-[169px] flex flex-col">
                <mat-label>{{ 'editSegment.defaultMedia' | translate }} *</mat-label>
                <mat-form-field appearance="outline"
                                class="w-full">
                  <mat-select formControlName="standardMedia">
                    <mat-option *flowHasPermission="['UpdateMediaCms']; disabled true;"
                                (click)="openCreateMedia(detailsForm, 'standardMedia',selectedStandardMedias, customerIdFormControl.value)"
                                [value]="null"
                                class="option-create">{{ 'editSegment.form.createNewMedia' | translate }}
                    </mat-option>
                    <mat-option (click)="openSelectMedia(detailsForm, 'standardMedia', selectedStandardMedias, customerIdFormControl.value)"
                                [value]="null"
                                class="option-create">{{ 'editSegment.form.chooseMedia' | translate }}
                    </mat-option>
                    <mat-option *ngFor="let media of selectedStandardMedias"
                                [value]="media"
                                class="option-create">{{ media?.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="detailsForm.get('standardMedia').value"
                   class="flex flex-col self-start gap-[5px]"
                   [matTooltip]="detailsForm.get('standardMedia')?.value?.name">
                <mat-label>{{ 'editSegment.defaultMedia' | translate }} *</mat-label>

                <div (click)="openViewMedia(detailsForm.get('standardMedia').value, $event)"
                     class="list-thumbnail-container">
                  <div class="relative">
                    <div class="image-name">{{ detailsForm.get('standardMedia')?.value?.name }}</div>
                  </div>
                  <img *ngIf="detailsForm.get('standardMedia').value?.type === MediaTypeEnum.Image"
                       [src]="detailsForm.get('standardMedia').value?.imageUrl"
                       class="list-thumbnail-container">

                  <div *ngIf="detailsForm.get('standardMedia').value?.type === MediaTypeEnum.Video"
                       class="thumbnail-placeholder">
                    <mat-icon>play_circle</mat-icon>
                  </div>
                </div>
              </div>

              <div class="w-[169px] flex flex-col">
                <mat-label>{{ 'createScreen.form.presentationTime' | translate }} *</mat-label>
                <mat-form-field appearance="outline">
                  <input formControlName="presentationTime"
                         matInput
                         type="number"
                         min="1"
                         max="999"
                         (keypress)="restrictNonNumericalInput($event)"
                         (input)="removeLeadingZero($event)"
                         [ngClass]="{'is-invalid': detailsForm.get('presentationTime').invalid && detailsForm.get('presentationTime').touched}">
                </mat-form-field>
                <mat-error
                           *ngIf="detailsForm.get('presentationTime').hasError('max') && detailsForm.get('presentationTime').touched">
                  {{ 'createScreen.validationErrors.presentationTime' | translate }}
                </mat-error>
              </div>



              <div (click)="resetDefaultMedia()"
                   *ngIf="detailsForm.get('standardMedia').value"
                   class="p-2 bg-neutral-50 rounded justify-start items-start gap-2 flex">
                <app-icon [ngStyle]="{cursor:'pointer'}"
                          iconName="x"
                          size="md"
                          stroke="#202020">
                </app-icon>
              </div>
            </div>
          </ng-container>

          <div *ngIf="screen?.uniqueIdentifier && isAdmin"
               class="w-[362px] flex flex-col">
            <mat-label>{{ 'editScreen.form.externalScreen' | translate }}</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full form-field-readonly">
              <input [disabled]="true"
                     [readonly]="true"
                     [value]="screen.uniqueIdentifier"
                     matInput
                     type="text">
            </mat-form-field>
          </div>

          <div class="w-[362px] flex flex-col mt-2">
            <mat-checkbox color="primary"
                          formControlName="scheduleOptions"> {{ 'createScreen.scheduleOptions' | translate }}
            </mat-checkbox>
          </div>

          <ng-container *ngIf="detailsForm.get('scheduleOptions').value">
            <ng-container *ngFor="let day of weekDays; index as i">
              <div class="w-[362px] flex justify-center">
                <div class="flex flex-col w-[48.5%] justify-center">
                  <mat-label>{{('createScreen.days.'+day ) | translate }}</mat-label>
                </div>

                <div class="flex flex-col w-[48.5%] justify-center">
                  <div class="flex justify-around">
                    <mat-label *ngIf="i < 1">{{ 'power up' | translate }}</mat-label>
                    <mat-label *ngIf="i < 1">{{ 'power down' | translate }}</mat-label>
                  </div>
                  <div [formGroupName]="day"
                       class="flex justify-between">
                    <mat-form-field appearance="outline"
                                    class="w-[48.5%]">
                      <input formControlName="powerUp"
                             flowTimeFormat
                             maxlength="5"
                             matInput
                             type="text">
                    </mat-form-field>

                    <mat-form-field appearance="outline"
                                    class="w-[48.5%]">
                      <input formControlName="powerDown"
                             flowTimeFormat
                             maxlength="5"
                             matInput
                             type="text">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div *ngIf="screen?.uniqueIdentifier"
               class="w-[362px] flex flex-col">
            <mat-label>{{ 'editScreen.form.aiVersion' | translate }}</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full form-field-readonly">

              <mat-select formControlName="aiVersion">
                <mat-option *ngFor="let version of aiVersions"
                            [value]="version">
                  {{ version }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="screen?.uniqueIdentifier"
               class="w-[362px] flex flex-col">
            <mat-label>{{ 'editScreen.form.adsVersion' | translate }}</mat-label>
            <mat-form-field appearance="outline"
                            class="w-full form-field-readonly">

              <mat-select formControlName="advertiserVersion">
                <mat-option *ngFor="let version of advertiserVersions"
                            [value]="version">
                  {{ version }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 col-span-2">
          <div class="row row-col text-xs">
            <p class="border-l-4 border-chathams-blue-600 pl-1"> {{ 'createScreen.note' | translate }}</p>
            <p>{{ 'createScreen.crateScreenDetailsNote' | translate }}</p>
          </div>
          <div class="flex flex-row justify-end">
            <div>
              <button (click)="onNextTab(0)"
                      class="btn-cancel mr-3"
                      mat-stroked-button
                      type="button">
                {{ "global.back" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </flow-tabs-item>
</flow-stepper>
