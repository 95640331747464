import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Bookings } from "@api/index";
import { DateTime } from "luxon";
import { BookService } from "../../services/book.service";

@Component({
  selector: 'flow-booking-summary',
  templateUrl: './booking-summary.component.html',
  styleUrls: ['./booking-summary.component.scss']
})
export class BookingSummaryComponent implements OnChanges {
  @ViewChild('slotsContainer', { static: false }) slotsContainer!: ElementRef;
  @Input() selectedBookings: Bookings[] | null = null;
  @Output() weekChange = new EventEmitter<DateTime>();
  showBookings = true;
  showAllStates: any;

  constructor(private bookService: BookService,) {
  }

  ngOnChanges() {
    // Initialize showAllStates array with false for each booking
    this.showAllStates = this.selectedBookings.map(() => false);
  }

  toggleBookings() {
    this.showBookings = !this.showBookings;
  }

  toggleShowAll(index) {
    this.showAllStates[index] = !this.showAllStates[index];
  }

  navigateToWeek(date: DateTime) {
    this.bookService.updateWeekDays(date.startOf('week'));
    this.weekChange.emit(date.startOf('week'));
  }
}
