import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SystemMessageService } from 'src/app/core/services/system-message.service';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { EngagementTypeRouteEnum } from '../book.component';

@Component({
  selector: 'flow-book-confirm-modal',
  templateUrl: './book-confirm-modal.component.html',
  styleUrls: ['./book-confirm-modal.component.scss']
})
export class BookConfirmModalComponent implements OnInit {
  EngagementTypeRouteEnum = EngagementTypeRouteEnum;
  constructor(
    public dialogRef: MatDialogRef<BookConfirmModalComponent>,
    public dialog: MatDialog,
    private systemMessageService: SystemMessageService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public translateService: TranslateConfigService
  ) { }

  ngOnInit(): void {
  }

  onContinue() {
    this.data.continue()
  }

  onLater() {
    this.data.later();
  }

}

interface ModalData {
  continue: Function;
  later: Function;
  engagementType: string;
}