<div class="w-full h-[42px] justify-start items-center gap-4 inline-flex">
  <button class="btn-base-s" type="button" (click)="navigateToToday()">
    {{'book.today' | translate}}
  </button>
  <div class="flex-col w-[11rem] justify-center items-start inline-flex">
    <div class="text-neutral-800 text-xs font-normal font-['Inter'] capitalize leading-[18px]">{{'book.week' |translate}} {{ weekNumber }}</div>
    <div class="text-neutral-800 text-base font-semibold font-['Inter'] capitalize leading-normal">{{ startDate | date: 'MMM d' }} - {{endDate | date: 'MMM d'}}, {{endDate | date: 'y'}}</div>
  </div>
  <div class="w-6 h-6 justify-center items-center cursor-pointer flex" (click)="getPreviousWeek()">
    <div class="w-6 h-6 relative flex-col justify-start items-start flex">
      <app-icon
      fill="rgba(0,0,0,0)"
      iconName="caret-left"
      size="md">
    </app-icon></div>
  </div>
  <div class="w-6 h-6 justify-center items-center cursor-pointer flex" (click)="getNextWeek()">
    <div class="w-6 h-6 relative flex-col justify-start items-start flex">
      <app-icon
        fill="rgba(0,0,0,0)"
        iconName="caret-right"
        size="md">
      </app-icon>
    </div>
  </div>
</div>
