import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { TranslateConfigService } from 'src/app/core/translate-config.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { CalendarItem, CalendarSlot } from './calendar-models';
import { EngagementTypeTranslations } from 'src/app/admin/engagement/engagement-type-translations';
import { EngagementTypeEnum } from 'src/_api';

@Component({
  selector: 'flow-calendar-item',
  templateUrl: 'calendar-item.component.html',
  styleUrls: ['./calendar-item.component.scss']
})

export class CalendarItemComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  @Input() item: CalendarItem;
  @Input() isSelectState: boolean = false;
  @Input() todaySlot: number;
  @Input() shouldScroll: boolean;
  @ViewChild('itemRef') itemRef: ElementRef;
  EngagementTypeTranslations = EngagementTypeTranslations;

  isCrossingRight = true;
  isCrossingLeft = true;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private translateConfigService: TranslateConfigService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    var itm = this.item;
    if (this.todaySlot && this.shouldScroll) {
      const element86 = document.getElementById(`item${this.todaySlot}`)
      if (element86) {
        element86.scrollIntoView({ block: "start", behavior: 'smooth' });
      }
    }

  }

  @HostBinding('class.bc-select-state') get selectStateClass() {
    return this.isSelectState;
  }
  @HostBinding('class.bc-item-selected') get itemSelectedClass() {
    return this.isSelectState && this.item.slot.selected;
  }
  @HostBinding('class.bc-item-disabled') get itemDisabledClass() {
    return this.isSelectState && this.item.slot.booked;
  }

  ngOnInit(): void {

    const element = this.elementRef.nativeElement as HTMLElement;
    this.renderer.setStyle(element, 'left', this.item.leftPercentage + '%');
    this.renderer.setStyle(element, 'right', this.item.rightPercentage + '%');
    this.renderer.setStyle(element, 'top', this.item.topPx + 'px');
    this.isCrossingLeft = this.item.startDiffInMinutes < 0;
    this.isCrossingRight = this.item.endDiffInMinutes < 0;
  }

  toggleSlotSelected(event: MatCheckboxChange): void {
    this.item.slot.selected = event.checked;
  }

  onItemClick(item: CalendarItem): void {
    if (!this.selectState && item.slot?.id) {
      if (item.slot.slot.engagementType === EngagementTypeEnum.Segment) this.router.navigate(['/segments', item.slot.id])
      if (item.slot.slot.engagementType === EngagementTypeEnum.Playlist) this.router.navigate(['/playlists', item.slot.id])
    }
  }

  translateItem() {
    return this.item.toString()
      .replace("SCREEN", this.translateConfigService.instant("global.screen"))
      .replace("GROUP", this.translateConfigService.instant("global.screenGroup"))
      .replace("CHANNEL", this.translateConfigService.instant("global.channel").toLowerCase())
      .replace("ALLCH", this.translateConfigService.instant("global.channels").toLowerCase())
      .replace("ORIENTATION", this.translateConfigService.instant("global.orientation").toLowerCase())
      .replace("CUSTOMER", this.translateConfigService.instant("global.customer"))


  }
}
