import { HttpClient } from "@angular/common/http";
import { ApiConfiguration } from "../api-configuration";
import { Injectable } from "@angular/core";
import { BaseService } from "../base-service";
import { UserLoginRequest } from "../models/user-login-request";
import { UserLoginResponse, UserProfileResponse } from "../models/user-login-response";
import { User, UserCreateModel, UserListItem, UserUpdateRequest } from "../models/user";
import { UserRegisterRequest } from "../models/user-register-request";
import { DummyResult } from "../models/dummy-result";
import { Observable } from "rxjs";
import { UserInviteRequest } from "../models/user-invite-request";
import { PaginationResult, PaginatorParams } from "@api/models/paginator";
import { Customer } from "@api/models/customer";

@Injectable({
    providedIn: 'root',
})
export class UsersApi extends BaseService {

    constructor(
        config: ApiConfiguration,
        http: HttpClient
    ) {
        super(config, http);
    }

    login(body: UserLoginRequest) {
        return this.http.put<UserLoginResponse>(`${this.rootUrl}/users/login`, body)
    }

    refreshToken(body: { refreshToken: string }) {
        return this.http.get<UserLoginResponse>(`${this.rootUrl}/users/refreshToken`, {
            params: { refreshToken: body.refreshToken }
        });
    }

    resetPassword(body: { email: string }) {
        return this.http.put<any>(`${this.rootUrl}/users/resetpassword`, null, {
            params: { email: body.email },
            headers: { 'Accept': 'text/json' }
        });
    }

    getActiveUsers(params): Observable<PaginationResult<UserListItem>> {
        return this.http.get<PaginationResult<UserListItem>>(`${this.rootUrl}/users/active`, { params });
    }

    getUser(params: { userId: number, customerId?: number }): Observable<UserCreateModel> {
        let requestParams:any = {};
        if (params.customerId) requestParams.customerId = params.customerId;
        return this.http.get<UserCreateModel>(`${this.rootUrl}/users/${params.userId}`, {params: requestParams});
    }

    updateLanguage(params: { userId: number; languageId: number }) {
        return this.http.put(`${this.rootUrl}/users/${params.userId}/language/${params.languageId}`, null);
    }

    deleteUser(params: { userId: number }) {
        return this.http.delete<void>(`${this.rootUrl}/users/${params.userId}`);
    }

    register(body: UserRegisterRequest) {
        return this.http.put<DummyResult>(`${this.rootUrl}/users/register`, body);
    }

    verify(body: { code: string }) {
        return this.http.get<string>(`${this.rootUrl}/users/verify`, {
            params: { code: body.code }
        });
    }

    invite(body: UserInviteRequest): Observable<DummyResult> {
        return this.http.post<DummyResult>(`${this.rootUrl}/users/invite`, body);
    }

    updateUser(request: UserUpdateRequest): Observable<any> {
        return this.http.put(`${this.rootUrl}/users`, request);
    }

    selectProfile(customerId: number, email: string): Observable<UserProfileResponse> {
        return this.http.post<UserProfileResponse>(`${this.rootUrl}/users/profile`, { customerId, email });
    }

    getUserCustomers(userId: number): Observable<Customer[]> {
        return this.http.get<Customer[]>(`${this.rootUrl}/users/${userId}/customers`);
    }

}
