import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdminApi, EngagementTypeEnum, ScreenApi } from 'src/_api';
import { IdName } from 'src/_api/models/idname';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { BookService } from '../services/book.service';
import { DateTime } from 'luxon';
import { StateBookData } from '../book.component';

@Component({
  selector: 'flow-book-step-one',
  templateUrl: './book-step-one.component.html',
  styleUrls: ['./book-step-one.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BookStepOneComponent extends unsubscribeMixin() implements OnInit {
  savedState: any = null;
  EngagementTypeEnum = EngagementTypeEnum;
  @Input() bookForm: FormGroup;
  @Input() isAdmin: boolean;
  @Input() customerId: number = 0;
  @Input() formArraysData: FormArraysData;
  @Input() engagementId: number;
  @Input() stateBookData: StateBookData;

  constructor(
    private adminApi: AdminApi,
    private bookService: BookService,
    private screenApi: ScreenApi
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.formArraysData.customers.length === 0) {
      let callFunction: any = this.isAdmin
        ? this.adminApi.getCustomers()
        : this.adminApi.getCustomerById(this.customerId);
      callFunction
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((customers: any) => {
          let customersObject = this.isAdmin ? customers : [customers];
          this.formArraysData.customers = customersObject.map((customer) => {
            return { id: customer.id, name: customer.name };
          });
          if (this.stateBookData) {
            this.bookForm.get('customer').setValue(this.stateBookData.customer);
            this.getChannels(this.stateBookData.customer);
          }
        });
    }
    if (this.customerId && !this.engagementId) {
      this.getChannels(this.customerId);
    }
  }
  onSelectionChange(event: any, type: number) {
    switch (type) {
      case 1:
        this.getChannels(event.source.value);
        break;
      case 2:
        this.getPlacements(event.source.value);
        break;
      case 3:
        this.setScreens(event.source.value);
        break;
    }
  }
  getChannels(value) {
    let params: any = { status: true };
    if (value)params.customerIds = value;
    this.adminApi
      .getChannels(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((channels) => {
        this.formArraysData.channels = channels.map((channel) => {
          return { id: channel.id, name: channel.name };
        });
        const channelIds = this.stateBookData?.channel
          ? [this.stateBookData.channel]
          : this.formArraysData.channels.map((channel) => channel.id);
        this.bookForm.get('channels').setValue(channelIds);
        this.getPlacements(channelIds);
      });
  }
  getPlacements(value) {
    let params: any = {};
    if (this.bookForm.get('customer').value) {
      params.customerId = this.bookForm.get('customer').value;
    }
    if (value && value.length > 0) {
      params.channelIds = value;
    }
    this.screenApi
      .getBookPlacements(params)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((placements) => {
        let filteredPlacements = this.bookService.filterPlacements(placements);
        this.formArraysData.placements = filteredPlacements.map((placement) => {
          return { id: placement, name: placement.name };
        });
        if (this.stateBookData?.placements) {
          filteredPlacements = filteredPlacements.filter((p) =>
            this.stateBookData.placements.includes(p.id)
          );
        }
        this.bookForm.get('placements').setValue(filteredPlacements);
        this.setScreens(filteredPlacements);
      });
  }
  setScreens(filteredPlacements) {
    const allScreens = filteredPlacements.flatMap((item) => item.screenIds);
    const uniqueScreenIds = [...new Set(allScreens)];
    this.bookForm.get('screens').setValue(uniqueScreenIds);
    const today = DateTime.now();
    const startOfWeek = today.startOf('week');
    this.bookService.getBookings(
      this.stateBookData?.startDate
        ? this.stateBookData.startDate
        : startOfWeek,
      this.bookForm,
      this.engagementId
    );
  }
}

export interface FormArraysData {
  customers: IdName[];
  channels: IdName[];
  placements: any[];
}
