import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MediaTypeEnum } from 'src/_api';
import { Media } from 'src/app/core/services/media.service';


@Component({
  selector: 'flow-view-media-modal',
  templateUrl: './view-media.modal.html',
  styleUrls: ['./view-media.modal.scss']
})
export class ViewMediaModal implements OnInit {

  MediaTypeEnum = MediaTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<ViewMediaModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) {

  }

  ngOnInit(): void { }

  onClose(): void {
    this.dialogRef.close();
  }
}

interface ModalData {
  title?: string;
  media?: Media;
}
