<div [id]="'item' + item?.slot?.engagement?.id"
     class="bc-engagement-time"
     #itemRef
     [ngClass]="{
      'bc-crossing-left': isCrossingLeft,
      'bc-crossing-right': isCrossingRight,
      'missingConfig': item?.slot?.slot?.mediaCount === 0,
      'isGroup': item?.slot?.slot?.mediaCount === 0 ? null : item?.slot?.screenGroup
    }"
     (click)="onItemClick(item)">
  <mat-icon *ngIf="isCrossingLeft"
            class="bc-start-arrow">arrow_right_alt</mat-icon>
  <div class="bc-engagement-time-label"
       *ngIf="!isCrossingLeft">
    <mat-checkbox class="checkbox-w-full"
                  *ngIf="isSelectState"
                  [disabled]="item.slot.booked"
                  (change)="toggleSlotSelected($event)"
                  [checked]="item.slot.selected">
      {{translateItem()}}
    </mat-checkbox>
    <span *ngIf="!isSelectState">
      <span class="font-bold">{{item.slot.id}}:</span> {{translateItem()}}
    </span>
    <span *ngIf="!isSelectState"
          class="bc-engagement-time-label-type">
      {{EngagementTypeTranslations[item.slot?.slot?.engagementType] | translate}}
    </span>
  </div>
  <mat-icon *ngIf="isCrossingRight"
            class="bc-end-arrow">arrow_right_alt</mat-icon>
</div>
