import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';

import { DateTime } from 'luxon';
import { Month } from './calendar-models';
import { BookService } from "../book/services/book.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Bookings } from 'src/_api';

interface WeekDay {
  dayName: string;
  dateNumber: number;
}

@Component({
  selector: 'flow-calendar',
  templateUrl: 'calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})


export class CalendarComponent implements OnInit, OnChanges {
  @Input() week!: WeekDay[];
  @Input() bookings!: Bookings[];
  @Input() bookForm!: FormGroup;

  showInfo = false

  todayNumber!: number;
  currentWeekDays: string[];
  months: Month[];
  selectedScreen: string;
  todayDate: string = DateTime.now().toISODate();
  currentWeekEndDate: string;

  constructor(private bookService: BookService, private fb: FormBuilder) {

  }
  ngOnInit(): void {
    this.currentWeekDays = this.bookService.currentWeekDays;
    this.currentWeekEndDate = this.currentWeekDays[this.currentWeekDays.length - 1];
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.todayNumber = DateTime.now().day;
  }
  selectSlot(slot) {
    if (!slot.isBooked && slot.startDate >= this.todayDate) {
      slot.isMainBooking = !slot.isMainBooking
      slot.isSelected = slot.isMainBooking;
      const activeBooking = this.bookService.setActiveBookings(this.bookings);
      this.bookForm.get('bookings').setValue(activeBooking);
    }
  }

  showMore(e: Event, booking?) {
    if (booking) {
      e.stopPropagation()
      this.selectedScreen = null;
      this.showInfo = !this.showInfo;
      this.selectedScreen = booking.screens[0].name || booking?.screenGroup?.name
      return
    }
  }

  showLess($event: MouseEvent) {
    $event.stopPropagation()
    this.showInfo = false;
  }

  selectWeek(bookingSlots) {
    const startOfSelectedWeek = this.currentWeekDays[0]
    const emptySlotsForTheWeek = bookingSlots.filter(item => item && item.weekStartDate === startOfSelectedWeek && (item.isBooked === false && item.isMainBooking === false));
    const bookingsForTheWeek = bookingSlots.filter(item => item.weekStartDate === startOfSelectedWeek)

    if (emptySlotsForTheWeek.length > 0) {
      emptySlotsForTheWeek.forEach(slot => this.selectSlot(slot));
    } else {
      bookingsForTheWeek.forEach(slot => this.selectSlot(slot));
    }
  }
  isWeekSelected(booking?): boolean {
    const startOfSelectedWeek = this.currentWeekDays[0];
    const remainingDaysInWeek = this.currentWeekDays.filter(day => day >= this.todayDate).length;
    const isMainBooking = booking?.engagementSlots.filter(item => item.weekStartDate == startOfSelectedWeek && item.isMainBooking).length;
    const isBooked = booking?.engagementSlots.filter(item => item.weekStartDate == startOfSelectedWeek && item.isBooked).length;
    return isMainBooking == (remainingDaysInWeek - isBooked) && isMainBooking > 0;
  }
  hideSelection(booking?) {
    const startOfSelectedWeek = this.currentWeekDays[0];
    return booking ? booking.filter(item => item.weekStartDate == startOfSelectedWeek && item.isBooked).length != 7 : true;
  }
}


